@charset "UTF-8";

// 상단유틸영역
.header_util {
    position: relative;
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #dadada;
    z-index: 5000;
    @include fontset;

    .inner {
        width: 1200px;
        height: 39px;
        margin: 0 auto;
    }

    .tx_bar {
        display: inline-block;
        width: 1px;
        height: 10px;
        margin: 0 10px;
        background: #e2e1e0;
        vertical-align: -1px;
    }
}

.header_utilmenu {
    float: left;
    padding: 11px 0 0 0;

    ul > li {
        float: left;
        line-height: 18px;
    }

    .home {
        color: $color-11st-red;
    }

    .appdown {
        color: #333;

        .ico {
            display: inline-block;
            width: 16px;
            height: 16px;
            margin: 0 4px 0 0;
            background: url("/img/common/v2/sp_header_util.png") no-repeat;
            vertical-align: -2px;
        }
    }

    .btn_direct {
        display: block;
        width: 82px;
        height: 13px;
        margin: 3px 0 0 0;
        background: url("/img/common/v2/sp_header_util.png") no-repeat -20px 0;
        text-indent: -9999px;
        line-height: 18px;
    }

    .on .btn_direct {
        background-position: -20px -15px;
    }

    .direct_go_wrap {
        display: none;
        position: absolute;
        top: 39px;
        z-index: 1000;
        width: 278px;
        border: 1px solid #dadada;
        font-family: $font-family-default;

        .lay_tit {
            height: 38px;
            padding: 0 18px;
            border-bottom: 1px solid #dadada;
            background-color: #f5f5f5;
            color: #333;
            font-weight: normal;
            font-size: 16px;
            line-height: 35px;

            em {
                color: #f62e3d;
            }
        }

        .lay_conts {
            padding: 7px 18px;
            color: #666;
            background: #fff;
        }

        dl {
            margin-top: 20px;

            &:first-child {
                margin-top: 0;
            }
        }

        dt {
            margin-bottom: 10px;
            color: #111;
            font-size: 14px;
        }

        dd {
            font-size: 12px;
        }

        ol li {
            padding: 1px 0 5px;
            color: #536dfe;
        }

        dd .m_benefit {
            padding-top: 7px;
            color: $color-11st-red;

            .ico {
                display: inline-block;
                width: 5px;
                height: 5px;
                margin-right: 8px;
                background: url("/img/common/v2/sp_common_v2.png") no-repeat -133px -82px;
                vertical-align: 0.2em;
            }
        }

        ul li {
            float: left;
            margin: 0 -1px -1px 0;
            border: 1px solid #dadada;

            .btn_way {
                display: block;
                width: 118px;
                height: 33px;
                text-align: center;
                color: #666;
                background: #fff;
                font: normal 11px/1.2 $font-family-default;

                &:hover {
                    color: $color-11st-red;
                    text-decoration: none;
                }
            }
        }

        .btn_sec {
            margin-top: 15px;
            text-align: center;
        }

        .btn_nw_gray {
            padding-left: 10px;
            padding-right: 10px;
        }

        button.btn_laycls {
            position: absolute;
            right: -1px;
            top: -1px;
            width: 40px;
            height: 40px;
            background: url("/img/common/v2/sp_common_v2.png") no-repeat -140px -58px !important;
        }
    }

    .lang {
        position: relative;
        top: -1px;
        display: inline-block;
        margin-right: 10px;
        font: 12px $font-family-default;
        letter-spacing: 0;
        color: #333;
        vertical-align: middle;

        &:after {
            content: "";
            display: inline-block;
            height: 16px;
            width: 0;
            vertical-align: middle;
        }

        img {
            width: 16px;
            height: auto;
            margin: -2px 5px 0 0;
            vertical-align: middle;
        }
    }
}

// MPSR-49126 삭제예정 시작
.header_user_etc {
    float: right;
    padding: 10px 0 0 0;
    font-size: 12px;

    > ul > li {
        float: left;
        padding: 0 0 0 10px;
    }

    a {
        display: inline-block;

        &:hover {
            color: $color-11st-red;
        }
    }

    .ico_arrow {
        position: absolute;
        right: 10px;
        top: 7px;
        width: 7px;
        height: 4px;
        background: url("/img/common/v2/sp_header_util.png") no-repeat 0 -20px;
        vertical-align: middle;
    }

    .tx_bar {
        margin: 0 0 0 10px;
    }

    .frame {
        display: none;
        position: absolute;
        left: -1px;
        right: 0px;
        top: -5px;
        height: 27px;
        border: 1px solid #666;
    }

    .user_sub_menu {
        display: none;
        position: absolute;
        left: -1px;
        right: 0px;
        top: 23px;
        padding: 10px 8px;
        background: #fff;
        border: 1px solid #666;

        li {
            white-space: nowrap;
            margin-top: 4px;

            &:first-child {
                margin-top: 0px;
            }
        }
    }

    .have_sub {
        position: relative;

        a {
            padding-right: 17px;
        }
    }

    .on {
        .frame,
        .user_sub_menu {
            display: block;
        }
    }

    .lang_v2 {
        a {
            padding-right: 25px;
            letter-spacing: 0;
        }

        .flag {
            display: inline-block;
            width: 16px;
            height: 12px;
            line-height: 12px;
            position: relative;
            top: 1px;
            margin: 0 5px 0 0;
            background: url("/img/common/v2/flag_all.gif") no-repeat;
        }

        .flag_ko {
            background-position: 0 0;
        }

        .flag_en {
            background-position: 0 -15px;
        }

        .flag_ch {
            background-position: 0 -30px;
        }

        .flag_ma {
            background-position: 0 -45px;
        }

        .flag_in {
            background-position: 0 -60px;
        }

        .flag_tu {
            background-position: 0 -75px;
        }

        .flag_th {
            background-position: 0 -90px;
        }

        .frame {
            width: 96px;
        }

        .user_sub_menu {
            width: 80px;
        }
    }
}
// MPSR-49126 삭제예정 끝

.header_user_box {
    float: right;
    padding: 10px 10px 0 0;
    font-size: 12px;

    > ul > li {
        float: left;
        padding: 0 0 0 10px;
    }

    a {
        display: inline-block;

        &:hover {
            color: $color-11st-red;
        }
    }

    .ico_arrow {
        position: absolute;
        right: 0;
        top: 8px;
        width: 7px;
        height: 4px;
        background: url("/img/common/v2/sp_header_util.png") no-repeat 0 -20px;
        vertical-align: middle;
    }

    .tx_bar {
        margin: 0 0 0 10px;
    }

    .frame {
        display: none;
        position: absolute;
        left: -11px;
        right: -11px;
        top: -5px;
        height: 27px;
        border: 1px solid #666;
    }

    .user_sub_menu {
        display: none;
        position: absolute;
        left: -1px;
        right: 0px;
        top: 23px;
        padding: 10px 8px;
        background: #fff;
        border: 1px solid #666;

        li {
            white-space: nowrap;
            margin-top: 4px;

            &:first-child {
                margin-top: 0px;
            }
        }
    }

    .have_sub {
        position: relative;

        a {
            position: relative;
            padding-right: 17px;
        }

        &.selleroffice .user_sub_menu {
            width: 76px;
        }
    }

    .on {
        .frame,
        .user_sub_menu {
            display: block;
        }
    }
}
