@charset "UTF-8";

$pageStatus: "sub";

@import "../common/mixins"; //믹스인모음

@import "../common/helpers/variables"; //변수모음
@import "../common/helpers/copyright"; //copyright
@import "../common/helpers/reset"; //스타일 초기화

@import "../common/keyframes";
@import "../common/animation";
@import "../common/partial/ie_end_support"; //IE 지원 종료
@import "../common/partial/skipnavi"; //스킵네비
@import "../common/partial/common"; //공통기능클래스
@import "../common/partial/button"; //공통버튼
@import "../common/partial/adlay"; //광고+도움말레이어
@import "../common/partial/header_util"; //공통상단유틸
@import "../common/partial/header"; //공통헤더
@import "../common/partial/footer"; //공통푸터
@import "../common/partial/banner"; //공통배너, 윙배너
@import "../common/partial/layer_collection"; //공통레이어
@import "../common/partial/listing"; //공통 상품정보, 리스팅
@import "../eui/component/c-starrate";
@import "../eui/component/c-card-item";
@import "../common/partial/myhistory"; //공통 마이히스토리
@import "../common/partial/c-netfunnel"; //공통 넷퍼넬 팝업
@import "../common/partial/lazyload";

.fl_left {
    float: left;
}

.fl_right,
.RTab {
    float: right;
}

// BUTTON - default type
.defbtn_xsm,
.defbtn_sm,
.defbtn_lsm,
.defbtn_med,
.defbtn_lar,
.defbtn_xlar {
    display: inline-block;
    height: 16px;
    border: 1px solid #c4c4c4;
    border-radius: 2px;
    font: 11px/18px $font-family-asis;
    line-height: 21px\0 / IE;
    text-decoration: none !important;
    letter-spacing: -1px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
    vertical-align: middle;
}

.defbtn_xsm > span,
.defbtn_sm > span,
.defbtn_lsm > span,
.defbtn_med > span,
.defbtn_lar > span,
.defbtn_xlar > span,
.defbtn_xsm > em,
.defbtn_sm > em,
.defbtn_lsm > em,
.defbtn_med > em,
.defbtn_lar > em,
.defbtn_xlar > em {
    display: inline-block;
    *display: inline;
    padding: 0 7px;
}

button.defbtn_xsm {
    height: 18px;
}

.defbtn_sm {
    height: 18px;
    line-height: 20px;
    line-height: 23px\0 / IE;
}

button.defbtn_sm {
    height: 20px;
}

.defbtn_lsm {
    height: 22px;
    line-height: 24px;
    line-height: 27px\0 / IE;
}

button.defbtn_lsm {
    height: 24px;
}

.defbtn_med {
    height: 25px;
    line-height: 27px;
    line-height: 30px\0 / IE;
    font-size: 12px;
}

button.defbtn_med {
    height: 27px;
}

.defbtn_lar {
    height: 33px;
    line-height: 37px;
    line-height: 40px\0 / IE;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
}

button.defbtn_lar {
    height: 35px;
}

.defbtn_med > {
    span,
    em {
        padding: 0 11px;
    }
}

.defbtn_lar > {
    span,
    em {
        padding: 0 11px;
    }
}

.defbtn_xlar {
    height: 41px;
    line-height: 43px;
    line-height: 46px\0 / IE;
    font-size: 14px;
    font-weight: bold;
}

button.defbtn_xlar {
    height: 43px;
}

.defbtn_xlar > {
    span,
    em {
        padding: 0 28px;
    }
}

.defbtn_med,
.defbtn_lar,
.defbtn_xlar {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.12);
}

.ladtype,
.xladtype,
a.mdtype,
button.mdtype,
button.dtype,
button.arrdtype,
a.dtype,
a.arrdtype {
    border-color: #d81818;
    border-bottom-color: #9e1212;
    background: #ed2f2f;
    background-image: linear-gradient(#ed2f2f, #dd0e0e);
    background-image: -webkit-linear-gradient(#ed2f2f, #dd0e0e);
    color: #fff;
}

a.mdtype2,
button.mdtype2,
button.dtype2,
button.arrdtype2,
a.dtype2,
a.arrdtype2 {
    border-color: #424242;
    border-bottom-color: #303030;
    background: #545454;
    background-image: linear-gradient(#545454, #3f3f3f);
    background-image: -webkit-linear-gradient(#545454, #3f3f3f);
    color: #fff;
}

.ladtype4,
.xladtype4,
a.mdtype3,
button.mdtype3,
button.dtype3,
button.arrdtype3,
a.dtype3,
a.arrdtype3 {
    border-color: #676767;
    border-bottom-color: #4c4c4c;
    background: #7f7f7f;
    background-image: linear-gradient(#7f7f7f, #6a6a6a);
    background-image: -webkit-linear-gradient(#7f7f7f, #6a6a6a);
    color: #fff;
}

.ladtype5,
.xladtype5,
a.mdtype4,
button.mdtype4,
button.dtype4,
button.arrdtype4,
a.dtype4,
a.arrdtype4 {
    border-color: #ec2e2e;
    border-bottom-color: #cd2828;
    background: #fff;
    background-image: linear-gradient(#fff, #efefef);
    background-image: -webkit-linear-gradient(#fff, #efefef);
    color: #ea1717;
}

.ladtype6,
.xladtype6,
a.mdtype5,
button.mdtype5,
button.dtype5,
button.arrdtype5,
a.dtype5,
a.arrdtype5 {
    border-color: #6283e3;
    border-bottom-color: #5572c6;
    background: #fafafa;
    background-image: linear-gradient(#fafafa, #efefef);
    background-image: -webkit-linear-gradient(#fafafa, #efefef);
    color: #4d6bcc;
}

a.mdtype6,
button.mdtype6,
button.dtype6,
button.arrdtype6,
a.dtype6,
a.arrdtype6 {
    border-color: #bdbdbd;
    border-bottom-color: #828282;
    background: #fafafa;
    background-image: linear-gradient(#fafafa, #efefef);
    background-image: -webkit-linear-gradient(#fafafa, #efefef);
    color: #4d4d4d;
}

.ladtype7,
.xladtype7 {
    border-color: #aaa;
    border-bottom-color: #808080;
    background: #fafafa;
    background-image: linear-gradient(#fafafa, #eee);
    color: #333;
}

a.mdtype7,
button.mdtype7,
button.dtype7,
button.arrdtype7,
a.dtype7,
a.arrdtype7 {
    border-color: #bdbdbd;
    border-bottom-color: #828282;
    background: #fff;
    color: #757575;
}

.ladtype2,
.xladtype2 {
    border-color: #586c93;
    border-bottom-color: #414f6c;
    background: #6e81a5;
    background-image: linear-gradient(#6e81a5, #576a92);
    color: #fff;
}

.ladtype3,
.xladtype3 {
    border-color: #313131;
    border-bottom-color: #242424;
    background: #474747;
    background-image: linear-gradient(#474747, #333);
    color: #fff;
}

.arrdtype > span:after,
.arrdtype2 > span:after,
.arrdtype3 > span:after,
.arrdtype4 > span:after,
.arrdtype5 > span:after,
.arrdtype6 > span:after,
.arrdtype7 > span:after {
    display: inline-block;
    content: "";
    width: 3px;
    height: 5px;
    margin: 0 -2px 0 4px;
    background: url("/img/default/icon/bul_arrt_deftotal.gif") no-repeat 100% 0;
    vertical-align: 1px;
}

.arrdtype4 > span:after {
    background-position: 100% -25px;
}

.arrdtype5 > span:after {
    background-position: 100% -50px;
}

.arrdtype6 > span:after {
    background-position: 100% -75px;
}

.arrdtype7 > span:after {
    background-position: 100% -100px;
}

.btn_disable {
    opacity: 0.5;
    filter: alpha(opacity = 50);
    cursor: default;
}

.defbtn_desel {
    padding: 0 0 0 10px !important;
    background: url("/img/default/icon/ico_deselect.gif") no-repeat 0 50% !important;
}

.btnctr_pn {
    position: absolute;
    top: 0;
    right: 0;
    padding-right: 42px;
    color: #666;
    font-size: 11px;
    font-family: Tahoma, "돋움", dotum, sans-serif;
    letter-spacing: 1px;

    em {
        color: #434343;
        font-weight: bold;
    }

    button {
        display: block;
        position: absolute;
        width: 19px;
        height: 18px;
        top: -1px;
        right: 18px;
        background: url("/img/default/btn/prev_next05.gif") no-repeat 0 0;
        font-size: 0;
        text-indent: -9999px;

        &.in_next {
            right: 0;
            background-position: 100% 0;
        }
    }
}

.btnctr_pn2 button {
    display: block;
    position: absolute;
    z-index: 10;
    top: 107px;
    width: 39px;
    height: 39px;
    background: url("/img/default/btn/prev_next06.png") no-repeat 0 0;
    font-size: 0;
    text-indent: -9999px;

    &.in_prev {
        left: 0;
    }

    &.in_next {
        right: 0;
        background-position: -39px 0;
    }

    &.in_prev:hover {
        background-position: 0 -39px;
    }

    &.in_next:hover {
        background-position: -39px -39px;
    }
}

// etc button
.link_arr_txt {
    font-size: 11px;
    line-height: 1.2;
    padding: 0 7px 0 0;
    background: url("/img/default/btn/arr.gif") no-repeat 100% -1px;
    *background-position: 100% 0px;
    color: #4d4d4d;
    letter-spacing: -1px;
    text-decoration: underline;
}

.link_arr_link2 {
    padding-right: 6px;
    background: url("/img/default/icon/bul_arr_666.gif") no-repeat 100% 2px;
    color: #636363;
    font-family: $font-family-asis;
    font-size: 11px;
    letter-spacing: -1px;
    line-height: 120%;
    text-decoration: underline;
}

.link_arrdown_txt {
    font-size: 11px;
    line-height: 1.2;
    padding: 0 7px 0 0;
    background: url("/img/default/btn/arrdown04.gif") no-repeat 100% -1px;
    *background-position: 100% 0px;
    color: #4d4d4d;
    letter-spacing: -1px;
    text-decoration: underline;
}

:root {
    .link_arrdown_txt,
    .link_arr_txt {
        background-position: 100% 1px;
    }
}

.link_arr_dirtgo {
    position: absolute;
    z-index: 10;
    padding-right: 6px;
    background: url("/img/default/icon/bul_arr_666.gif") no-repeat 100% 2px;
    color: #636363;
    font-family: $font-family-asis;
    font-size: 11px;
    letter-spacing: -1px;
    line-height: 120%;
    text-decoration: underline;
}

.defbtn_plus {
    width: 17px;
    height: 17px;
    line-height: 500% !important;
    background: url("/img/default/btn/plusminus.gif") no-repeat -16px 0;
    border: 0 none;
    overflow: hidden;
}

.defbtn_minus {
    width: 17px;
    height: 17px;
    line-height: 500% !important;
    background: url("/img/default/btn/plusminus.gif") no-repeat 0 0;
    border: 0 none;
    overflow: hidden;
}

.defbtn_delete {
    width: 18px;
    height: 13px;
    line-height: 500% !important;
    background: url("/img/default/btn/delete.gif") no-repeat 50% 50%;
    vertical-align: middle;
    border: 0 none;
    overflow: hidden;
}

.defbtn_info {
    width: 18px;
    height: 15px;
    *height: 17px;
    line-height: 500% !important;
    margin: -1px 0 0 2px;
    *margin-left: 1px;
    *padding: 1px;
    background: url("/img/default/btn/info.gif") no-repeat 50% 0;
    vertical-align: middle;
    border: 0 none;
    overflow: hidden;
}

.defbtn_more {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    overflow: hidden;
    height: 14px;
    padding: 0 11px 0 5px;
    border: 1px solid #e3e3e3;
    background: #fff url("/img/default/icon/bul_arr_555.gif") no-repeat 38px 4px;
    color: #666;
    line-height: 150%;
    text-decoration: none !important;
    letter-spacing: -1px;
}

.defbtn_download {
    display: inline-block;
    font: 11px $font-family-asis;
    padding: 0 0 0 14px;
    background: url("/img/default/btn/download.gif") no-repeat 0 0;
    line-height: 13px;
    color: #4d4d4d !important;
    letter-spacing: -1px;
    text-decoration: underline !important;
}

.defbtn_download2 {
    display: inline-block;
    font: 11px $font-family-asis;
    padding: 0 0 0 11px;
    background: url("/img/default/btn/download02.gif") no-repeat 0 2px;
    line-height: 13px;
    color: #ec1618 !important;
    letter-spacing: -1px;
    text-decoration: underline !important;
}

.defbtn_arrdown {
    width: 17px;
    height: 17px;
    line-height: 500% !important;
    background: url("/img/default/btn/arrdown.gif") no-repeat 50% 50%;
    vertical-align: middle;
    margin: -2px 0 0 2px;
    border: 0 none;
    overflow: hidden;
}

.defbtn_arrdown2 {
    width: 13px;
    height: 13px;
    line-height: 500% !important;
    background: url("/img/default/btn/arrdown02.gif") no-repeat 50% 50%;
    vertical-align: middle;
    margin: -2px 0 0 2px;
    border: 0 none;
    overflow: hidden;
}

.defbtn_arrdown3 {
    overflow: visible;
    font-size: 11px;
    line-height: 1.2;
    height: 12px;
    padding: 0 10px 0 0;
    background: url("/img/default/btn/arrdown03.gif") no-repeat 100% 4px;
    border-bottom: 1px solid #676767;
    color: #4d4d4d;
    letter-spacing: -1px;
    text-decoration: none;
}

.defbtn_arrup {
    overflow: visible;
    font-size: 11px;
    line-height: 1.2;
    height: 12px;
    padding: 0 10px 0 0;
    background: url("/img/default/btn/arrup.gif") no-repeat 100% 4px;
    border-bottom: 1px solid #676767;
    color: #4d4d4d;
    letter-spacing: -1px;
    text-decoration: none;
}

:root {
    .defbtn_arrdown3,
    .defbtn_arrup,
    .link_arr_txt {
        line-height: 1.1;
    }
}

.defshare-box {
    font-size: 0;
    line-height: 0;

    a {
        float: left;
        overflow: hidden;
        width: 20px;
        height: 0;
        padding-top: 20px;
        margin-left: 3px;
        background: url("/img/default/btn/sns.png") no-repeat 0 0;
        font-size: 0;
        line-height: 18px;

        &.twitter {
            background-position: 0 0;
        }

        &.facebook {
            background-position: -21px 0;
        }

        &.me2day {
            background-position: -42px 0;
        }

        &.clog {
            background-position: -64px 0;
        }
    }

    .flike {
        float: left;
        width: 102px;
        height: 20px;
        margin-left: 3px;
    }
}

.defbtn_apply {
    position: absolute;
    z-index: 10;
    right: 0;
    top: 4px;
    color: #757575;
    font: 11px $font-family-asis;
    letter-spacing: -1px;
    text-decoration: none !important;
}

//control
.plusminus {
    position: relative;
    display: inline-block;
    padding: 0 0 0 17px;
    width: 42px;
    height: 13px;
    margin-top: -2px;
    vertical-align: middle;

    .text {
        position: absolute;
        left: 17px;
        width: 17px;
        height: 13px;
        padding: 2px 4px 0px;
        *margin-top: 0px;
        _margin-top: 2px;
        font-size: 11px;
        text-align: center;
        border: 1px solid #cdcdcd;
        border-left: 0 none;
        border-right: 0 none;
        vertical-align: top;
    }

    .defbtn_minus {
        position: absolute;
        left: 0;
        _top: 2px;
        vertical-align: top;
    }

    .defbtn_plus {
        position: absolute;
        right: 0;
        vertical-align: top;
        _margin-top: 2px;
    }
}

:root .plusminus input[type="text"] {
    padding: 1px 4px 1px;
}

//select box
.defselect_box {
    display: inline-block;
    *display: block;
    position: relative;
    border: 1px solid #c8c8c8;
    background: #fff;
    font-size: 11px;
    line-height: 120%;

    &.selected {
        background-color: #fff9e8;
    }

    button {
        *overflow: visible;
        width: 100%;
        height: 20px;
        padding: 0;
        border: 0 none;
        background: url("/img/default/btn/select.gif") no-repeat 100% 50%;
        color: #666;
        font-size: 11px;
        text-align: left;

        span {
            display: inline-block;
            overflow: hidden;
            height: 16px;
            padding: 0 24px 0 5px;
            line-height: 1.6;
        }
    }

    ul {
        display: none;
        position: absolute;
        left: -1px;
        top: 20px;
        overflow: hidden;
        overflow-y: auto;
        width: 100%;
        max-height: 249px;
        border: 1px solid #c8c8c8;
        background: #fff;
        color: #656565;

        li {
            overflow: hidden;
            border-top: 1px solid #e9e9e9;
            font-size: 11px;

            &:first-child {
                border: none;
            }
        }

        a {
            display: block;
            height: 24px;
            padding: 0 10px;
            color: #666;
            font-family: $font-family-asis;
            text-decoration: none;
            text-align: left;
            line-height: 240%;

            &:hover {
                background: #f2f2f2;
                font-weight: bold;
                letter-spacing: -1px;
            }
        }
    }
}

//ICON
.ico_bbslock {
    display: inline-block;
    overflow: hidden;
    width: 15px;
    height: 15px;
    margin: -2px 0 0 3px;
    line-height: 500%;
    background: url("/img/default/icon/ico_lock.gif") no-repeat 0 0;
    vertical-align: middle;
}

// promotion layer
.om_stit {
    padding-left: 8px;
    background: url("/img/default/icon/bul_arr_333.gif") 0 6px no-repeat;
    color: rgb(48, 25, 25);
    font-size: 14px;
}

.h_ad {
    overflow: hidden;
    width: 36px;
    height: 0;
    *height: 15px;
    margin: -2px 0 0 10px;
    background: url("/img/default/icon/ad_v2.gif") no-repeat 0 0;
    padding-top: 18px;
    font-size: 0 !important;
    vertical-align: -2px;
    *text-indent: -9999px;

    &.hadtype2 {
        width: 21px;
        margin: 0 0 0 4px;
        padding-top: 12px;
        background-position: -39px 0;
    }
}

.had_wrap {
    .h_ad {
        display: block;
    }

    p {
        display: none;
        position: absolute;
        margin: 2px 0 0 4px;
        *margin-top: -4px;
        z-index: 2;
        padding: 6px 5px 2px;
        border: 1px solid #8a8a8a;
        background: #fff;
        color: #757575;
        font: 11px $font-family-asis;
        letter-spacing: -1px;
        text-align: left;
        line-height: 120%;
    }
}

div.had_wrap {
    position: relative;
    zoom: 1;
    z-index: 5;

    &:after {
        display: block;
        clear: both;
        content: "";
    }

    .ad_stit {
        float: left;

        + div {
            display: inline-block;
        }
    }

    + .thumbnail_list {
        margin-top: 5px;
    }

    .om_stit {
        margin-top: 25px;

        + div {
            margin-top: 28px;
        }
    }
}

#wingBnr2 .had_wrap {
    display: block;
    width: auto;
    text-align: left;

    h4 {
        padding-left: 8px !important;
    }

    p {
        width: 150px;
        left: auto;
        right: 0;
    }

    > div {
        position: absolute;
        top: 5px;
        left: 47px;
        *z-index: 1;
    }
}

:root #wingBnr2 .had_wrap > div {
    position: absolute;
    top: 6px;
    left: 47px;
}

* + html .had_wrap + div.viewport {
    position: static !important;
    left: 0 !important;
}

// new, hot
span.gicon {
    display: inline-block;
    overflow: hidden;
    width: 11px !important;
    height: 0;
    padding: 11px 0 0 !important;
    margin-left: 3px;
    position: static !important;
    background: url("/img/default/icon/ico_new_hot.gif") no-repeat 0 0;
    vertical-align: -2px;
    *vertical-align: middle;

    &.ico_new {
        background-position: -13px -12px;
    }

    &.ico_sale {
        background-position: -26px -12px;
    }

    &.ico_up {
        width: 15px !important;
        background-position: -39px -12px;
    }
}

#navigateWrap {
    position: relative;
    z-index: 100;
    width: 100%;
    height: 36px;
    background: url("/img/default/icon/bul_squ1px_d6d6d6.gif") repeat-x 0 100%;

    .ico_hot {
        background-position: 0 0;
    }

    .ico_new {
        background-position: -13px 0;
    }

    span.gnb_ico {
        display: inline-block;
        width: 40px !important;
        height: 12px !important;
        margin-left: 3px;
        overflow: hidden !important;
        background: url("/img/default/gnb_sale_v2.gif") no-repeat 0 0;
        vertical-align: -3px;
        *vertical-align: middle;

        span {
            *display: block;
            overflow: hidden;
            position: relative;
            z-index: -1;
            font-size: 11px;
            white-space: nowrap;
            letter-spacing: -1px;
        }
    }
}

.navigateWrap {
    position: relative;
    z-index: 100;
    width: 100%;
    height: 36px;
    background: url("/img/default/icon/bul_squ1px_d6d6d6.gif") repeat-x 0 100%;
}

span.gnb_ico {
    &.srset_tship1 {
        background-position: -303px -54px !important;
    }

    &.srset_tship2 {
        background-position: 0 -54px !important;
    }

    &.srset_tship3 {
        background-position: -42px -54px !important;
    }

    &.srset_tship5 {
        background-position: -84px -54px !important;
    }

    &.srset_tship7 {
        background-position: -345px -54px !important;
    }

    &.srset_tship10 {
        background-position: -132px -54px !important;
    }

    &.srset_tship11 {
        background-position: -177px -54px !important;
    }

    &.srset_tship13 {
        background-position: -219px -54px !important;
    }

    &.srset_tship14 {
        background-position: -261px -54px !important;
    }

    &.srset_s1 {
        background-position: 0 -14px !important;
    }

    &.srset_s2 {
        background-position: -42px -14px !important;
    }

    &.srset_s3 {
        background-position: -84px -14px !important;
    }

    &.srset_s4 {
        background-position: -132px -14px !important;
    }

    &.srset_s5 {
        background-position: -177px -14px !important;
    }

    &.srset_s6 {
        background-position: -219px -14px !important;
    }

    &.srset_s7 {
        background-position: -261px -14px !important;
    }

    &.srset_s8 {
        background-position: -303px -14px !important;
    }

    &.srset_s9 {
        background-position: -345px -14px !important;
    }

    &.srset_s10 {
        background-position: -387px -14px !important;
    }

    &.srset_s11 {
        background-position: 0 -27px !important;
    }

    &.srset_s12 {
        background-position: -42px -27px !important;
    }

    &.srset_s13 {
        background-position: -84px -27px !important;
    }

    &.srset_s14 {
        background-position: -132px -27px !important;
    }

    &.srset_s15 {
        background-position: -177px -27px !important;
    }

    &.srset_s16 {
        background-position: -219px -27px !important;
    }

    &.srset_s17 {
        background-position: -261px -27px !important;
    }

    &.srset_s18 {
        background-position: -303px -27px !important;
    }

    &.srset_s19 {
        background-position: -345px -27px !important;
    }

    &.srset_s20 {
        background-position: -387px -27px !important;
    }

    &.srset_s21 {
        background-position: 0 -40px !important;
    }

    &.srset_s22 {
        background-position: -42px -40px !important;
    }

    &.srset_s23 {
        background-position: -84px -40px !important;
    }

    &.srset_s24 {
        background-position: -132px -40px !important;
    }

    &.srset_s25 {
        background-position: -177px -40px !important;
    }

    &.srset_s30 {
        background-position: -219px -40px !important;
    }

    &.srset_s32 {
        background-position: -261px -40px !important;
    }

    &.srset_s43 {
        background-position: -303px -40px !important;
    }

    &.srset_s50 {
        background-position: -345px -40px !important;
    }

    &.srset_s52 {
        background-position: -387px -40px !important;
    }

    &.srset_s70 {
        background-position: -429px -40px !important;
    }

    &.srset_s100 {
        background-position: 0 0 !important;
        vertical-align: -1px !important;
        *vertical-align: middle !important;
    }

    &.srset_s101 {
        background-position: -42px 0 !important;
    }

    &.srset_s102 {
        width: 46px !important;
        background-position: -84px 0 !important;
    }

    &.srset_s103 {
        width: 43px !important;
        background-position: -132px 0 !important;
    }

    &.srset_s104 {
        background-position: -177px 0 !important;
    }

    &.srset_s105 {
        background-position: -219px 0 !important;
    }

    &.srset_s106 {
        background-position: -261px 0 !important;
    }

    &.csset1 {
        background-position: 0 -73px !important;
    }

    &.csset2 {
        background-position: -45px -73px !important;
    }

    &.csset3 {
        background-position: -90px -73px !important;
    }

    &.csset4 {
        background-position: -135px -73px !important;
    }

    &.csset5 {
        background-position: -180px -73px !important;
    }

    &.csset6 {
        background-position: -225px -73px !important;
    }

    &.csset7 {
        background-position: -270px -73px !important;
    }

    &.csset8 {
        background-position: -315px -73px !important;
    }

    &.csset9 {
        background-position: -360px -73px !important;
    }

    &.csset10 {
        background-position: -405px -73px !important;
    }

    &.csset11 {
        background-position: -450px -73px !important;
    }
}

// listing, product detail icon
.prdico {
    &.totalsale {
        background: url("/img/listing/icon_sp_v2.gif") no-repeat 0 0;
        background-position: -24px -17px;
    }

    &.sktsale {
        background: url("/img/listing/icon_sp_v2.gif") no-repeat 0 0;
        background-position: -24px -51px;
    }

    &.dirgosale {
        background: url("/img/listing/icon_sp_v2.gif") no-repeat 0 0;
        background-position: -24px -34px;
    }

    &.insurance {
        background: url("/img/listing/icon_sp_v2.gif") no-repeat 0 0;
        background-position: -24px -119px;
    }

    &.newbook {
        width: 31px;
        background: url("/img/listing/icon_sp_v2.gif") no-repeat 0 0;
        background-position: -152px -187px;
    }

    &.newbook_free {
        width: 41px;
        background: url("/img/listing/icon_sp_v2.gif") no-repeat 0 0;
        background-position: -152px -204px;
    }

    &.oldbook {
        width: 31px;
        background: url("/img/listing/icon_sp_v2.gif") no-repeat 0 0;
        background-position: -152px -221px;
    }

    &.power_review {
        width: 62px;
        background: url("/img/listing/icon_sp_v2.gif") no-repeat 0 0;
        background-position: -360px -60px;
    }

    &.good_review {
        width: 62px;
        background: url("/img/listing/icon_sp_v2.gif") no-repeat 0 0;
        background-position: -360px -80px;
    }

    &.membership_Per {
        position: relative;
        background: url("/img/listing/icon_sp_v2.gif") no-repeat 0 0;
        background-position: -88px -238px;

        em {
            display: block;
            position: absolute;
            top: 1px;
            left: 32px;
            width: 21px;
            color: #fff;
            font: 10px $font-family-number;
            text-align: center;
            text-indent: 0;
        }
    }

    &.okcash_Per {
        position: relative;
        background: url("/img/listing/icon_sp_v2.gif") no-repeat 0 0;
        background-position: -353px 0;
        width: 74px;

        em {
            display: block;
            position: absolute;
            top: 1px;
            left: 33px;
            width: 51px;
            color: #fff;
            font: 10px $font-family-number;
            text-align: center;
            text-indent: 0;
        }
    }

    &.mart_oneplus {
        background: url("/img/listing/icon_sp_v2.gif") no-repeat 0 0;
        background-position: -360px -105px;
    }

    &.mart_addpre {
        background: url("/img/listing/icon_sp_v2.gif") no-repeat 0 0;
        background-position: -360px -125px;
    }

    &.mart_freegift {
        background: url("/img/listing/icon_sp_v2.gif") no-repeat 0 0;
        background-position: -360px -145px;
    }

    &.daydeliv {
        background: url("/img/listing/icon_sp_v2.gif") no-repeat 0 0;
        background-position: -152px -320px;
    }
}

// order list
.mart_icon {
    position: absolute;
    bottom: 0;
    left: 0;
}

//delivery icon
.ico_deliver1, // 무료배송(default)
.ico_deliver2,
.ico_deliver3,
.ico_deliver4,
.ico_deliver5,
.ico_deliver6,
.ico_deliver7,
.ico_deliver8,
.ico_deliver9,
.ico_deliver10,
.ico_deliver11,
.ico_deliver12,
.ico_deliver13,
.ico_deliver17,
.ico_deliver18,
.ico_benefit1,
.sell_due,
.perf_ing,
.sell_ing,
.sell_end,
.icon_flagN5,
.icon_flagN8,
.icon_flagN9,
.icon_flagN22,
.icon_flagN44,
.icon_flagN53,
.icon_flagN54,
.icon_flagN55 {
    display: inline-block;
    width: 54px;
    height: 17px;
    background: url("/img/listing/listing_total2.gif") no-repeat 0 -320px;
    font-size: 0;
    line-height: 0;
    text-indent: -9999px;
    *text-indent: 0;
    vertical-align: middle;
}

.ico_deliver2 {
    // 조건부무료
    background-position: -56px -340px;
}

.ico_deliver3 {
    // 착불,선결제
    background-position: -56px -360px;
}

.ico_deliver4 {
    // 착불
    background-position: -56px -380px;
}

.ico_deliver5 {
    // 수량별차등
    background-position: -56px -400px;
}

.ico_deliver6 {
    // 선결제가능
    background-position: -56px -420px;
}

.ico_deliver7 {
    // 디지털컨텐츠
    background-position: -56px -440px;
}

.ico_deliver8 {
    // 방문수량
    background-position: -56px -460px;
}

.ico_deliver9 {
    // 이태리직배송 (해외직배송 개편으로 인해 사용안함)
    background-position: -56px -480px;
}

.ico_deliver10 {
    // 해외직배송
    background-position: -56px -500px;
}

.ico_deliver11 {
    // 해외통합(NY)
    background-position: -56px -520px;
}

.ico_deliver12 {
    // 해외통합(LA)
    background-position: -56px -540px;
}

.ico_deliver13 {
    // 해외통합(ATL) 현재이미지는 LA와 동일
    background-position: -56px -560px;
}

.ico_deliver17,
.icon_flagN44 {
    // 배송지연보상
    background-position: 0 -580px;
}

.ico_deliver18 {
    // SMS발송
    background-position: -56px -280px;
}

.ico_deliver1 b,
.ico_deliver2 b,
.ico_deliver3 b,
.ico_deliver4 b,
.ico_deliver5 b,
.ico_deliver6 b,
.ico_deliver7 b,
.ico_deliver8 b,
.ico_deliver9 b,
.ico_deliver10 b,
.ico_deliver11 b,
.ico_deliver12 b,
.ico_deliver13 b,
.ico_deliver17 b,
.ico_deliver18 b {
    visibility: hidden;
}

.sell_due {
    // 판매예정
    background-position: -56px -200px;
}

.perf_ing {
    // 공연중
    background-position: -56px -180px;
}

.sell_ing {
    // 예매중
    background-position: -56px -160px;
}

.sell_end {
    // 판매완료
    background-position: -56px -220px;
}

span.ico_freq_coupon {
    display: inline-block;
    position: relative;
    overflow: hidden;
    width: 70px;
    height: 0;
    background: url("/img/listing/listing_total2.gif") no-repeat -56px -580px;
    padding-top: 17px;

    em {
        display: block;
        position: absolute;
        top: 2px;
        right: 5px;
        width: 20px;
        color: #4e7cd1;
        font: 10px/1.25 $font-family-number;
        font-style: normal;
        text-align: center;
    }
}

// benefit icon
.ico_benefit1, // 할인(default)
.ico_benefit2,
.ico_benefit4,
.ico_benefit5,
.ico_benefit6,
.ico_benefit8,
.ico_benefit9,
.ico_benefit10,
.ico_benefit11,
// MPSR-43727 삭제예정 시작
.ico_benefit12,
// MPSR-43727 삭제예정 끝
.ico_benefit13 {
    display: inline-block;
    width: 37px;
    height: 17px;
    background: url("/img/listing/listing_total2.gif") no-repeat 0 0;
    font-size: 0;
    vertical-align: middle;
    line-height: 0;
}

.ico_benefit1,
.icon_flagN22 {
    // 할인
    width: 37px;
    background-position: -56px -300px;
}

.ico_benefit2,
.icon_flagN5 {
    // 적립
    width: 37px;
    background-position: -56px -320px;
}

.ico_benefit4 {
    // 마일리지
    background-position: -115px -340px;
}

.ico_benefit5,
.icon_flagN8 {
    // 무이자
    width: 37px;
    background-position: -96px -320px;
}

.ico_benefit6 {
    // 복수할인
    background-position: -115px -400px;
}

.ico_benefit8,
.ico_benefit9 {
    // 사은품
    background-position: -115px -420px;
}

.ico_benefit10 {
    // 희망후원
    background-position: -115px -360px;
}

.ico_benefit11 {
    // 오포인트
    width: 46px;
    background-position: -115px -380px;
}

// MPSR-43727 삭제예정 시작
.ico_benefit12 {
    // 11Pay
    width: 78px;
    background-position: -115px -540px;

    em {
        display: block;
        padding-right: 32px;
        color: $color-11st-red;
        font: 11px/17px $font-family-number;
        font-style: normal;
        text-align: right;
        letter-spacing: -1px;
    }
}
// MPSR-43727 삭제예정 끝
.ico_benefit13 {
    // L.POINT
    width: 54px;
    background-position: -115px -560px;
}

.ico_benefit1 b,
.ico_benefit2 b,
.ico_benefit4 b,
.ico_benefit5 b,
.ico_benefit6 b,
.ico_benefit9 b,
.ico_benefit10 b,
.ico_benefit11 b {
    visibility: hidden;
}

div.all_cate .ico_benefit10 {
    font-size: 0;
    text-indent: -9999px;
}

// NEW Icon
.prdico_wrap {
    overflow: hidden;
}

* + html .prdico_wrap {
    overflow: visible;
    zoom: 1;
    line-height: 0;
}

span.prdico,
.ic_lowprice,
.ic_hopeshopping {
    float: left;
    width: 54px;
    height: 17px;
    margin: 0 2px 4px 0;
    background: url("/img/listing/listing_total2.gif") no-repeat 0 0;
    font-size: 0;
    line-height: 0;
    text-indent: -9999px;
}

span.prdico {
    > span {
        display: block;
        position: absolute;
        top: 0;
        right: 4px;
        width: 23px;
        color: #fff;
        font-family: Tahoma, sans-serif;
        font-size: 11px;
        text-align: center;
        line-height: 15px;
        text-indent: 0;
    }

    &.bonus_saving {
        background-position: 0 -20px;
    }

    &.saving {
        background-position: 0 -40px;
    }

    &.secondhand {
        background-position: 0 -60px;
    }

    &.sh_plusup {
        background-position: 0 -80px;
    }

    &.sh_plus {
        background-position: 0 -100px;
    }

    &.sh_boomup {
        background-position: 0 -120px;
    }

    &.hyundai1 {
        background-position: 0 -140px;
    }

    &.hyundai2 {
        background-position: 0 -160px;
    }

    &.hyundai3 {
        background-position: 0 -180px;
    }

    &.hyundai4 {
        background-position: 0 -200px;
    }

    &.hyundai5 {
        background-position: 0 -220px;
    }

    &.hyundai6 {
        background-position: 0 -240px;
    }

    &.hyundai7 {
        background-position: 0 -260px;
    }

    &.hyundai8 {
        background-position: 0 -280px;
    }

    &.hyundai9 {
        background-position: 0 -300px;
    }

    &.hyundai10 {
        background-position: -154px -280px;
    }

    &.hyundai11 {
        background-position: -154px -300px;
    }

    &.hyundai12 {
        background-position: -154px -360px;
    }

    &.hyundai13 {
        background-position: -154px -400px;
    }

    &.lotte {
        background-position: -154px -320px;
    }

    &.mileagesale,
    &.mileage {
        background-position: 0 -419px;
    }

    &.cardsale {
        background-position: 0 -499px;
    }

    &.ohpoint {
        background-position: 0 -540px;
    }

    &.reserve {
        background-position: -56px -240px;
    }

    &.series {
        background-position: -56px -260px;
    }

    &.mfreesale {
        position: relative;
        width: 70px;
        background-position: -56px -140px;
    }

    &.mcardsale {
        position: relative;
        width: 70px;
        background-position: -64px -601px;
    }

    &.mtmemsale {
        position: relative;
        width: 70px;
        background-position: -64px -621px;
    }

    &.delayshipping,
    &.compensate {
        background-position: 0 -580px;
    }

    &.ico_deliver1 {
        background-position: 0 -320px;
    }

    &.t_direct {
        width: 61px;
        height: 17px;
        background-position: 0 -601px;
    }

    &.ssg {
        background-position: -154px -340px;
    }

    &.sohoico_new {
        // 소호신상
        background-position: -154px -180px;
    }

    &.sohoico_newhit {
        // 소호뜨는신상
        background-position: -154px -200px;
    }

    &.sohoico_update {
        // 소호신상업데이트
        background-position: -154px -220px;
    }

    &.sohoico_sale {
        // 소호할인
        background-position: -154px -240px;
    }

    &.sohoico_event {
        // 소호이벤트
        background-position: -154px -260px;
    }

    &.best_rank {
        position: relative;
        background-position: 0 -379px;

        em {
            position: absolute;
            top: 0;
            right: 0;
            width: 36px;
            color: #6b6b6b;
            font-size: 10px;
            text-align: center;
            text-indent: 0;
            line-height: 16px;
        }
    }

    &.lowest,
    &.price_compensate {
        background-position: 0 -560px;
    }

    &.lpoint {
        background-position: -115px -521px;
    }

    &.tmembership {
        width: 85px;
        background-position: -130px -581px;
    }

    &.late_delayshipping {
        width: 70px;
        background-position: 0 -678px;
    }
}

.ic_lowprice {
    background-position: 0 -560px;
}

.ic_hopeshopping {
    background-position: 0 -699px;
}

// icon text
.ico_txt {
    font-family: $font-family-number;
    font-size: 10px;
    color: #666;
    margin: 0 0 0 4px;

    .won {
        font-size: 11px;
        font-style: normal;
        font-family: $font-family-asis;
        display: inline-block;
        *vertical-align: 1px;
        height: 12px;
        line-height: 12px;
    }

    .per {
        font-size: 10px;
        font-style: normal;
    }
}

// h align icon wrap
.list_icoH {
    display: block;
    overflow: hidden;
    height: 17px;
    zoom: 1;
    margin-left: 0px;
    padding-left: 2px;
    text-align: left;
    line-height: 0;

    &:after {
        display: block;
        content: "";
        clear: both;
    }

    span {
        margin-left: -2px;
    }
}

// v align icon wrap
.list_icoV {
    margin: 0 auto;

    span {
        display: block;
        clear: both;
        float: left;
        margin: 0 0 2px;
    }
}

// benefit h align icon wrap
.benefit_icoH {
    display: block;
    overflow: hidden;
}

// benefit v align icon wrap
.benefit_icoV {
    display: block;

    p {
        display: block;
        margin-bottom: 2px;
    }
}

// seller icon
.ico_seller1,
.ico_seller2,
.ico_seller3 {
    display: inline-block;
    width: 17px;
    height: 39px;
    padding: 0 3px;
    background: url("/img/listing/icon_sp_v2.gif") no-repeat -277px -170px;
    vertical-align: middle;
    font-size: 0;
    line-height: 0;
}

.ico_seller2 {
    background-position: -277px -170px;
}

.ico_seller1 b,
.ico_seller2 b,
.ico_seller3 b {
    visibility: hidden;
}

// seller icon_v2
.ico_seller1_v2 {
    display: inline-block;
    width: 17px;
    height: 39px;
    padding: 0 3px;
    background: url("/img/listing/icon_sp_v2.gif") no-repeat -277px -170px;
    vertical-align: middle;
    font-size: 0;
    line-height: 0;
}

.ico_seller2_v2 {
    display: inline-block;
    width: 17px;
    height: 39px;
    padding: 0 3px;
    background: url("/img/listing/icon_sp_v2.gif") no-repeat -277px -170px;
    vertical-align: middle;
    font-size: 0;
    line-height: 0;
    background-position: -297px -170px;
}

.ico_seller1_v2 b,
.ico_seller2_v2 b {
    visibility: hidden;
}

// seller icon - 가로형
.ico_w53_seller1 {
    display: inline-block;
    width: 53px;
    height: 15px;
    padding: 0 3px;
    background: url("/img/listing/icon_sp_v2.gif") no-repeat -280px -215px;
    vertical-align: middle;
    font-size: 0;
    line-height: 0;
}

.ico_w53_seller2 {
    display: inline-block;
    width: 53px;
    height: 15px;
    padding: 0 3px;
    background: url("/img/listing/icon_sp_v2.gif") no-repeat -280px -215px;
    vertical-align: middle;
    font-size: 0;
    line-height: 0;
    background-position: -280px -236px;
}

.ico_w53_seller1 b,
.ico_w53_seller2 b {
    visibility: hidden;
}

.ico_w49_seller1 {
    display: inline-block;
    width: 49px;
    height: 13px;
    margin: 0 2px;
    background: url("/img/listing/icon_sp_v2.gif") no-repeat -280px -252px;
    vertical-align: middle;
    font-size: 0;
    line-height: 0;
}

.ico_w49_seller2 {
    display: inline-block;
    width: 49px;
    height: 13px;
    margin: 0 2px;
    background: url("/img/listing/icon_sp_v2.gif") no-repeat -280px -252px;
    vertical-align: middle;
    font-size: 0;
    line-height: 0;
    background-position: -280px -268px;
}

.ico_w49_seller1 b,
.ico_w49_seller2 b {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    margin: -1px;
    width: 1px;
    height: 1px;
}

// seller star icon
.ico_selr_star {
    display: inline-block;
    overflow: hidden;
    width: 69px;
    height: 0px;
    padding-top: 12px;
    background: url("/img/default/icon/ico_star.png") no-repeat left top;
    vertical-align: middle;

    &.selr_star5 {
        background-position: 0 0;
    }

    &.selr_star4 {
        background-position: 0 -13px;
    }

    &.selr_star3 {
        background-position: 0 -26px;
    }

    &.selr_star2 {
        background-position: 0 -39px;
    }

    &.selr_star1 {
        background-position: 0 -52px;
    }

    &.selr_star0 {
        background-position: 0 -65px;
    }
}

// review star icon
.ico_review_star {
    display: inline-block;
    overflow: hidden;
    width: 65px;
    height: 0px;
    padding-top: 10px;
    background: url("/img/default/icon/ico_star02.png") no-repeat left top;
    vertical-align: middle;

    &.review_star10 {
        background-position: 0 0px;
    }

    &.review_star9 {
        background-position: 0 -10px;
    }

    &.review_star8 {
        background-position: 0 -20px;
    }

    &.review_star7 {
        background-position: 0 -30px;
    }

    &.review_star6 {
        background-position: 0 -40px;
    }

    &.review_star5 {
        background-position: 0 -50px;
    }

    &.review_star4 {
        background-position: 0 -60px;
    }

    &.review_star3 {
        background-position: 0 -70px;
    }

    &.review_star2 {
        background-position: 0 -80px;
    }

    &.review_star1 {
        background-position: 0 -90px;
    }

    &.review_star0 {
        background-position: 0 -100px;
    }
}

// review star icon V2
.ico_rev_star {
    position: relative;
    display: inline-block;
    overflow: hidden;
    width: 70px;
    height: 12px;
    background: url("/img/default/icon/rev_star.gif") no-repeat 0 0;

    em {
        position: absolute;
        top: 0;
        left: 0;
        width: 70px;
        height: 12px;
        background: url("/img/default/icon/rev_star.gif") no-repeat 0 -13px;
        text-indent: -9999px;
    }

    &.rev_star1 em {
        width: 14px;
    }

    &.rev_star2 em {
        width: 28px;
    }

    &.rev_star3 em {
        width: 42px;
    }

    &.rev_star4 em {
        width: 56px;
    }

    &.rev_star5 em {
        width: 70px;
    }
}

// satisf_star
.satisf_area {
    float: left;
    margin-right: 20px;
    color: #757575;

    &:hover {
        text-decoration: none;
    }

    > em {
        display: inline-block;
        width: 70px;
        height: 12px;
        margin: 1px 5px 0 0;
        background: url("/img/default/icon/ico_star03.png") no-repeat 0 0;
        color: #000;
        font-size: 0;
        text-indent: -9999px;

        span {
            display: block;
            height: 12px;
            background: url("/img/default/icon/ico_star03.png") no-repeat 0 -15px;
        }
    }

    span:hover {
        text-decoration: underline;
    }

    > em {
        &.star100 span {
            width: 100%;
        }

        &.star90 span {
            width: 90%;
        }

        &.star80 span {
            width: 80%;
        }

        &.star70 span {
            width: 70%;
        }

        &.star60 span {
            width: 60%;
        }

        &.star50 span {
            width: 50%;
        }

        &.star40 span {
            width: 40%;
        }

        &.star30 span {
            width: 30%;
        }

        &.star20 span {
            width: 20%;
        }

        &.star10 span {
            width: 10%;
        }
    }
}

// cmpr_price
.statu_area .satisf_area {
    float: none;
    margin-right: 0;

    em {
        display: inline-block;
        overflow: hidden;
        width: 98px;
        height: 20px;
        background: url("/img/default/icon/ico_revstar2.png") no-repeat 0 0;
        text-align: left;
    }

    > em {
        span {
            display: inline-block;
            width: 0;
            height: 0px;
            padding-top: 20px;
            background: url("/img/default/icon/ico_revstar2.png") no-repeat 0 -20px;
            text-indent: 0 !important;
        }

        &.star100 span {
            width: 100%;
        }

        &.star90 span {
            width: 90%;
        }

        &.star80 span {
            width: 80%;
        }

        &.star70 span {
            width: 70%;
        }

        &.star60 span {
            width: 60%;
        }

        &.star50 span {
            width: 50%;
        }

        &.star40 span {
            width: 40%;
        }

        &.star30 span {
            width: 30%;
        }

        &.star20 span {
            width: 20%;
        }

        &.star10 span {
            width: 10%;
        }
    }
}

// 구매자 등급
.ico_custom {
    display: inline-block;
    overflow: hidden;
    width: 12px;
    height: 12px;
    background: url("/img/default/icon/icon_level.gif") no-repeat 0 -51px;
    vertical-align: middle;
    font-size: 0;
    line-height: 200px;

    &.customlv1 {
        // vvip
        background-position: 0 0;
    }

    &.customlv2 {
        // vip
        background-position: -49px 0;
    }

    &.customlv3 {
        // top
        background-position: -90px 0;
    }

    &.customlv4 {
        // best
        background-position: -129px 0;
    }

    &.customlv6 {
        // new
        background-position: -174px 0;
    }
}

.ico_grade_com {
    display: inline-block;
    overflow: hidden;
    height: 14px;
    margin-top: 2px;
    background: url("/img/common/v2/sp_grade.png") no-repeat;
    vertical-align: top;
    font-size: 0;
    line-height: 200px;

    &.vip {
        width: 36px;
        background-position: 0 0;
    }
    &.family {
        width: 59px;
        background-position: -40px 0;
    }
    &.biz {
        width: 67px;
        background-position: 0 -18px;
    }
}

// 구매 만족도
.ico_buy_grade_sis {
    display: inline-block;
    width: 33px;
    height: 22px;
    padding: 10px 0 0;
    border: 1px solid #fe656a;
    background: #fff;
    font-size: 11px;
    color: #e21912;
    letter-spacing: -1px;
    text-align: center;

    &.buysatisfy3 {
        // 만족
        border-color: #fe656a;
        color: #e21912;
    }

    &.buysatisfy2 {
        // 보통
        border-color: #7692f3;
        color: #4f6cd0;
    }

    &.buysatisfy1 {
        // 불만
        border-color: #979797;
        color: #666;
    }

    &.buysatisfy_sm {
        padding: 3px 0 0;
        height: 15px;
    }
}

// 상품 만족도
.ico_prdc_grade_sis {
    position: relative;
    width: 21px;
    height: 36px;
    margin-top: -17px;
    display: inline-block;
    background: url("/img/default/icon/icon_level02.gif") no-repeat 0 0;

    em {
        position: absolute;
        left: -45%;
        bottom: -22px;
        width: 41px;
        display: block;
        font-size: 11px;
        letter-spacing: -1px;
        line-height: 1.4;
        font-weight: normal;
    }

    &.prdsatisfy3 {
        // 적극추천
        background-position: -0px 0;
    }

    &.prdsatisfy2 {
        // 추천
        background-position: -22px 0;
    }

    &.prdsatisfy1 {
        // 보통
        background-position: -44px 0;
    }

    &.prdsatisfy0 {
        // 추천안함
        background-position: -66px 0;
    }
}

.direct_icon {
    display: inline-block;
    width: 76px;
    height: 17px;
    background: url("/img/default/icon/ico_direct_11st04.gif") no-repeat 0 -19px;
    font-size: 0;
    line-height: 0;
    overflow: hidden;
    vertical-align: middle;
    &.on {
        background-position: 0 0;
    }
}

// PRODUCT DEFAULT INFO
.pub_photo {
    display: block;
    margin-bottom: 8px;
    font-size: 0;
    text-align: left;
    line-height: 0;
    cursor: pointer;

    img {
        border: 1px solid #e6e6e6;
    }
}

.pub_info {
    display: block;
}

.pub_brandW {
    min-height: 11px;
    margin-bottom: 4px;
}

.pub_brand {
    display: block;
    min-height: 11px;
    font-weight: bold;
    text-align: left;
    color: #4d4d4d;
}

.pub_title {
    display: block;
    overflow: hidden;
    height: 30px;
    margin-bottom: 7px;
    color: #4d4d4d;
    font-style: normal;
    text-align: left;
    cursor: pointer;

    a {
        display: inline-block;
        word-wrap: break-word;
        word-break: break-all;
        color: #4d4d4d;

        &:hover {
            color: #4d4d4d;
        }
    }
}

.pub_priceW {
    display: block;
    height: 29px;
}

.pub_price {
    display: block;
    margin-bottom: -1px;
    color: #4d4d4d;
    font: 11px $font-family-number;
    text-align: left;
    text-decoration: none;

    .won {
        font: 11px $font-family-asis;
    }
}

.ico_txt .per,
.pub_price .per {
    font-size: 10px;
}

.pub_salep {
    display: block;
    font: 12px $font-family-number;
    color: #111;
    font-weight: bold;
    text-align: left;
    line-height: 14px;

    .won {
        font: 12px $font-family-asis;
        font-weight: bold;
    }

    .salse {
        color: #fb0808;
        font-size: 11px;
        font-weight: bold;
    }

    .per {
        font-size: 10px;
    }
}

.pub_subinfoW {
    margin-top: 7px;
    color: #878787;
    font-size: 11px;
    text-align: left;

    a {
        color: #878787;
        text-decoration: underline;

        &:hover {
            text-decoration: underline !important;
        }
    }
}

// align type common
.pub_alignC,
.pub_alignL,
.pub_alignR {
    display: block;
    zoom: 1;
    overflow: hidden;
}

.pub_alignC:hover,
.pub_alignL:hover,
.pub_alignR:hover {
    text-decoration: none;
}

.pub_alignC:hover .pub_title,
.pub_alignL:hover .pub_title,
.pub_alignR:hover .pub_title {
    text-decoration: underline;
}

.pub_alignC a:hover,
.pub_alignL a:hover,
.pub_alignR a:hover {
    text-decoration: none;
}

.pub_alignC a:hover .pub_title,
.pub_alignL a:hover .pub_title,
.pub_alignR a:hover .pub_title {
    text-decoration: underline;
}

.pub_alignC {
    .pub_photo,
    .pub_brand,
    .pub_title,
    .pub_price,
    .pub_salep,
    .list_icoH,
    .pub_subinfoW {
        text-align: center;
    }
}

.pub_alignL {
    .pub_brand,
    .pub_title,
    .pub_price,
    .pub_salep,
    .list_icoH,
    .pub_subinfoW {
        text-align: left;
    }
    .pub_photo {
        text-align: center;
    }
}

.list_icoH {
    margin-top: 8px;
}

.pub_alignR {
    .pub_photo,
    .pub_brand,
    .pub_title,
    .pub_price,
    .pub_salep,
    .list_icoH,
    .pub_subinfoW {
        text-align: left;
    }
    .pub_photo {
        float: left;
        margin-bottom: 0;
        margin-right: 7px;
    }
    .pub_info {
        float: left;
    }
}

// shop name
.shop_linkW {
    display: block;
    height: 19px;
    margin-top: -10px;
    margin-bottom: 10px;
    padding: 7px 0 0;
    border: 1px solid #ddd;
    border-top: none;
    background: #f5f5f5;
    color: #646464;
    font-weight: bold;
    text-align: center;
    cursor: pointer;

    &:hover {
        color: #646464;
    }
}

.shop_linkW_v2 a:hover {
    color: #646464;
}

.shop_linkW .shop_link,
.shop_linkW_v2 .shop_link {
    display: block;
    width: 95px;
    height: 12px;
    overflow: hidden;
    margin: 0 auto;
}

.shop_linkW .ico_shop,
.shop_linkW_v2 .ico_shop {
    display: inline-block;
    width: 13px;
    height: 12px;
    margin: 0 2px 3px 0;
    _margin: 0 2px 0 0;
    background: url("/img/ctgr/img_sp.gif") no-repeat -66px -44px;
    vertical-align: middle;
}

.shop_linkW .shop_name {
    width: 68px;
    height: 12px;
    overflow: hidden;
    font-size: 11px;
}

.shop_linkW_v2 {
    margin-top: -1px;
    margin-bottom: 12px;
    height: 25px;

    .shop_name {
        width: 68px;
        height: 12px;
        overflow: hidden;
        font-size: 11px;
    }

    a {
        display: block;
        height: 13px;
        margin-bottom: 2px;
        padding: 0;
        background: #f5f5f5;
        color: #646464;
        font-weight: bold;
        text-align: center;
        cursor: pointer;
    }

    .shop_link {
        width: 135px;
    }

    .shop_name {
        font-size: 12px;
    }

    .dan_sale {
        color: #e71818;
        font-size: 12px;
        text-align: center;
    }
}

// PAGING
.s_paging {
    position: relative;
    width: 100%;
    height: 22px;
    margin-top: 10px;
    font-family: $font-family-number;
    text-align: center;

    a {
        display: inline-block;
        width: 21px;
        height: 21px;
        background: url("/img/listing/listinfo_img.gif") no-repeat -127px -42px;
        vertical-align: middle;

        span {
            display: block;
            padding: 0 !important;
            font-size: 0;
            text-indent: -9999em;
        }

        &.prev {
            margin-left: -5px;
            background-position: -147px -42px;
        }

        &.next {
            margin-right: -5px;
            background-position: -169px -42px;
        }

        &.last {
            background-position: -189px -42px;
        }
    }

    span {
        display: inline-block;
        padding: 1px 10px;

        a,
        strong {
            display: inline-block;
            width: auto;
            min-width: 14px;
            height: 19px;
            margin: 0 -1px;
            padding: 0 4px;
            color: #333;
            border: 1px solid #fff;
            background: none;
            font-size: 14px;
            font-weight: bold;
            text-indent: 0;
            text-decoration: none !important;
            vertical-align: middle;
            *vertical-align: -2px;
        }

        a:hover,
        strong {
            border: 1px solid #db8883;
            color: #e81818;
        }
    }

    .total_area {
        position: absolute;
        right: 0;
        top: 0;
        color: #999;

        input {
            width: 32px;
            height: 11px;
            margin-right: 3px;
            color: #4d4d4d;
            font-family: $font-family-number;
            text-align: right;
            line-height: 11px;
        }

        em {
            color: #999;
            font-style: normal;
            font-weight: bold;
        }

        a {
            width: auto;
            margin: 6px 3px 0 0;
            *margin-top: 8px;
            padding-right: 7px;
            background: url("/img/common/icon/bul_arrow_gray_3c.gif") no-repeat 100% 3px;
            color: #666;
            font-family: $font-family-asis;
            font-size: 11px;
            letter-spacing: -1px;
            text-decoration: underline;

            &.next {
                width: 19px;
                height: 19px;
                margin: 0 -2px;
                background: url("/img/listing/listinfo_img.gif") no-repeat -215px -43px;
                font-size: 0;
                text-indent: -9999em;
                vertical-align: 14px;
                *vertical-align: -5px;
            }
        }
    }
}

.mini_paging {
    position: relative;
    width: 100%;
    height: 22px;
    margin-top: 8px;
    font-family: $font-family-number;
    text-align: center;

    a {
        display: inline-block;
        width: 16px;
        height: 16px;
        background: url("/img/listing/listinfo_img.gif") no-repeat -127px -64px;
        vertical-align: middle;

        span {
            display: block;
            padding: 0 !important;
            font-size: 0;
            text-indent: -9999em;
        }

        &.prev {
            margin-left: -5px;
            background-position: -142px -64px;
        }

        &.next {
            margin-right: -5px;
            background-position: -159px -64px;
        }

        &.last {
            background-position: -174px -64px;
        }
    }

    span {
        display: inline-block;
        padding: 1px 10px 1px 5px;

        a,
        strong {
            display: inline-block;
            width: auto;
            min-width: 14px;
            height: 14px;
            margin: 0 -1px;
            _padding: 0 4px;
            color: #656565;
            border: 1px solid #fff;
            background: #fff;
            font-size: 11px;
            font-weight: bold;
            text-indent: 0;
            text-decoration: none !important;
            vertical-align: middle;
            *vertical-align: -2px;
        }

        a:hover,
        strong {
            border: 1px solid #db8883;
            color: #e81818;
        }
    }
}

// [LAYER POPUP]
.layer_def_a,
.layer_def_a2,
.layer_def_a3,
.layer_def_a4,
.layer_def_b,
.layer_def_b2,
.layer_def_b3,
.layer_def_c,
.layer_def_c2,
.layer_def_c3 {
    position: absolute;
    padding: 2px 6px 6px;
    border: 1px solid #8b8b8b;
    background: #fff;
    color: #666;
    font: 11px $font-family-asis;
    line-height: 120%;
}

.layer_def_a2,
.layer_def_b2,
.layer_def_c2 {
    border-style: solid;
    border-width: 2px;
}

.layer_def_a3,
.layer_def_b3,
.layer_def_c3 {
    padding: 5px 0 0;
    border: none;
    background: url("/img/default/layer_arrup.gif") no-repeat 50% 0;
    font-size: 11px;
    letter-spacing: -1px;

    .layer_conts {
        padding: 10px 9px 6px;
        border: 1px solid #8b8b8b;
        border-top: none;
    }
}

.layer_def_a4 {
    padding: 0 0 5px;
    border: none;
    background: url("/img/default/layer_arrdown.gif") no-repeat 50% 100%;
    font-size: 11px;
    letter-spacing: -1px;

    .layer_cont {
        padding: 10px 9px 6px;
        border: 1px solid #8b8b8b;
        border-bottom: none;
    }
}

.layer_conts {
    padding: 8px 3px 0;
    background: #fff;
}

.layer_tit {
    padding: 5px 0 4px 3px;
    border-bottom: 1px solid #b2b2b2;
    color: #111;
    line-height: 120%;
}

button.btn_layclose {
    position: absolute;
    top: 4px;
    right: 5px;
    width: 18px;
    height: 18px;
    background: url("/img/default/btn/delete04.gif") no-repeat 50% 50%;
    font-size: 0;
    text-indent: -9999px;
}

.layer_def_b,
.layer_def_b2,
.layer_def_b3 {
    border-color: #333;
}
.layer_def_b3 {
    background-image: url("/img/default/layer_arrup_b.gif");

    .layer_conts {
        border-color: #333;
    }
}

.layer_def_c,
.layer_def_c2,
.layer_def_c3 {
    border-color: #e61818;
}

.layer_def_c3 {
    background-image: url("/img/default/layer_arrup_c.gif");

    .layer_conts {
        border-color: #e61818;
    }
}

// Have Title
.have_tit {
    padding: 2px 7px 7px;
}

.have_tit2 {
    padding: 2px 7px 7px;

    .layer_tit {
        padding: 7px 0 6px 3px;
        font-size: 12px;
        text-align: left;
    }

    .layer_conts {
        padding: 10px 3px 0;
    }

    button.btn_layclose {
        top: 6px;
        background-image: url("/img/default/btn/delete03.gif");
    }
}

:root {
    .layer_tit {
        padding: 6px 0 3px 3px\9;
    }

    .have_tit2 .layer_tit {
        padding: 9px 0 4px 3px\9;
    }
}

// Have Close Button
.have_close {
    .layer_conts {
        padding-right: 20px;
    }

    button.btn_layclose {
        top: 0;
        right: 0;
    }
}

.layer_def_a3 button.btn_layclose,
.layer_def_b3 button.btn_layclose,
.layer_def_c3 button.btn_layclose {
    top: 6px;
    right: 0;
}

.layer_stit {
    padding-top: 15px;
    color: #111;
    text-align: left;

    span {
        padding-left: 2px;
        font-weight: normal;

        a {
            padding-left: 2px;
            color: #111;
            font-weight: bold;
            font-family: $font-family-asis;
            letter-spacing: 0;
        }
    }

    &.tfirst {
        padding-top: 0;
    }
}

// GLOBAL COMMON
#wrapBody {
    zoom: 1;
    width: 100% !important;
    min-width: 1280px !important;
}

#layBodyWrap {
    position: relative;
    z-index: 20;
    width: 980px;
    margin: 0 auto;
    padding: 10px 0 50px;
    outline: none;
}

#locationWrap {
    position: relative;
    z-index: 30;
    width: 980px;
    margin: 0 auto;
    padding-top: 10px;

    &:after {
        display: block;
        clear: both;
        content: "";
    }

    h2 {
        font-size: 0;
        text-indent: -9999px;
    }

    .go_home {
        float: left;
        padding: 2px 8px 0;
        color: #666;
    }

    button {
        overflow: hidden;
        width: 178px;
        height: 21px;
        padding: 0 22px 0 8px;
        border: 1px solid #bbb;
        background: #fff url("/img/default/btn/select_arr.gif") no-repeat 100% 0;
        color: #4d4d4d;
        font: 12px/110% $font-family-asis;
        text-align: left;
    }

    .locastep {
        position: relative;
        float: left;
        padding: 0 7px 0 12px;
        background: url("/img/default/icon/bar_arr9px_666.gif") no-repeat 0 4px;

        &.txtyp {
            // 텍스트형
            float: left;
            padding: 2px 8px 0 13px;
        }
    }

    div {
        &.loca_conts {
            display: none;
            position: absolute;
            top: 23px;
            left: 12px;
            overflow: hidden;
            width: 174px;
            padding: 5px 0;
            border: 2px solid #666;
            background: #fff;
        }

        &.locastep.selected .loca_conts {
            display: block;
        }

        &.loca_conts {
            ul {
                width: auto;
                line-height: 18px;
            }

            li {
                float: left;
                width: 179px !important;
                vertical-align: top;

                a {
                    padding-left: 10px;
                }
            }

            ul em {
                color: #e71818;
                font-weight: bold;
            }

            &.li_5over {
                width: 358px;
                background: #fff url("/img/default/icon/bar_10px_dcdcdc.gif") repeat-y 177px 0;
            }
        }
    }
}

div.loca_conts.li_50over {
    width: 358px;
    background: #fff url("/img/default/icon/bar_10px_dcdcdc.gif") repeat-y 177px 0;
}

#locationWrap div {
    &.loca_conts {
        &.li_5over li {
            float: left;
        }

        &.li_50over {
            height: 412px;
            background-position: 170px 0;

            ul {
                height: 410px;
                overflow: scroll;
                overflow-x: hidden;

                li {
                    width: 170px;
                }
            }
        }
    }

    &.first_cate div.loca_conts {
        width: 712px;
        padding: 0;
    }

    &.loca_conts div {
        float: left;
        width: 178px;
        height: 270px;
        margin-left: -1px;
        border-left: 1px solid #eaeaea;

        &.cate_firstline {
            height: 270px;
        }

        h3 {
            height: 32px;
            padding-left: 10px;
            border-bottom: 1px solid #cbcbcb;
            background: #f2f2f2;
            color: #333;
            line-height: 35px;
        }

        ul {
            padding-top: 5px;
        }

        li {
            float: none;
        }
    }

    &.locastep.last_cate {
        background: none !important;
    }

    &.loca_conts.loca_specialshop {
        width: 378px;
        min-height: 252px;
        _height: 252px;
        background: #fff url("/img/default/location_special_bg.gif") repeat-y 100% 0;

        li {
            float: none;

            a {
                display: block;
                line-height: 22px;

                &:hover {
                    background: #f5f5f5;
                    color: #e71818;
                    font-weight: bold;
                }
            }
        }
    }
}

div.loca_conts.loca_specialshop li.selected a {
    background: #f5f5f5;
    color: #e71818;
    font-weight: bold;
}

#locationWrap div.loca_conts.loca_specialshop {
    ul ul {
        display: none;
        overflow: auto;
        overflow-x: hidden;
        position: absolute;
        top: 7px;
        left: 185px;
        width: 188px;
        height: 250px;
        background: #f5f5f5;
    }
    li:hover ul {
        display: block;
    }
}

div.loca_conts.loca_specialshop .selected ul {
    display: block;
}

#locationWrap {
    div.loca_conts.loca_specialshop li.selected ul a {
        color: #4d4d4d;
        font-weight: normal;
    }

    button.btn_rss {
        width: 18px;
        height: 18px;
        padding: 0;
        border: none;
        background: url("/img/default/icon/ico_rss.gif") no-repeat 0 0;
        font-size: 0;
        text-indent: -9999px;
    }
}

// model search*/
div.topdetail_wrap .topdetail_conts {
    #locationWrap {
        padding-top: 0;
    }

    p {
        padding: 9px 0 !important;
    }
}

#layBody {
    zoom: 1;

    &:after {
        display: block;
        clear: both;
        content: "";
    }
}

// new footer search
div.footer_search2 {
    position: relative;
    z-index: 1;
    width: 980px;
    margin: 0 auto;
    padding: 20px 0 21px;
    border-top: 1px solid #d8d8da;
    background: #f1f1f1;
    font-size: 12px;
    font-family: $font-family-default;
    text-align: center;

    fieldset {
        display: inline-block;
        position: relative;
        height: 34px;
        overflow: hidden;
    }

    fieldset legend {
        position: absolute;
        font-size: 0;
        overflow: hidden;
        text-indent: -9999px;
    }

    input {
        vertical-align: middle;
    }

    fieldset.total_search {
        position: relative;
        zoom: 1;
        overflow: visible;
        width: 385px;
        margin-left: 0px;
        padding: 0 0 0 75px;
        background: url("/img/default/footer_search.png") no-repeat 0 0;

        h4 {
            display: block;
            float: left;
            height: 14px;
            width: 64px;
            margin: 9px 0 0 0px;
            *margin: 8px 0 0 0px;
            padding: 1px 8px 0 6px;
            color: #e81818;
            line-height: 14px;
            font-weight: normal;
            overflow: hidden;
            text-align: left;
        }

        .text {
            float: left;
            position: relative;
            width: 220px;
            top: 5px;
            left: 7px;
            padding: 2px 0;
            border: 1px solid #fff;
            color: #111;
            font-size: 12px;
            font-weight: bold;
        }

        .search {
            display: block;
            position: absolute;
            top: 0;
            right: 0px;
            width: 55px;
            height: 34px;
            padding: 0;
            border: 0;
            background: url("/img/default/footer_search.png") no-repeat -405px 0;
            font-size: 0;
            text-indent: -9999px;
            cursor: pointer;
        }
    }

    .btn_research {
        position: absolute;
        top: 37px;
        right: 20px;
        cursor: pointer;

        &:hover {
            text-decoration: none;
        }

        .ico_rsch {
            display: inline-block;
            width: 14px;
            height: 13px;
            margin: 0px 5px 2px 0;
            background: url("/img/browsing/search_img.gif") no-repeat -502px -390px;
            overflow: hidden;
            vertical-align: middle;

            em {
                visibility: hidden;
            }
        }
    }
}

:root div.footer_search2 fieldset {
    vertical-align: middle;
}

// My History
.noview_product {
    font-size: 11px;
    padding-top: 75px;
    color: #757575;
    text-align: center;
}

.loading_conts {
    padding: 80px 0 77px;
    text-align: center;
}

// My History ver.2
.my_history2_wrap {
    width: 980px;
    margin: 0 auto 10px auto;
    background: #fff;
    font-size: 12px;
    font-family: $font-family-default;
    line-height: 18px;
    letter-spacing: 0;

    &:after {
        clear: both;
        content: "";
        display: block;
    }

    .hgroup {
        overflow: hidden;
        height: 34px;
        border: 1px solid #515da5;
        background: #5f6cb2;

        h3 {
            float: left;
            padding: 11px 9px 0 19px;
        }

        p {
            float: left;
            color: #dfe4ff;
            font-size: 11px;
            line-height: 34px;
        }
    }

    input.in_clear {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 13px;
        height: 13px;
        border: 0;
        background: url("/img/common/myhistorybtn_delete.gif") no-repeat 0 0;
        font-size: 0;
        text-indent: -9999px;
        cursor: pointer;
    }

    .noview_product {
        font-size: 12px;
        padding-top: 115px;
        text-align: center;
    }

    .my_wrap {
        position: relative;
        height: 245px;
        zoom: 1;
        border: 1px solid #ddd;
        border-top: 0;
    }

    .list_wrap {
        .tab {
            display: block;
            width: 126px;
            height: 81px;
            border-right: 1px solid #ddd;
            background: #f5f5f5;
            font-size: 12px;
            letter-spacing: 0;

            span {
                display: block;
                text-align: center;
                font-weight: normal;
                line-height: 81px;
                color: #999;
            }

            a {
                display: block;
                color: #333;
            }
        }

        + .list_wrap .tab {
            border-top: 1px solid #ddd;
        }

        &.selected {
            .tab {
                background: #fff;
                border-right: 0;

                span {
                    color: #4d6ccd;
                    font-weight: bold;
                }

                em {
                    font-weight: normal;
                }
            }

            .innerbox {
                display: block;
            }
        }
    }

    .btnctr_pn {
        position: absolute;
        top: 20px;
        left: 0;
        text-align: right;
        padding-right: 30px;

        button {
            position: absolute;
            z-index: 2;
            top: 88px;
            width: 30px;
            height: 30px;
            background: url("/img/default/main/prev_next.png") no-repeat 0 -149px;
            opacity: 0.2;
            filter: alpha(opacity = 20);
            -webkit-transition: opacity 0.3s;
            -moz-transition: opacity 0.3s;
            -o-transition: opacity 0.3s;
            transition: opacity 0.3s;
        }

        .in_prev {
            left: 0;
        }

        button.in_next {
            right: 0;
            background-position: -33px -149px;
        }
    }

    .prd_wrap:hover .btnctr_pn button,
    .recom_prd:hover .btnctr_pn button {
        background-position: 0 -114px;
        opacity: 0.2;
        filter: alpha(opacity = 20);
    }

    .prd_wrap:hover .btnctr_pn button.in_next,
    .recom_prd:hover .btnctr_pn button.in_next {
        background-position: -33px -114px;
    }

    .innerbox .btnctr_pn button {
        &:hover,
        &:focus {
            opacity: 0.7;
            filter: alpha(opacity = 70);
        }
    }

    .my_wrap {
        .innerbox {
            display: none;
            position: absolute;
            top: 0px;
            left: 127px;
            right: 0;
            zoom: 1;
        }

        .prd_wrap {
            position: relative;
            float: left;
            margin-top: 20px;
            padding-bottom: 0;
            width: 24%;
            min-width: 210px;
            height: 210px;
            border-right: 1px solid #ddd;

            .viewport {
                margin: 18px 20px 0;
            }

            .btnctr_pn {
                top: 0px;
            }

            .thumb {
                display: block;
                width: 150px;
                height: 150px;
            }

            .viewport img {
                max-width: 150px;
                max-height: 150px;
            }
        }
    }

    .product_wrap {
        position: relative;
        width: 150px;
        margin: 0 auto;
        text-align: center;

        a {
            display: block;
        }

        span.tit {
            display: block;
            overflow: hidden;
            height: 16px;
            padding-top: 8px;
            line-height: 16px;
            color: #4d4d4d;
            cursor: pointer;
        }
    }

    .price {
        display: block;
        color: #111;
        font-weight: bold;
        font-family: Tahoma, sans-serif;
        font-size: 13px;
        line-height: 17px;
    }

    .recom_prd {
        overflow: hidden;
        position: relative;
        height: 245px;
        margin: 0;

        h5 {
            padding: 20px 0 0 30px;
            color: #111;
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
        }

        ul {
            overflow: hidden;
            height: 207px;
            margin: 0 20px;
        }

        li {
            float: left;
            width: 25%;
            height: 207px;
            text-align: center;

            a {
                display: block;
                width: 130px;
                margin: 0 auto;
                line-height: 16px;

                .thumb {
                    display: block;
                    width: 130px;
                    height: 130px;
                }

                img {
                    max-width: 130px;
                    max-height: 130px;
                }

                .tit {
                    display: block;
                    overflow: hidden;
                    padding-top: 12px;
                    height: 32px;
                }
            }
        }

        .btnctr_pn button.in_prev {
            left: -1px;
        }

        .noview_product {
            padding-top: 85px;
        }
    }
}

.latelyview2_conts {
    overflow: hidden;
    zoom: 1;
    border: 1px solid #d7dbe4;
    border-top: none;
    border-bottom: 1px solid #b1b1b1;
    padding: 5px 0;
    line-height: 18px;

    div {
        overflow: hidden;
        zoom: 1;

        &:first-child {
            margin-left: 0;
            border-right: none;
        }
    }

    h4 {
        float: left;
        padding-left: 20px;
        font-size: 12px;
        line-height: 18px;
        color: #333;
        letter-spacing: 0;
    }

    ul {
        float: left;
        max-width: 370px;
    }

    li {
        float: left;
        padding-left: 10px;
        white-space: nowrap;

        input {
            display: inline-block;
            overflow: hidden;
            width: 18px;
            height: 18px;
            border: none;
            background: url("/img/default/btn/delete06.gif") no-repeat 45% 45%;
            font-size: 0;
            text-indent: -9999px;
            vertical-align: top;
            cursor: pointer;
        }

        a {
            display: inline-block;
            color: #4d4d4d;
            vertical-align: top;
        }
    }
}

// [FOOTER BANNER]
.ftr_banner {
    overflow: hidden;
    width: 980px;
    margin: 0 auto 10px;
    text-align: center;

    a {
        display: block;
        position: relative;

        > img {
            max-width: 100%;
            vertical-align: top;
        }
    }

    .bnrborder {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border: 1px solid #000;
        opacity: 0.08;
        filter: alpha(opacity = 8);
        z-index: 2;
    }

    span.ad {
        display: block;
        position: absolute;
        top: 4px;
        right: 4px;

        img {
            margin: 0;
        }
    }
}

// [WING BANNER]
.prom_wingbnr {
    position: absolute;
    top: 180px;
    left: 50%;
    margin-left: -580px;

    div {
        position: relative;
        margin-bottom: 5px;
    }
}

// 좌측날개 광고배너
.layer_ad1 {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 13px;
    height: 7px;
    overflow: hidden;
    z-index: 99999;
}

// 로그인 광고배너
.layer_ad2 {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 15px;
    height: 9px;
    overflow: hidden;
    z-index: 99999;
}

// 나의11번가 LNB 광고배너
.layer_ad3 {
    position: absolute;
    top: 5px;
    left: 7px;
    width: 15px;
    height: 9px;
    overflow: hidden;
    z-index: 99999;
}

.layer_ad4 {
    position: absolute;
    top: 9px;
    left: 9px;
}

// [CATEGORY SEARCH]
// Sponsor Link
div.sponsor_link {
    position: relative;
    zoom: 1;
    margin-top: 22px;
    padding: 25px 0 0;
    border-top: 1px solid #e0e0e0;

    h3 {
        width: 59px;
        height: 13px;
        background: url("/img/browsing/search_img.gif") no-repeat -49px -187px;
        font-size: 0;
        text-indent: -9999em;

        &.tit {
            width: 73px;
            height: 14px;
            background: url("/img/browsing/search_img.gif") no-repeat left -563px;
            font-size: 0;
            text-indent: -9999em;
        }
    }

    ul {
        margin-top: 10px;
        padding: 16px 0 25px 26px;
        border-top: 2px solid #666;

        li {
            margin-top: 8px;
            *margin-top: 10px;
            padding-left: 20px;
            background: url("/img/default/icon/sponsor_num_red.gif") no-repeat 0 1px;

            &.num2 {
                background-position: 0 -57px;
            }

            &.num3 {
                background-position: 0 -106px;
            }

            &.num4 {
                background-position: 0 -155px;
            }

            &.num5 {
                background-position: 0 -205px;
            }

            &.num6 {
                background-position: 0 -253px;
            }

            h4 {
                float: left;
                height: 16px;
                font-weight: normal;

                a {
                    color: #00c;
                    font: 14px $font-family-asis;
                    text-decoration: underline;
                }
            }

            em {
                font-weight: bold;
                font-style: normal;
            }

            p {
                float: left;
                height: 16px;
                margin: 1px 0 0 5px;
                padding-left: 9px;
                background: url("/img/common/icon/bul_dotGray.gif") no-repeat 0 6px;

                a {
                    color: #333;
                }
            }

            span {
                display: block;
                clear: both;
                margin-top: -2px;

                a {
                    color: #7c5d3e;
                    font: 11px $font-family-number;
                }
            }
        }
    }

    .sponsorlink_guide {
        position: absolute;
        right: 7px;
        top: 25px;
        padding-right: 5px;
        background: url("/img/common/icon/bul_arrow_gray_3c.gif") no-repeat 100% 3px;
        color: #666;
        font: 11px $font-family-asis;
        text-decoration: underline;
    }

    .defbtn_apply {
        top: 27px;
    }
}

// floating banner
.float_bnr_wrap {
    clear: left;
    position: absolute;
    z-index: 9999;

    .flot_ctschup {
        position: absolute;
        z-index: 3;
        overflow: hidden;
        width: 180px;
        height: 180px;

        .ctsch_cnt {
            display: block;
            width: 182px;
            height: 130px;
            text-indent: -9999px;
        }

        .ctsch_close {
            float: right;
            width: 40px;
            height: 40px;
            text-indent: -9999px;
        }
    }

    .flot_ctsch {
        position: absolute;
        z-index: 2;
        overflow: hidden;
        width: 182px;
        height: 182px;
    }
}

// mobile direct go
.go_mobileW {
    display: block;
    width: 978px;
    height: 152px;
    margin: 30px auto 0 auto;
    background: #5f636c;
    border: 1px solid #454952;
    cursor: pointer;

    span {
        display: block;
        width: 548px;
        height: 55px;
        margin: 50px auto 0 auto;
        background: url("/img/gnb/go_mobile.gif") no-repeat left top;
        overflow: hidden;
        text-indent: -20000px;
    }
}

// [AS-IS CSS]
// paging View
.paging_v4 {
    clear: both;
    text-align: center;
    padding: 10px 0 9px 0;
    font-size: 0;
    line-height: 0;

    * {
        vertical-align: top;
    }

    a {
        display: inline-block;
        text-indent: 0;
        background: url("/img/common/list/bg_list_icon3.gif") no-repeat 0 0;
        text-align: center;
        overflow: hidden;
        cursor: pointer;

        &.first {
            margin: 0 0 0 3px;
            width: 21px;
            height: 19px;
            background: url("/img/common/list/bg_list_icon3.gif") no-repeat 0 -191px;
            overflow: hidden;
        }

        &.pre {
            margin: 0 0 0 3px;
            width: 16px;
            height: 19px;
            background: url("/img/common/list/bg_list_icon3.gif") no-repeat -22px -191px;
            overflow: hidden;
        }

        &.next {
            margin: 0 0 0 3px;
            width: 16px;
            height: 19px;
            background: url("/img/common/list/bg_list_icon3.gif") no-repeat -39px -191px;
            overflow: hidden;
        }

        &.end {
            margin: 0 0 0 3px;
            width: 21px;
            height: 19px;
            background: url("/img/common/list/bg_list_icon3.gif") no-repeat -56px -191px;
            overflow: hidden;
        }
    }

    .pagingList {
        display: inline-block;
        overflow: hidden;

        ul {
            float: left;
            margin: 4px 0 0 -1px;
            _display: inline;
            overflow: hidden;
            zoom: 1;
        }

        li {
            float: left;
            height: 11px;
            margin: 0;
            overflow: hidden;
            zoom: 1;

            a {
                display: inline-block;
                height: 11px;
                font-size: 12px;
                text-indent: 0;
                padding: 0 10px;
                border-left: 1px solid #e4e4e4;
                color: #585860;
                font-weight: bold;
                text-decoration: none;
                line-height: 13px;
                background: none;

                &:visited {
                    color: #585860;
                }

                &:hover,
                &.on {
                    color: #f60;
                }
            }
        }
    }
}

.pagingV2_wrap {
    clear: both;
    position: relative;
    zoom: 1;

    .InLineWrap {
        display: inline-block;
    }

    .info_v2 {
        position: absolute;
        top: 11px;
        right: 1px;

        .btn_viewList_1 {
            float: left;
            display: block;
            width: 103px;
            height: 14px;
            background: url("/img/common/list/bg_list_icon3.gif") no-repeat 0 -261px;
            overflow: hidden;
            zoom: 1;
            text-indent: -20000px;
        }

        .btn_viewList_2 {
            float: left;
            display: block;
            width: 83px;
            height: 14px;
            background: url("/img/common/list/bg_list_icon3.gif") no-repeat 0 -276px;
            overflow: hidden;
            zoom: 1;
            text-indent: -20000px;
        }

        .btn_viewList_3 {
            float: left;
            display: block;
            width: 112px;
            height: 14px;
            margin-left: 5px;
            background: url("/img/common/list/bg_list_icon3.gif") no-repeat 0 -212px;
            overflow: hidden;
            zoom: 1;
            text-indent: -20000px;
        }
    }

    .page_GotoWrap {
        clear: both;
        margin: 0 auto;
        zoom: 1;
        text-align: center;

        * {
            vertical-align: top;
        }

        .page input {
            width: 38px;
            height: 14px;
            padding: 2px 0 0px 0;
            border: 1px solid #cccccc;
            margin: 0;
            text-align: center;
            font-family: Verdana;
            color: #ff6600;
            font-weight: bold;
            font-size: 11px;
        }

        .total {
            float: left;
            display: block;
            margin: 4px 7px 0 1px;
            height: 14px;
            font-family: 굴림, 굴림체, Gulim;
            font-size: 12px;
            line-height: 16px;
            color: #686868;
            overflow: hidden;
            zoom: 1;
        }

        a.btn_pagego {
            float: left;
            display: block;
            margin: 1px 0 0 0;
            width: 35px;
            height: 17px;
            background: url("/img/common/list/bg_list_icon3.gif") no-repeat 0 -226px;
            overflow: hidden;
            zoom: 1;
            text-indent: -20000px;
        }
    }
}

// prd array
.sort_wrap {
    zoom: 1;

    &:after {
        display: block;
        clear: both;
        content: "";
    }

    .sort_way a {
        float: left;
        padding: 0 6px;
        border-left: 1px solid #ddd;

        &:first-child {
            border: none;
        }
    }

    .sort_Qv3 {
        float: right;
    }
}

// As-is order, mytmall icon
.icon_flagN1 {
    // 공동구매
    display: block;
    width: 14px;
    height: 42px;
    background: url("/img/common/icon/iconSet_v6.gif") no-repeat 0 0;
    overflow: hidden;
    text-indent: -10000000px;
}

.icon_flagN2 {
    // 해외쇼핑
    display: block;
    width: 14px;
    height: 42px;
    background: url("/img/common/icon/iconSet_v6.gif") no-repeat -14px 0;
    overflow: hidden;
    text-indent: -10000000px;
}

.icon_flagN10 {
    // 착불
    display: block;
    width: 24px;
    height: 14px;
    background: url("/img/common/icon/iconSet_v6.gif") no-repeat 0 -126px;
    overflow: hidden;
    text-indent: -10000000px;
    margin: 2px auto 0 auto;
}

.icon_flagN11 {
    // 착불&선결제
    display: block;
    width: 49px;
    height: 14px;
    background: url("/img/common/icon/iconSet_v6.gif") no-repeat 0 -140px;
    overflow: hidden;
    text-indent: -10000000px;
    margin: 2px auto 0 auto;
}

.icon_flagN12 {
    // 조건부무료
    display: block;
    width: 49px;
    height: 14px;
    background: url("/img/common/icon/iconSet_v6.gif") no-repeat 0 -154px;
    overflow: hidden;
    text-indent: -10000000px;
    margin: 2px auto 0 auto;
}

.icon_flagN13 {
    // OK셀러
    display: block;
    width: 68px;
    height: 14px;
    background: url("/img/common/icon/iconSet_v6.gif") no-repeat 0 -168px;
    overflow: hidden;
    text-indent: -10000000px;
    margin: 2px auto 0 auto;
}

.icon_flagN14 {
    // BIG셀러
    display: block;
    width: 68px;
    height: 14px;
    background: url("/img/common/icon/iconSet_v6.gif") no-repeat 0 -182px;
    overflow: hidden;
    text-indent: -10000000px;
    margin: 2px auto 0 auto;
}

.icon_flagN15 {
    // QUICK셀러
    display: block;
    width: 68px;
    height: 14px;
    background: url("/img/common/icon/iconSet_v6.gif") no-repeat 0 -196px;
    overflow: hidden;
    text-indent: -10000000px;
    margin: 2px auto 0 auto;
}

.icon_flagN16 {
    // 수량별차등
    display: block;
    width: 49px;
    height: 14px;
    background: url("/img/common/icon/iconSet_v6.gif") no-repeat 0 -210px;
    overflow: hidden;
    text-indent: -10000000px;
    margin: 2px auto 0 auto;
}

.icon_flagN17 {
    // 소호상품
    display: block;
    width: 14px;
    height: 42px;
    background: url("/img/common/icon/iconSet_v6.gif") no-repeat -28px 0;
    overflow: hidden;
    text-indent: -10000000px;
}

.icon_flagN18 {
    // 프리미엄
    float: left;
    display: block;
    width: 39px;
    height: 14px;
    margin-right: 2px;
    background: url("/img/common/icon/iconSet_v6.gif") no-repeat 0 -238px;
    overflow: hidden;
    text-indent: -10000000px;
}

.icon_flagN19 {
    // 프리미엄플러스
    float: left;
    display: block;
    width: 59px;
    height: 14px;
    margin-right: 2px;
    background: url("/img/common/icon/iconSet_v6.gif") no-repeat 0 -224px;
    overflow: hidden;
    text-indent: -10000000px;
}

.icon_flagN20 {
    // OK캐쉬백Street
    float: left;
    display: block;
    width: 74px;
    height: 14px;
    margin-right: 2px;
    background: url("/img/common/icon/iconSet_v6.gif") no-repeat 0 -252px;
    overflow: hidden;
    text-indent: -10000000px;
}

.icon_flagN21 {
    // 급상승
    float: left;
    display: block;
    width: 28px;
    height: 14px;
    margin-right: 2px;
    background: url("/img/common/icon/iconSet_v6.gif") no-repeat 0 -266px;
    overflow: hidden;
    text-indent: -10000000px;
}

.icon_flagN23 {
    // 옵션가
    display: block;
    width: 33px;
    height: 14px;
    background: url("/img/common/icon/iconSet_v6.gif") no-repeat -28px -266px;
    overflow: hidden;
    text-indent: -10000000px;
}

.icon_flagN24 {
    // 디지털컨텐츠
    display: block;
    width: 56px;
    height: 14px;
    background: url("/img/common/icon/iconSet_v6.gif") no-repeat 0 -280px;
    overflow: hidden;
    text-indent: -10000000px;
    margin: 2px auto 0 auto;
}

.icon_flagN25 {
    // 프리미엄
    display: block;
    width: 14px;
    height: 42px;
    margin-bottom: 1px;
    background: url("/img/common/icon/iconSet_v6.gif") no-repeat -42px 0;
    overflow: hidden;
    text-indent: -10000000px;
}

.icon_flagN26 {
    // 프리미엄
    display: block;
    width: 31px;
    height: 14px;
    margin-bottom: 1px;
    background: url("/img/common/icon/iconSet_v6.gif") no-repeat 0px -324px;
    overflow: hidden;
    text-indent: -10000000px;
}

.icon_flagN27 {
    // sKT고객할인
    float: left;
    display: block;
    width: 54px;
    height: 14px;
    margin-right: 2px;
    background: url("/img/common/icon/iconSet_v6.gif") no-repeat 0px -338px;
    overflow: hidden;
    text-indent: -2000px;
}

.icon_flagN28 {
    // 해외직배송
    display: block;
    width: 49px;
    height: 14px;
    margin: 2px auto 0 auto;
    background: url("/img/common/icon/iconSet_v6.gif") no-repeat 0px -352px;
    overflow: hidden;
    text-indent: -2000px;
}

.icon_flagN29 {
    // 방문수령
    display: block;
    width: 37px;
    height: 14px;
    background: url("/img/common/icon/iconSet_v6.gif") no-repeat 0 -366px;
    overflow: hidden;
    text-indent: -10000000px;
    margin: 2px auto 0 auto;
}

.icon_flagN30 {
    // 플러스
    display: block;
    width: 14px;
    height: 33px;
    margin-bottom: 1px;
    background: url("/img/common/icon/iconSet_v6.gif") no-repeat -56px 0;
    overflow: hidden;
    text-indent: -10000000px;
}

.icon_flagN31 {
    // 플러스UP
    float: left;
    display: block;
    width: 46px;
    height: 14px;
    margin-right: 2px;
    background: url("/img/common/icon/iconSet_v6.gif") no-repeat 0 -238px;
    overflow: hidden;
    text-indent: -10000000px;
}

.icon_flagN32 {
    // 마일리지
    display: block;
    float: left;
    width: 31px;
    height: 14px;
    background: url("/img/common/icon/iconSet_v6.gif") no-repeat -31px -70px;
    overflow: hidden;
    text-indent: -10000000px;
}

.icon_flagN33 {
    // 사은품
    display: block;
    float: left;
    width: 31px;
    height: 14px;
    background: url("/img/common/icon/iconSet_v6.gif") no-repeat -31px -84px;
    overflow: hidden;
    text-indent: -10000000px;
}

.icon_flagN34 {
    // 해외통합배송
    display: block;
    width: 57px;
    height: 14px;
    background: url("/img/common/icon/iconSet_v6.gif") no-repeat 0 -467px;
    overflow: hidden;
    text-indent: -10000000px;
    margin: 2px auto 0 auto;
}

.icon_flagN34_txt {
    // 해외통합배송 텍스트내용
    color: #887bcb;
    padding-top: 3px;
    font-size: 11px;
    letter-spacing: -1px;

    a {
        color: #887bcb;
        padding-top: 3px;
        font-size: 11px;
        letter-spacing: -1px;

        &:hover {
            color: #887bcb;
            padding-top: 3px;
            font-size: 11px;
            letter-spacing: -1px;
            text-decoration: underline;
        }
    }
}

.icon_flagN35 {
    // new해외직배송
    display: block;
    width: 49px;
    height: 14px;
    background: url("/img/common/icon/iconSet_v6.gif") no-repeat 0 -481px;
    overflow: hidden;
    text-indent: -10000000px;
    margin: 2px auto 0 auto;
}

.icon_flagN36 {
    // 안심보장
    display: block;
    width: 14px;
    height: 42px;
    margin-bottom: 1px;
    background: url("/img/common/icon/iconSet_v6.gif") no-repeat -70px 0;
    overflow: hidden;
    text-indent: -10000000px;
}

.icon_flagN37 {
    display: block;
    width: 55px;
    height: 14px;
    background: url("/img/common/icon/iconSet_v6.gif") no-repeat -38px -366px;
    overflow: hidden;
    text-indent: -10000000px;
    margin: 2px auto 0 auto;
}

.icon_flagN40 {
    display: inline-block;
    width: 31px;
    height: 14px;
    background: url("/img/common/icon/iconSet_v6.gif") no-repeat 0 -453px;
    font-size: 0;
    line-height: 0;
    overflow: hidden;
    zoom: 1;
}

.list_line .dp_sp .icon_flagN40 {
    // 복수구매할인 리스트형보기 재지정
    float: left;
    display: block;
    text-indent: -10000px;
    margin: 2px auto 0 auto;
}

.icon_imgListW .icon_save_bonus .icon_flagN40 {
    // 복수구매할인 이미지형 재지정
    float: left;
    margin: 0px auto 0 auto;
}

.icon_flagN41 {
    // 공용
    display: inline-block;
    width: 54px;
    height: 14px;
    background: url("/img/common/icon/iconSet_v6.gif") no-repeat 0 -495px;
    font-size: 0;
    line-height: 0;
    overflow: hidden;
    zoom: 1;
}

// 바로가기할인 리스트형보기 상품명 앞에 들어갈때 지정
// 바로가기할인 이미지형 재지정
.list_line .ico_titwrap .icon_flagN41,
.list_img .icon_flagN41,
.list_line .ico_titwrap .icon_flagN42,
.list_img .icon_flagN42 {
    float: left;
    display: block;
    text-indent: -10000px;
    margin-right: 2px;
}

// .icon_flagN42{display:inline-block;width:54px;height:14px;background:url('/img/common/icon/iconSet_v6.gif') no-repeat 0 -509px;font-size:0;line-height:0;overflow:hidden;zoom:1;} 전고객할인
// 전고객할인 리스트형보기 상품명 앞에 들어갈때 지정
// 전고객할인 이미지형 재지정

.icon_flagN43 {
    display: block;
    width: 39px;
    height: 14px;
    background: url("/img/common/icon/iconSet_v6.gif") no-repeat -39px -112px;
    overflow: hidden;
    text-indent: -10000000px;
    margin: 2px auto 0 auto;
}

// 옵션가큰거
// .icon_flagN44 {display:inline-block;margin-right:2px;width:54px;height:14px;background:url('/img/common/icon/iconSet_v6.gif') no-repeat 0 -523px;font-size:0;line-height:0;overflow:hidden;zoom:1;} 배송지연보상

.list_line .icon_flagN44,
.list_img .icon_flagN44 {
    display: block;
    float: left;
}

.list_line .icon_flagN56,
.list_img .icon_flagN56 {
    display: block;
    float: left;
}

.icon_flagN56 {
    // 오늘배송
    display: inline-block;
    width: 70px;
    height: 17px;
    background: url("/img/listing/listing_total2.gif") no-repeat 0 -678px;
    font-size: 0;
    line-height: 0;
    text-indent: -9999px;
    *text-indent: 0;
    vertical-align: middle;
}

.icon_flagN45 {
    // 중고품
    display: inline-block;
    margin-right: 2px;
    width: 39px;
    height: 14px;
    background: url("/img/common/icon/iconSet_v6.gif") no-repeat 0 -570px;
    font-size: 0;
    line-height: 0;
    overflow: hidden;
    zoom: 1;
}

.icon_flagN46 {
    // ok캐쉬백
    float: left;
    display: block;
    width: 46px;
    height: 14px;
    margin-right: 2px;
    background: url("/img/common/icon/iconSet_v6.gif") no-repeat 0px -585px;
    overflow: hidden;
    text-indent: -2000px;
}

.icon_flagN47 {
    // 마일리지
    float: left;
    display: block;
    width: 46px;
    height: 14px;
    margin-right: 2px;
    background: url("/img/common/icon/iconSet_v6.gif") no-repeat 0px -600px;
    overflow: hidden;
    text-indent: -2000px;
}

.icon_flagN48 {
    // T멤버십할인
    float: left;
    display: block;
    width: 55px;
    height: 14px;
    margin-right: 2px;
    background: url("/img/common/icon/iconSet_v6.gif") no-repeat 0px -615px;
    overflow: hidden;
    text-indent: -2000px;
}

.icon_flagN35 {
    // new해외직배송
    display: block;
    width: 49px;
    height: 14px;
    background: url("/img/common/icon/iconSet_v6.gif") no-repeat 0 -481px;
    overflow: hidden;
    text-indent: -10000000px;
    margin: 2px auto 0 auto;
}

.icon_tmembership {
    display: block;
    position: relative;
    float: left;
    width: 59px;
    height: 14px;
    overflow: hidden;
    margin-right: 2px;
    background: url("/img/listing/icon_sp.gif") no-repeat -198px -128px;
    font-size: 0;
    line-height: 30px;
    text-indent: -9999em;

    em {
        display: block;
        position: absolute;
        left: 34px;
        top: 1px;
        width: 24px;
        color: #fff;
        font: 10px $font-family-number;
        text-indent: 0;
        text-align: center;
    }
}

span.prdico.s_point {
    float: left;
    width: 54px;
    height: 14px;
    margin: 0 0 4px 2px;
    background: url("/img/listing/icon_sp.gif") no-repeat -198px -294px;
    font-size: 0;
    line-height: 0;
    text-indent: -9999em;
}

ul.list_line span.prdico.s_point {
    margin: 0 2px 4px 0;
}

.sh_plusup {
    display: block;
    width: 14px;
    height: 40px;
    overflow: hidden;
    margin-bottom: 2px;
    background: url("/img/listing/icon_sp.gif") no-repeat -254px 0;
    font-size: 0;
    text-indent: -9999em;
}

.icon_boomup {
    // 해외쇼핑 상품리스팅용 붐업 아이콘 추가
    float: left;
    display: block;
    width: 54px;
    height: 14px;
    margin-right: 2px;
    background: url("/img/listing/icon_sp.gif") no-repeat -198px -158px;
    overflow: hidden;
    text-indent: -10000px;
}

.icon_flagN2 {
    background: url("/img/listing/icon_sp.gif") no-repeat -270px -42px;
}

.icon_flagN10 {
    width: 52px;
    background: url("/img/listing/icon_sp.gif") no-repeat -32px -48px;
}

.icon_flagN11 {
    width: 52px;
    background: url("/img/listing/icon_sp.gif") no-repeat -32px -32px;
}

.icon_flagN12 {
    width: 52px;
    background: url("/img/listing/icon_sp.gif") no-repeat -32px -16px;
}

.icon_flagN13 {
    width: 58px;
    background: url("/img/listing/icon_sp.gif") no-repeat -139px -32px;
}

.icon_flagN14 {
    width: 58px;
    background: url("/img/listing/icon_sp.gif") no-repeat -139px -48px;
}

.icon_flagN15 {
    width: 58px;
    background: url("/img/listing/icon_sp.gif") no-repeat -139px -64px;
}

.icon_flagN16 {
    width: 52px;
    background: url("/img/listing/icon_sp.gif") no-repeat -32px -64px;
}

.icon_flagN24 {
    width: 52px;
    background: url("/img/listing/icon_sp.gif") no-repeat -32px -96px;
}

.icon_flagN26 {
    background: url("/img/listing/icon_sp.gif") no-repeat 0 -96px;
}

.icon_flagN27 {
    background: url("/img/listing/icon_sp.gif") no-repeat -198px -80px;
}

.icon_flagN28 {
    width: 52px;
    background: url("/img/listing/icon_sp.gif") no-repeat -86px -32px;
}

.icon_flagN29 {
    width: 52px;
    background: url("/img/listing/icon_sp.gif") no-repeat -86px 0;
}

.icon_flagN30 {
    height: 33px;
    background: url("/img/listing/icon_sp.gif") no-repeat -254px -42px;
}

.icon_flagN32 {
    background: url("/img/listing/icon_sp.gif") no-repeat 0 -48px;
}

.icon_flagN33 {
    background: url("/img/listing/icon_sp.gif") no-repeat 0 -112px;
}

.icon_flagN35 {
    width: 52px;
    background: url("/img/listing/icon_sp.gif") no-repeat -86px -32px;
}

.icon_flagN36 {
    height: 40px;
    background: url("/img/listing/icon_sp.gif") no-repeat -270px 0;
}

.icon_flagN37 {
    width: 52px;
    background: url("/img/listing/icon_sp.gif") no-repeat -86px -16px;
}

.icon_flagN40 {
    background: url("/img/listing/icon_sp.gif") no-repeat 0 -80px;
}

.icon_flagN41 {
    background: url("/img/listing/icon_sp.gif") no-repeat -198px -64px;
}

.icon_flagN43 {
    width: 33px;
    background: url("/img/listing/icon_sp.gif") no-repeat -289px 0;
}

.icon_flagN45 {
    width: 54px;
    background: url("/img/listing/icon_sp.gif") no-repeat -198px -96px;
}

.icon_flagN46 {
    width: 54px;
    background: url("/img/listing/icon_sp.gif") no-repeat -198px 0;
}

.icon_flagN47 {
    width: 54px;
    background: url("/img/listing/icon_sp.gif") no-repeat -198px -16px;
}

.icon_flagN48 {
    width: 54px;
    background: url("/img/listing/icon_sp.gif") no-repeat -198px -112px;
}

.icon_flagN49 {
    float: left;
    display: block;
    width: 55px;
    height: 14px;
    margin-right: 2px;
    background: url("/img/listing/icon_sp.gif") no-repeat -198px -143px;
    overflow: hidden;
    text-indent: -2000px;
}

.icon_flagN51 {
    // 오포인트
    display: inline-block;
    overflow: hidden;
    width: 54px;
    height: 14px;
    margin-right: 2px;
    background: url("/img/listing/icon_sp_v2.gif") no-repeat -88px -255px;
    font-size: 0;
    line-height: 0;
    zoom: 1;
}

.icon_flagN52 {
    // 오포인트
    display: block;
    width: 46px;
    height: 15px;
    background: url("/img/listing/icon_sp_v2.gif") no-repeat -152px -255px;
    overflow: hidden;
    text-indent: -10000000px;
}

.list_line .icon_flagN49,
.list_img .icon_flagN49 {
    display: block;
    float: left;
}

.icon_flagN50 {
    // 공용
    display: inline-block;
    width: 54px;
    height: 14px;
    background: url("/img/listing/icon_sp.gif") no-repeat -198px -294px;
    font-size: 0;
    line-height: 0;
    overflow: hidden;
    zoom: 1;
}

.list_line .icon_flagN50,
.list_img .icon_flagN50 {
    // 구리스팅용
    display: block;
    float: left;
}

.icon_flagN53 {
    background-position: -115px -460px;
}

.icon_flagN54 {
    background-position: -115px -480px;
}

.icon_flagN55 {
    background-position: -115px -500px;
}

// As-is order, mytmall icon
#wingBnr .defbtn_med {
    span,
    em {
        _width: 53px;
        _padding: 0;
        _text-align: center;
    }
}

// ie6 err page
.ie6_err_wrap {
    position: relative;
    z-index: 100005;
    width: 475px;
    margin: 0 auto;
}

.ie6_err {
    position: absolute;
    top: 210px;
    width: 475px;
    border: 1px solid #333;
    background: #fff;
    line-height: 150%;

    h2 {
        height: 29px;
        padding-left: 14px;
        color: #fff;
        background: #4d4d4d;
        line-height: 240%;
    }

    .inner_box {
        padding: 40px 0 29px;
        border: 1px solid #333;

        em {
            padding-left: 55px;
        }

        p {
            padding: 25px 0 0 35px;
            color: #666;

            em {
                display: block;
                padding: 0;
                font-weight: bold;
                letter-spacing: -1px;
            }
        }

        div {
            &.btn_wrap {
                padding-top: 30px;
                text-align: center;
            }

            &.close_wrap {
                height: 28px;
                margin-top: 17px;
                border-top: 1px solid #dedede;
                text-align: right;

                button {
                    margin: 7px 10px 0 0;
                    padding-right: 8px;
                    background: url("/img/default/btn/delete05.gif") no-repeat 100% 2px;
                    color: #757575;
                    font-size: 11px;
                    letter-spacing: -1px;
                    cursor: pointer;
                }
            }
        }
    }
}

// base left wing
.leftban_sub_scr {
    position: absolute;
    top: 210px;
    left: 50%;
    margin-left: -580px;
    width: 80px;

    a {
        display: block;
        margin-bottom: 5px;
    }
}

// base add
.LocationBar {
    margin: 0 !important;
    padding: 10px 0 3px 7px !important;
}

.go11st_wrap .btn2 {
    display: block;
    margin: 0 auto;
    font-size: 0;
    text-indent: -9999px;
}

// [gnb]
// direct off, on

.direct_11st2 {
    float: left;
    position: relative;
    padding: 6px 0;
    width: 75px;
    height: 17px;
    background: none;

    &.selected .inner_conts {
        visibility: visible;
    }

    button,
    a.defbtn_direct {
        display: block;
        width: 75px;
        height: 17px;
        background: url("/img/default/icon/ico_direct_11st05.gif") no-repeat;
        cursor: pointer;
    }

    &.direct_11st_on {
        button,
        a.defbtn_direct {
            background-position: 0 -17px !important;
        }

        .inner_conts .dir_method {
            background: #fff;

            li {
                border-color: #c4c4c4;
                border-bottom: 1px solid #909090;
                color: #757575;

                &:first-child {
                    border-color: #c4c4c4;
                }

                a {
                    color: #757575;
                }
            }
        }
    }

    button span,
    a.defbtn_direct span {
        position: relative;
        z-index: -1;
        cursor: pointer;
    }

    .inner_conts {
        visibility: hidden;
        position: absolute;
        top: 31px;
        left: 0;
        width: 280px;
        border: 1px solid #8c8c8c;
        background: #fff;
        text-align: center;

        .tit {
            display: block;
            padding: 13px 0 0;
            color: #666;
            font-weight: bold;
            text-align: left;
            margin: 0 14px;
        }

        .dir_top {
            overflow: hidden;
            height: 40px;
            margin: 0;
            padding: 0;
            color: #666;
            font-weight: bold;
            background: #f2f2f2;
            border-bottom: 1px solid #e8e8e8;
            text-align: center;
            line-height: 41px;

            em {
                color: #e81816;
            }
        }

        ul {
            margin: 5px 14px 0;
            padding: 0;
            text-align: left;
            font-size: 11px;

            li {
                float: none;
                background: none;
                color: #666;
                padding: 0;
                list-style: decimal inside;
                letter-spacing: 0;
                line-height: 150%;

                em {
                    color: #3f79cb;
                }
            }
        }

        .btn_wrap {
            margin: 5px 7px 0;
            padding: 5px 0 6px 0;
        }

        .btn_close {
            position: absolute;
            top: 9px;
            right: 6px;
            width: 13px;
            height: 13px;
            background: url("/img/default/btn/delete03.gif") no-repeat 0 0;
            overflow: hidden;
            text-indent: -2000px;
        }

        .dir_method {
            zoom: 1;
            margin-left: 16px;
            background: #f2f5fc;

            &:after {
                display: block;
                clear: both;
                content: "";
            }

            li {
                float: left;
                height: 24px;
                padding: 9px 9px 5px 9px;
                border: 1px solid #bdc8ce;
                border-left: none;
                border-bottom: 1px solid #8f969e;
                color: #57597f;
                font-size: 11px;
                line-height: 110%;
                text-align: center;
                letter-spacing: -1px;
                list-style: none;

                &:first-child {
                    border-left: 1px solid #bdc8ce;
                }

                a {
                    color: #57597f;
                }

                &.meth_url a {
                    text-decoration: underline;
                }
            }
        }
    }

    .inner_tour {
        width: 220px;
        padding: 0 0 5px 0;

        .dir_top {
            height: 30px;
            line-height: 31px;
        }

        p {
            margin: 0 15px 5px;
            padding: 9px 0 6px 0;
            line-height: 120%;
            border-bottom: 1px solid #dedede;
            color: #4d4d4d;
            text-align: left;

            strong {
                color: #4f6ad1;
            }
        }

        .btn_close {
            width: 9px;
            height: 9px;
            background: url("/img/default/btn/delete04.gif") no-repeat 0 0;
            overflow: hidden;
        }
    }
}

// [GOLOBAL]
#headWrap2 {
    position: relative;
    z-index: 20000;
    width: 100%;
    height: 131px;
    border-bottom: 2px solid #363940;
    background: url("/img/default/icon/bul_squ1px_e4e4e4.gif") repeat-x 0 33px;

    &:after {
        display: block;
        clear: both;
        content: "";
    }
}

.util_wrap2 {
    position: relative;
    z-index: 1000;
    width: 1200px;
    margin: 0 auto;
    height: 30px;
    padding: 2px 0;
    font-size: 12px;
    letter-spacing: -1px;
    background: url("/img/default/icon/bul_squ1px_e4e4e4.gif") repeat-x 0 100%;

    &:after {
        display: block;
        clear: both;
        content: "";
    }

    .main_menu {
        float: left;
        overflow: hidden;
        padding: 8px 0 0 8px;
        margin-left: -8px;
        font-family: $font-family-asis;

        a {
            padding: 0 8px;
            color: #333;
            text-decoration: none !important;

            &.navi_11st_ico {
                display: block;
                width: 40px;
                height: 26px;
                padding: 0 6px 0 0;
                margin: -7px 0 0 8px;
                background: url("/img/default/gnb_common_v2.gif") no-repeat 0 0;
                overflow: hidden;
                text-indent: -20000px;
            }

            &.navi_11st {
                strong,
                &:hover {
                    color: #ea0000;
                }
            }

            &.navi_shockdeal {
                strong,
                &:hover {
                    color: #ea0000;
                }
            }

            &.navi_mart {
                strong,
                &:hover {
                    color: #ea0000;
                }
            }

            &.navi_book {
                strong,
                &:hover {
                    color: #dd3409;
                }
            }

            &.navi_tour {
                strong,
                &:hover {
                    color: #5b59f8;
                }
            }

            &.navi_ticket {
                strong,
                &:hover {
                    color: #ea0000;
                }
            }

            &.navi_brand11st {
                strong,
                &:hover {
                    color: #977561;
                }
            }
        }

        li {
            float: left;
            margin-left: -1px;
            background: url("/img/default/icon/bar_11px_c5c5c5.gif") no-repeat 0 1px;

            &:first-child {
                background: none;
            }
        }
    }
}

.app_down {
    float: left;
    display: block;
    width: 73px;
    height: 17px;
    margin: 6px 0 0 7px;
    background: url("/img/default/gnb_common_v3.gif") no-repeat 0 -27px;
    overflow: hidden;
    text-indent: -20000px;
}

.utilmenu2 {
    float: right;
    padding-top: 8px;
    font-family: $font-family-asis;

    li {
        position: relative;
        float: left;
        margin-left: -1px;
        background: url("/img/default/icon/bar_11px_cecece.gif") no-repeat 0 1px;

        &:first-child {
            background: none;
        }

        &.ut_my11st > a {
            margin-right: 5px;
            padding-right: 10px;
            background: url("/img/default/icon/bul_arrdown_4d4d4d_02.gif") no-repeat 99% 4px;
        }

        &.ut_insub {
            > a {
                margin-right: 5px;
                padding-right: 10px;
                background: url("/img/default/icon/bul_arrdown_4d4d4d_02.gif") no-repeat 99% 4px;
            }

            div {
                width: 106px;
            }
        }

        &.selected div {
            visibility: visible;
        }

        a {
            *float: left;
            padding: 0 8px 10px;
            color: #333;
        }

        div {
            visibility: hidden;
            position: absolute;
            left: 0px;
            top: 23px;
            width: 98px;
            padding: 7px 0px 3px;
            border: 1px solid #8b8b8b;
            background: #fff;
        }

        li {
            float: none;
            background: none;
            white-space: nowrap;
            line-height: 170%;

            a {
                margin: 0;
                padding: 0 21px 0 7px !important;
                background: none !important;
                color: #666 !important;
                font-weight: normal !important;

                &:hover {
                    color: #ea0000 !important;
                }
            }
        }
    }

    .btn_close {
        position: absolute;
        top: 5px;
        right: 2px;
        width: 15px;
        height: 9px;
        background: url("/img/default/btn/delete04.gif") no-repeat 50% 0;
        overflow: hidden;
        text-indent: -2000px;
    }

    #mYInFo_11st {
        position: static;
        right: auto;
        width: auto;
        z-index: 0;
    }
}

// base.css 충돌
#defaultHeader2 {
    position: relative;
    zoom: 1;
    z-index: 20;
    height: 97px;
    width: 1200px;
    margin: 0 auto;
    border-bottom: 2px solid #363940;

    &:after {
        display: block;
        clear: both;
        content: "";
    }

    h1 {
        position: absolute;
        top: 14px;
        left: 0;
        background: #fff url("/img/default/logo_v4.png") no-repeat 0 20px;

        a {
            display: block;
            width: 80px;
            height: 70px;
            text-indent: -9999px;
        }

        &.season_type a {
            float: left;
            width: auto;
            background: none;

            img {
                height: 97px;
            }
        }
    }
}

.search_wrap2 {
    padding: 23px 200px 0 80px;

    .best_link {
        float: left;
        width: 245px;
        padding: 17px 0 0 24px;
        white-space: nowrap;

        li {
            display: inline;
            padding: 0 1px 0 6px;
            background: url("/img/default/icon/bar_11px_999.gif") no-repeat 0 2px;

            &:first-child {
                background: none;
            }

            a {
                font-size: 12px;
                line-height: 18px;
                font-weight: bold;
                white-space: nowrap;
                text-decoration: none;
                vertical-align: top;
                color: #666;
            }
        }

        .new {
            display: inline-block;
            position: static;
            width: 11px;
            height: 0px;
            padding-top: 11px;
            margin-left: 3px;
            z-index: 1;
            background: url("/img/default/icon/ico_new02.gif") no-repeat 0 0;
            overflow: hidden;
            vertical-align: top;
        }
    }

    fieldset {
        float: left;
        position: relative;
        width: 449px;
        height: 41px;
        background: url("/img/default/gnb_common_v5.png") no-repeat 0 -160px;

        legend {
            position: absolute;
            top: 0;
            left: 0;
            *display: none;
        }
    }

    div.search_inner {
        float: left;

        div.inner_wrap {
            width: 400px;
            background: #fff;
        }
    }

    .choice_menu {
        float: left;
        padding-top: 2px;
    }

    .choice_tit {
        width: 76px;
        height: 18px;
        margin-top: 7px;
        padding: 4px 22px 0 6px;
        *padding: 3px 22px 0 6px;
        border: 0 none;
        background: url("/img/default/gnb_choice_arrdown_v2.png") 89% 9px no-repeat;
        color: $color-11st-red;
        text-align: left;
        letter-spacing: -1px;
    }

    .choice_menu {
        ul {
            visibility: hidden;
            position: absolute;
            top: 37px;
            left: 0px;
            z-index: -1;
            width: 80px;
            padding: 4px 0px;
            border: 1px solid #8b8b8b;
            border-top: 1px solid #ea0001;
            background: #fff;
            line-height: 130%;
        }

        &.selected ul {
            visibility: visible;
        }

        ul {
            .site_line {
                margin-top: 4px;
                padding-top: 4px;
                border-top: 1px solid #e8e8e8;
            }

            a {
                display: block;
                padding: 3px 2px 0 7px;
                color: #676767;

                em {
                    color: #676767;
                    font-family: $font-family-asis;
                }

                &:hover {
                    text-decoration: underline;
                }
            }

            ul {
                position: static;
                width: auto;
                padding: 1px 0 0 0;
                border: none;
                font-size: 11px;

                li a {
                    padding: 2px 2px 0 13px;
                    background: url("/img/default/icon/bul_dash5px_666.gif") no-repeat 7px 7px;

                    em {
                        font-size: 11px;
                        font-family: $font-family-asis;
                    }
                }
            }
        }

        > ul > li:first-child > a {
            font-weight: bold;
            color: #e90000;

            em {
                color: #e90000;
            }
        }

        .layer_def_a3 {
            width: 188px;
            left: -7px;
            top: 36px;
            *top: 35px;
            background-position: -418px 0;
        }
    }

    fieldset input.text_search {
        width: 310px;
        height: 24px;
        margin: 6px 0 0 5px;
        *margin: 5px 0 0 5px;
        padding-top: 3px;
        border: 0 none;
        font-size: 14px;
        font-weight: bold;
        color: #000;
        line-height: 23px;
        letter-spacing: -1px;
        outline: 0 none;

        &.adver,
        &.placetx {
            color: #666;
            font-size: 12px;
            letter-spacing: 0px;
            *letter-spacing: -1px;
        }
    }

    div.auto_tree {
        button {
            position: absolute;
            top: 10px;
            right: 54px;
            *width: 72px;
        }

        iframe {
            display: none;
            position: absolute;
            z-index: 1;
            width: 361px;
            top: 40px;
            left: 74px;
        }
    }

    .btn_search {
        position: absolute;
        right: 20px;
        top: 11px;
        width: 25px;
        height: 25px;
        background: #fff url("/img/default/gnb_common_v5.png") no-repeat 0 -201px;

        span {
            position: relative;
            z-index: -1;
            text-indent: -9999px;
        }
    }
}

.promotion_wrap2 {
    position: absolute;
    top: 25px;
    right: 7px;

    .btnctr_pn {
        position: absolute;
        top: 65px;
        right: 10px;
        width: 75px;
        padding-right: 0px;
        color: #666;
        font-family: $font-family-asis;
        font-size: 11px;
        letter-spacing: 1px;
        text-align: center;

        em {
            color: #434343;
            font-weight: bold;
        }

        button {
            top: 0px;
            width: 19px;
            height: 17px;
            padding: 0;
            background-image: url("/img/default/btn/prev_next14.gif");
            text-indent: -9999px;

            &.in_prev {
                left: 0px;
            }

            &.in_next {
                right: 0px;
            }
        }
    }

    &.ticket {
        top: 15px;

        .btnctr_pn {
            position: absolute;
            top: 10px;
            right: 0px;
            width: 75px;
            padding-right: 0px;
            color: #666;
            font-family: $font-family-asis;
            font-size: 11px;
            letter-spacing: 1px;
            text-align: center;

            em {
                color: #434343;
                font-weight: bold;
            }

            button {
                top: 0px;
                width: 19px;
                height: 17px;
                padding: 0;
                background-image: url("/img/default/btn/prev_next04.gif");
                text-indent: -9999px;

                &.in_prev {
                    left: 50px;
                }

                &.in_next {
                    right: 0px;
                }
            }
        }
    }
}

// LEFT WING BANNER
.prom_wingbnr2 {
    position: absolute;
    top: 210px;
    left: 50%;
    margin-left: -580px;

    div {
        position: relative;
        margin-bottom: 5px;
    }

    .layer_ad1 {
        position: absolute;
        top: 2px;
        left: 2px;
        width: 13px;
        height: 7px;
        overflow: hidden;
    }
}

// [navigation]
.navigateWrap .navigate {
    width: 1200px;
    margin: 0 auto;
    background: url("/img/default/icon/bul_squ1px_d6d6d6.gif") repeat-x 0 100%;
}

.totalcate_wrap {
    position: relative;
    z-index: 10;
    float: left;
    width: 134px;
    height: 36px;

    .btn_totalcate {
        display: block;
        width: 117px;
        height: 32px;
        margin-left: 10px;
        background: #fff;

        span {
            display: block;
            width: 117px;
            height: 12px;
            margin: 10px 0px 12px 0px;
            background: url("/img/default/gnb_common_v2.gif") no-repeat -100px 0px;
            cursor: pointer;
            overflow: hidden;
            text-indent: -20000px;
        }
    }

    &.selected .inner_totalcate {
        visibility: visible;
        height: 552px;
    }

    .inner_totalcate {
        visibility: hidden;
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 1;
        width: 976px;
        height: 0px;
        border: 2px solid #363940;
        background: #fff;
        overflow: hidden;

        h2 {
            width: 970px;
            height: 34px;
            background: #363940;
            padding: 0px 8px 0px 0px;
        }

        .btn_totalcate {
            display: block;
            width: 117px;
            height: 32px;
            margin-left: 10px;
            background: #363940;
            span {
                background: url("/img/default/gnb_common_v2.gif") no-repeat -218px 0px;
            }
        }
    }

    .btn_close {
        position: absolute;
        top: 7px;
        right: 10px;
        overflow: hidden;
        width: 42px;
        height: 18px;
        background: url("/img/default/btn/close04.gif") no-repeat 0 0;

        span {
            position: relative;
            z-index: -1;
            white-space: nowrap;
            text-align: right;
            font-size: 11px;
            letter-spacing: -1px;
            *font-size: 0;
            *text-indent: -9999px;
        }
    }

    .category2 {
        float: left;
        width: 663px;

        div {
            float: left;
            width: 165px;
            height: 230px;
            border-right: 1px solid #e5e5e5;
            border-bottom: 1px solid #e4e4e4;

            &.viewcate1 {
                width: 164px;
            }

            &.viewcate5 {
                width: 164px;
                border-bottom: none;
                height: 285px;
            }

            &.viewcate6,
            &.viewcate7,
            &.viewcate8 {
                border-bottom: none;
                height: 285px;
            }

            h3 span {
                display: block;
                height: 29px;
                padding-left: 9px;
                background: #f6f7fb;
                border-bottom: 1px solid #ededef;
                color: #111;
                line-height: 34px;
            }
        }

        ul {
            padding: 8px 0 0 10px;
        }

        li {
            overflow: hidden;
            height: 20px;

            a {
                color: #4d4d4d;

                &:hover {
                    color: #e71818;
                }
            }
        }
    }

    .popular_special {
        position: relative;
        float: left;
        width: 156px;
        height: 518px;
        background: #fff;
        border-right: 1px solid #e5e3e4;

        h3 {
            height: 17px;
            margin: 15px 0 0 15px;
            color: #4c6dcc;
        }

        ul {
            overflow: hidden;
            margin: 2px 7px 0 16px;
            padding-bottom: 5px;

            li {
                display: block;
                background: url("/img/default/icon/bul_2px_4e6ccc.gif") no-repeat 0 7px;
                overflow: hidden;
                max-width: 170px;
                height: 18px;
                padding-left: 7px;
                overflow: hidden;

                &:first-child {
                    border-top: none;
                }

                a {
                    color: #4c6dcc;
                    line-height: 18px;
                    cursor: pointer;
                }
            }
        }
    }

    .popular_corner2 {
        position: relative;
        float: left;
        width: 156px;
        height: 518px;
        background: #f6f7fb;

        h3 {
            height: 17px;
            margin: 15px 0 0 15px;
            color: #111;
        }

        ul {
            overflow: hidden;
            margin: 2px 7px 0 16px;
            padding-bottom: 5px;

            li {
                display: block;
                background: url("/img/default/icon/bul_2px_4d4d4d.gif") no-repeat 0 7px;
                overflow: hidden;
                max-width: 170px;
                height: 18px;
                padding-left: 7px;
                overflow: hidden;

                &:first-child {
                    border-top: none;
                }

                a {
                    color: #4d4d4d;
                    line-height: 18px;
                    cursor: pointer;
                }
            }
        }

        .btn_area {
            position: absolute;
            left: 13px;
            bottom: 19px;

            span {
                width: 104px;
                text-align: center;
            }
        }
    }
}

.gnb_menu3 {
    position: relative;
    z-index: 5;
    width: 846px;
    margin-left: 134px;

    ul {
        zoom: 1;
        padding: 0px;
        margin: 0;

        &:after {
            display: block;
            clear: both;
            content: "";
        }
    }

    h2 {
        font-size: 0;
        text-indent: -9999px;
        line-height: 0;
    }

    > ul > li {
        position: relative;
        float: left;
        height: 35px;
        *vertical-align: top;

        > a {
            float: left;
            position: relative;
            zoom: 1;
            z-index: 1;
            height: 35px;
            padding: 0 15px 0 16px;
            margin-left: -1px;
            background: url("/img/default/icon/bar_11px_cbcbcb.gif") no-repeat 0 12px;
        }
    }

    li {
        &:hover,
        &.selected {
            z-index: 2;
        }

        a span {
            display: block;
            *display: inline-block;
            width: 53px;
            height: 13px;
            margin: 11px auto;
            background: url("/img/default/gnb_common_v4.gif") no-repeat -100px -13px;
            text-align: center;
            cursor: pointer;
        }

        &.gnbm2 a span {
            width: 21px;
            background-position: -100px -26px;
        }

        &.gnbm3 a span {
            width: 53px;
            background-position: -100px -39px;
        }

        &.gnbm4 a span {
            width: 64px;
            background-position: -100px -52px;
        }

        &.gnbm5 a span {
            width: 54px;
            background-position: -100px -65px;
        }

        &.gnbm6 a span {
            width: 64px;
            background-position: -100px -78px;
        }

        &.gnbm7 a span {
            width: 63px;
            background-position: -100px -91px;
        }

        &.gnbm8 a span {
            width: 87px;
            background-position: -100px -143px;
        }

        &.gnbm9 a span {
            width: 53px;
            background-position: -100px -117px;
        }

        &.gnbm10 a span {
            width: 31px;
            background-position: -100px -130px;
        }

        &.gnbm1 a:hover span {
            background-position: -218px -13px;
        }
    }

    > ul > li {
        &:hover > a,
        &.selected > a {
            z-index: 2;
            background: #fff;
            padding: 0 14px 1px 15px;
            border: 1px solid #ea0001;
            border-top: none;
            border-bottom: none;
        }
    }
}

.gnb_menu3 li.gnbm1.selected span {
    background-position: -218px -13px;
}

.gnb_menu3 li.gnbm2 a:hover span,
.gnb_menu3 li.gnbm2.selected span {
    background-position: -218px -26px;
}

.gnb_menu3 li.gnbm3 a:hover span,
.gnb_menu3 li.gnbm3.selected span {
    background-position: -218px -39px;
}

.gnb_menu3 li.gnbm4 a:hover span,
.gnb_menu3 li.gnbm4.selected span {
    background-position: -218px -52px;
}

.gnb_menu3 li.gnbm5 a:hover span,
.gnb_menu3 li.gnbm5.selected span {
    background-position: -218px -65px;
}

.gnb_menu3 li.gnbm6 a:hover span,
.gnb_menu3 li.gnbm6.selected span {
    background-position: -218px -78px;
}

.gnb_menu3 li.gnbm7 a:hover span,
.gnb_menu3 li.gnbm7.selected span {
    background-position: -218px -91px;
}

.gnb_menu3 li.gnbm8 a:hover span,
.gnb_menu3 li.gnbm8.selected span {
    background-position: -218px -143px;
}

.gnb_menu3 li.gnbm9 a:hover span,
.gnb_menu3 li.gnbm9.selected span {
    background-position: -218px -117px;
}

.gnb_menu3 li.gnbm10 a:hover span,
.gnb_menu3 li.gnbm10.selected span {
    background-position: -218px -130px;
}

.gnb_menu3 {
    > ul > li.gnbm10 {
        > a {
            padding: 0 17px 0 17px;
        }

        &:hover > a,
        &.selected > a {
            padding: 0 16px 1px 16px;
        }
    }

    li {
        span span {
            overflow: hidden;
            position: relative;
            z-index: -1;
            height: 11px;
            font-size: 11px;
            letter-spacing: -2px;
            *font-size: 0;
            *text-indent: -9999px;
        }

        &.selected div {
            &.gnb_inner {
                visibility: visible !important;
            }

            &.gnb_inner_wrap2 {
                height: 398px !important;
            }
        }

        div.gnb_inner_wrap2 {
            visibility: visible;
            position: absolute;
            top: 35px;
            left: -1px;
            width: 501px;
            height: 0px;
            padding: 0px;
            transition: height 0.4s ease 0s;
            overflow: hidden;

            .gnb_inner {
                visibility: hidden;
                position: absolute;
                top: 0px;
                border: 1px solid #ea0001;
                background: #fff;
                width: 197px;
                min-height: 396px;
                max-height: 396px;

                .grouping {
                    float: left;
                    width: 178px;
                    padding: 10px 19px 10px 8px;

                    h3 {
                        height: 20px;
                        padding: 7px 0 0 11px;
                        background: #eff0f5;
                        font-weight: bold;
                    }

                    ul {
                        padding: 2px 0 26px 0;
                        zoom: 1;
                    }

                    li {
                        padding-left: 11px;
                        margin-top: 4px;
                    }

                    a {
                        color: #4d4d4d;

                        span {
                            display: inline-block;
                            width: auto;
                            height: auto;
                            margin: 0;
                            background: none;
                        }

                        &:hover {
                            color: #4d4d4d;
                        }
                    }

                    .special {
                        h3 {
                            height: 13px;
                            padding-top: 0px;
                            background: none;
                            color: #4e6ccc;
                        }

                        a {
                            color: #4e6ccc;

                            &:hover {
                                color: #4e6ccc;
                            }
                        }

                        ul {
                            padding-bottom: 5px;
                        }
                    }
                }

                .banner {
                    position: absolute;
                    height: 100%;
                    top: -1px;
                    right: -302px;
                    width: 300px;
                    border: 1px solid #ea0001;
                    border-left: 1px solid #e4e4e4;
                    background: #fff;
                    overflow: hidden;

                    .ban02 a {
                        position: absolute;
                        top: 199px;
                        z-index: 1;
                        display: block;
                        height: 198px;
                        border-left: none;

                        img {
                            width: 300px;
                            height: 198px;
                        }

                        &:first-child {
                            top: 0px;
                            z-index: 2;
                            padding-top: 0px;
                            border-bottom: 1px solid #e4e4e4;
                        }
                    }

                    .ban01 a {
                        position: absolute;
                        top: 0px;
                        z-index: 1;
                        display: block;
                        height: 396px;
                        padding-top: 0px;
                        border-left: none;

                        img {
                            width: 300px;
                            height: 396px;
                        }
                    }
                }
            }
        }

        &.gnbm6 div.gnb_inner_wrap2 {
            left: -49px;
        }

        &.gnbm7 div.gnb_inner_wrap2 {
            left: -143px;
        }

        &.gnbm8 div.gnb_inner_wrap2 {
            left: -236px;
        }

        &.gnbm9 div.gnb_inner_wrap2 {
            left: -353px;
        }
        &.gnbm10 div.gnb_inner_wrap2 {
            left: -436px;
        }

        &.gnbm6 div.gnb_inner_wrap2.non_ban,
        &.gnbm7 div.gnb_inner_wrap2.non_ban,
        &.gnbm8 div.gnb_inner_wrap2.non_ban {
            left: -1px;
        }

        &.gnbm9 div.gnb_inner_wrap2.non_ban {
            left: -43px;
        }

        &.gnbm10 div.gnb_inner_wrap2.non_ban {
            left: -129px;
        }

        div.gnb_inner_wrap2 {
            .btn_close {
                position: absolute;
                z-index: 2;
                top: 10px;
                right: -292px;
                width: 16px;
                height: 15px;
                background: url("/img/default/btn/close06.gif") no-repeat 0 0;
                overflow: hidden;
                text-indent: -20000px;
            }

            &.non_ban .btn_close {
                display: none;
            }
        }
    }
}

// [Right wing]
#wingBnr2 {
    position: absolute;
    z-index: 100000;
    top: 10px;
    right: 50%;
    margin-right: -580px;
    width: 80px;

    .defbtn_med {
        display: inline-block;
        height: 28px;
        margin: 15px 10px;
        padding-left: 2px;
        background: url("/img/default/btn/def_medium.png") no-repeat 0 -140px;
        font-family: $font-family-asis;

        span {
            display: inline-block;
            overflow: hidden;
            height: 28px;
            margin-right: -2px;
            padding: 0 12px 0 10px;
            background: url("/img/default/btn/def_medium.png") no-repeat 100% -140px;
            color: #4d4d4d;
            line-height: 265%;
            letter-spacing: -1px;
        }
    }

    span.count {
        position: absolute;
        top: 24px;
        left: 49px;
        display: block;
        height: 13px;
        background: url("/img/default/circle_Rbg.png") no-repeat 100% 0;

        > em {
            display: block;
            height: 13px;
            padding: 0 4px 0 3px;
            *padding: 0 3px 0 3px;
            margin-left: -6px;
            background: url("/img/default/circle_Lbg.png") no-repeat 0 0;
            text-align: center;
            color: #fff;
            font-family: $font-family-number;
            font-size: 10px;
            *font-size: 11px;
            font-weight: bold;
            letter-spacing: -1px;
            line-height: 120%;
        }
    }
}

:root #wingBnr2 span.count > em {
    padding: 1px 4px 0 3px;
}

#wingBnr2 {
    .btnctr_pn {
        position: absolute;
        top: 255px;
        left: 9px;
        width: 60px;
        padding: 0;
        color: #666;
        font-family: $font-family-asis;
        font-size: 11px;
        letter-spacing: 0px;
        text-align: center;

        em {
            color: #434343;
            font-weight: bold;
        }

        span {
            font-weight: bold;
        }

        button {
            display: block;
            position: absolute;
            top: 0px;
            width: 15px;
            height: 15px;
            padding: 0;
            background: url("/img/default/btn/prev_next12.gif") no-repeat 0 0;
            text-indent: -9999px;

            &.in_prev {
                left: 0;
            }

            &.in_next {
                right: 0;
                background-position: 100% 0;
            }
        }
    }

    div.oneid_wrap {
        width: 78px;
        min-height: 35px;
        border: 1px solid #d7d7d7;
        border-bottom: none;
        background: #f2f5fc;

        div.oneid {
            background: url("/img/default/wing_img_v2.gif") no-repeat 0 0;
            font-size: 0;
            text-indent: 0px;
        }
    }
}

div.oneid_wrap div.okcash span {
    background: url("/img/default/wing_img_v2.gif") no-repeat 0 0;
    font-size: 0;
    text-indent: 0px;
}

#wingBnr2 {
    div {
        &.oneid_wrap div {
            &.oneid {
                width: 78px;
                height: 35px;
                background-position: -120px -181px;

                a {
                    display: block;
                    width: 100%;
                    height: 35px;
                }
            }

            &.okcash {
                width: 78px;
                height: 18px;
                padding-top: 7px;
                border-top: 1px solid #d7d7d7;
                text-align: center;

                span {
                    display: inline-block;
                    width: 18px;
                    height: 13px;
                    margin-top: -2px;
                    margin-right: 1px;
                    background-position: -41px 0px;
                    vertical-align: top;
                }

                em {
                    display: inline-block;
                    color: #4e4e4e;
                    font-family: $font-family-number;
                    font-size: 10px;
                    vertical-align: top;
                }

                button {
                    width: 18px;
                    height: 18px;
                    margin: -3px -5px 0 -2px;
                    background: url("/img/default/btn/resch.gif") no-repeat 50% 50%;
                    text-indent: -9999px;
                    vertical-align: top;
                }

                > a {
                    display: block;
                    width: 78px;
                    height: 18px;
                    padding-top: 7px;
                    margin-top: -7px;
                    color: #4e4e4e;
                    font-size: 11px;
                    letter-spacing: -1px;
                    text-decoration: none;
                    cursor: pointer;
                }
            }
        }

        &.wing_hgroup {
            width: 80px;

            h3 {
                position: absolute;
                top: 0;
                left: 0;
                font-size: 0;
            }

            > ul {
                color: #676767;
                letter-spacing: -1px;
                height: 104px;

                > li {
                    position: relative;
                    width: 80px;
                    height: 51px;
                    font-size: 11px;
                    border-top: 1px solid #d7d7d7;
                    display: block;
                    width: 80px;
                    height: 51px;
                    background: url("/img/default/wing_img_v2.gif") no-repeat 0 0;
                    text-align: center;

                    > a {
                        display: block;
                        width: 80px;
                        height: 41px;
                        padding-top: 10px;
                        color: #4d4d4d;

                        &:hover {
                            color: #4d4d4d;
                        }
                    }
                }
            }

            ul li {
                &.hg_cupoint {
                    background-position: 0 -22px;
                }

                &.hg_special {
                    background-position: 0 -178px;
                }

                &.hg_cart {
                    background-position: 0 -74px;
                }

                &.selected a {
                    background: url("/img/default/icon/bul_arr_333_03.gif") no-repeat -1px 22px;
                }
            }
        }
    }

    ul.prd_list {
        width: 60px;
        margin: 0 auto;
        padding: 3px 0 7px;

        li {
            position: relative;
            padding-top: 5px;

            img {
                vertical-align: top;
            }

            a {
                display: block;
                width: 60px;
                height: 60px;
                overflow: hidden;
                text-decoration: none;

                &:hover {
                    display: block;
                    width: 58px;
                    height: 58px;
                    border: 1px solid #2b2e37;

                    img {
                        margin: -1px 0 0 -1px;
                    }
                }
            }
        }

        a {
            .prd_info {
                visibility: hidden;
                opacity: 0;
                -webkit-transition: all 0.3s linear 0s;
                -moz-transition: all 0.3s linear 0s;
                -o-transition: all 0.3s linear 0s;
                -ms-transition: all 0.3s linear 0s;
                transition: all 0.3s linear 0s;
                position: absolute;
                top: 5px;
                right: 59px;
                width: 92px;
                height: 44px;
                padding: 14px 13px 0 13px;
                background: #363940;
                border: 1px solid #2b2e37;
                color: #fff;
                font-size: 11px;
                text-align: left;

                .prd_tit {
                    display: block;
                    width: 99px;
                    height: 14px;
                    margin-bottom: 3px;
                    overflow: hidden;
                    line-height: 140%;
                    letter-spacing: -1px;
                }

                em {
                    color: #fff;
                    font-weight: bold;
                    font-family: $font-family-number;
                }
            }

            &:hover .prd_info,
            &:focus .prd_info {
                visibility: visible;
                opacity: 1;
            }
        }

        .btn_del {
            display: block;
            position: absolute;
            top: 6px;
            right: 1px;
            width: 18px;
            height: 18px;
            background: url("/img/default/btn/delete02.gif") no-repeat 100% 0;
            text-indent: -9999px;
            cursor: pointer;
        }
    }

    div {
        &.wing_viewprd {
            position: relative;
            height: 51px;
            border: 1px solid #c4cdd6;
            background: #fff;

            > h4 {
                position: relative;
                width: 78px;
                height: 51px;
                font-size: 11px;
                border-bottom: 1px solid #d7d7d7;
                letter-spacing: -1px;

                a {
                    display: block;
                    width: 78px;
                    height: 41px;
                    padding-top: 10px;
                    background: url("/img/default/wing_img_v2.gif") no-repeat -1px -126px;
                    text-align: center;
                    font-weight: normal;
                    color: #4d4d4d;

                    &:hover {
                        color: #4d4d4d;
                    }
                }
            }

            ul.prd_list,
            .btnctr_pn {
                display: none;
            }
        }

        &.wing_prdwrap {
            position: relative;
            width: 78px;
            height: 22px;
            border: 1px solid #d7d7d7;
            border-top: none;
            border-bottom: none;
            background: #f0f5fb;
            text-align: center;

            h4 {
                padding: 6px 0 1px;
                background: #e0e6f2;
                border-bottom: 1px solid #d7d7d7;
                font-size: 11px;
                line-height: 130%;
                letter-spacing: -1px;
                color: #515b7e;
                font-weight: normal;
                cursor: pointer;

                a {
                    color: #515b7e;

                    &:hover {
                        color: #515b7e;
                    }
                }
            }

            .btnctr_pn {
                top: 227px;
                letter-spacing: 1px;
            }

            ul.prd_list,
            .btnctr_pn {
                display: none;
            }

            &.selected {
                height: 248px;

                ul.prd_list,
                .btnctr_pn,
                .no_prd {
                    display: block;
                }
            }
        }
    }

    > div.wing_viewprd {
        &.selected {
            height: 277px;

            ul.prd_list,
            .btnctr_pn {
                display: block;
            }
        }

        .no_prd {
            font-size: 11px;
            text-align: center;
            color: #666;
            padding-top: 91px;
            letter-spacing: -1px;
        }
    }

    > div.wing_prdwrap .no_prd {
        display: none;
    }

    > div.wing_prdwrap .no_prd {
        font-size: 11px;
        text-align: center;
        color: #525c7f;
        padding-top: 91px;
        letter-spacing: -1px;
    }

    .winglayer_wrap {
        position: absolute;
        top: 0;
        left: -280px;
        z-index: -1;
        width: 80px;
        height: 0px;
        padding-left: 280px;
        overflow: hidden;
    }

    .layview {
        height: 486px;
    }

    div.winglayer {
        display: block;
        position: absolute;
        top: 0;
        left: 280px;
        width: 278px;
        min-height: 483px;
        padding: 0;
        border: 1px solid #333333;
        background: #fff;
        color: #666;
        line-height: 120%;

        .link_arr_dirtgo {
            position: static;
            display: inline-block;
            padding-right: 6px;
            background: url("/img/default/icon/bul_arr_666.gif") no-repeat 100% 2px;
            color: #636363;
            font-family: $font-family-asis;
            font-size: 11px;
            letter-spacing: -1px;
            line-height: 120%;
            text-decoration: underline;
        }

        .link_arr_4d6ccd {
            position: static;
            display: inline-block;
            padding-right: 6px;
            background: url("/img/default/icon/bul_arr_4d6ccd.gif") no-repeat 100% 2px;
            color: #4d6ccd;
            font-family: $font-family-asis;
            font-size: 11px;
            letter-spacing: -1px;
            line-height: 120%;
            text-decoration: underline;
        }

        button.rwing_btn_layclose {
            position: absolute;
            top: 8px;
            right: 6px;
            width: 13px;
            height: 13px;
            background: url("/img/default/btn/delete08.gif") no-repeat 50% 50%;
            font-size: 0;
            text-indent: -9999px;
        }
    }

    .winglayer_tit {
        padding: 0px 0 4px 15px;
        color: #111;
        line-height: 120%;
        letter-spacing: -1px;

        span {
            font-weight: normal;
            letter-spacing: -1px;
            font-family: $font-family-number;
        }
    }

    .my_info {
        position: relative;
        width: 177px;
        height: 89px;
        padding: 27px 0 0 101px;
        background: #f1f5fc;
        border-bottom: 1px solid #b0b2b4;

        .my_order {
            font-weight: bold;
            color: #333;
            font-size: 12px;
        }

        > ul {
            position: absolute;
            top: 58px;
            left: 102px;

            > li {
                margin-bottom: 6px;
            }
        }
    }

    .my_benefit {
        width: 280px;
        margin-bottom: 16px;
        border-bottom: none;
        zoom: 1;

        &:after {
            display: block;
            clear: both;
            content: "";
        }

        li {
            float: left;
            width: 92px;
            border-left: 1px solid #e6e7e8;

            &:first-child {
                border-left: none;
            }

            span {
                display: block;
                width: 92px;
                height: 19px;
                padding-top: 33px;
                background: url("/img/default/wing_img_v2.gif") no-repeat 0 0;
                font-weight: normal;
                letter-spacing: -1px;
                color: #333;
                text-align: center;
                font-size: 11px;
            }

            a {
                cursor: pointer;
            }

            em {
                display: block;
                width: 92px;
                padding: 7px 0 7px 0;
                border-bottom: 1px solid #d6d8da;
                text-align: center;
                background: url("/img/default/icon/dot_3px_d6d8da.gif") repeat-x 0 0;
                font-weight: bold;
                font-size: 11px;
                color: #333;
                font-family: $font-family-number;
            }

            .th_coupon {
                background-position: -121px -23px;
            }

            .th_mileage {
                background-position: -121px -76px;
            }

            .th_point {
                background-position: -121px -129px;
            }
        }
    }

    .detail_inner {
        position: relative;
        margin: 7px 0 15px 0;
        padding-bottom: 18px;
        border-bottom: 1px solid #d6d8da;

        .defbtn_more {
            top: -28px;
            right: 15px;
            font-size: 11px;
        }
    }

    div.minimall .defbtn_more {
        top: -28px;
        right: 15px;
        font-size: 11px;
    }

    .detail_inner {
        ul {
            zoom: 1;
            margin-left: 6px;

            &:after {
                display: block;
                clear: both;
                content: "";
            }
        }

        li {
            position: relative;
            float: left;
            width: 70px;
            margin-left: 14px;

            em {
                display: block;
                margin-top: 6px;
                height: 14px;
                font-weight: bold;
                font-family: $font-family-number;
                color: #111;
                text-align: center;
            }
        }

        ul .btn_del {
            display: block;
            position: absolute;
            top: 0px;
            right: 1px;
            width: 15px;
            height: 15px;
            background: url("/img/default/btn/delete09.gif") no-repeat 100% 0;
            text-indent: -9999px;
            cursor: pointer;
        }

        .no_list {
            height: 40px;
            padding-top: 30px;
            text-align: center;
            letter-spacing: -1px;
        }
    }

    div.minimall {
        position: relative;
        margin-top: 7px;

        .viewport {
            position: relative;
            overflow: hidden;
            width: 218px;
            height: 70px;
            margin: 0px 0 0 30px;
        }
        ul {
            overflow: hidden;
            width: 100000px;

            li {
                position: relative;
                float: left;
                width: 218px;

                > strong {
                    display: inline-block;
                    padding-left: 15px;
                    background: url("/img/default/icon/ico_minihome03.gif") no-repeat 0 2px;
                    font-size: 12px;
                    position: absolute;
                    top: -2px;
                    left: 80px;

                    a {
                        display: inline-block;
                        overflow: hidden;
                        width: 124px;
                        height: 15px;
                        color: #4d61a8;
                        line-height: 150%;

                        &:focus,
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}

#wingBnr2 {
    div.minimall {
        ul li {
            a {
                text-decoration: none;
                cursor: pointer;
            }

            img {
                float: left;
                margin-right: 10px;
            }

            span {
                display: block;
                overflow: hidden;
                height: 14px;
                padding-top: 0px;
            }

            a:hover span {
                text-decoration: underline;
            }

            em {
                display: block;
                margin-top: 3px;
                margin-bottom: 5px;
                font-weight: bold;
                font-family: $font-family-number;
                color: #111;

                span {
                    display: inline;
                    text-decoration: none !important;
                }
            }

            div {
                position: absolute;
                left: 80px;
                bottom: -2px;

                &.minicoupon em {
                    margin-top: 1px;
                    margin-bottom: 7px;
                }
            }

            span.coupon {
                color: #6e58d7;
                font-size: 11px;
                letter-spacing: -1px;

                strong {
                    font-family: $font-family-number;
                    font-size: 10px;
                    letter-spacing: 0px;
                }

                span {
                    display: inline;
                    font-weight: bold;
                }
            }
        }

        .btnctr_pn {
            position: static;

            button {
                display: block;
                width: 13px;
                height: 20px;
                padding: 0;
                background: url("/img/default/btn/prev_next13.gif") no-repeat 0 0;
                text-indent: -9999px;

                &.in_prev {
                    top: 25px;
                    left: 7px;
                }

                &.in_next {
                    top: 25px;
                    right: 7px;
                    background-position: 100% 0;
                }
            }
        }

        .no_list {
            height: 40px;
            padding-top: 30px;
            text-align: center;
            letter-spacing: -1px;
        }
    }

    .special_benefit {
        position: relative;
        height: 26px;
        padding: 12px 15px;
        background: #f3f3f3;
        border-bottom: 1px solid #d6d7d9;

        .winglayer_tit {
            padding-left: 0px;
            padding-bottom: 2px;
        }

        div {
            display: block;
            width: 240px;
            padding-left: 6px;
            background: url("/img/default/icon/bul_squ2px_4d4d4d.gif") no-repeat 0 5px;
            font-size: 11px;
            color: #676767;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .defbtn_more {
            top: 7px;
            right: 15px;
            font-size: 11px;
        }
    }

    div.wing_hgroup.special_h > ul {
        height: 156px;
    }

    .special_h {
        .layview {
            min-height: 537px;
        }

        div.winglayer {
            min-height: 534px;
        }
    }

    .ico_custom {
        position: absolute;
        top: 34px;
        left: 32px;
        display: inline-block;
        overflow: hidden;
        width: 38px;
        height: 48px;
        background: url("/img/default/wing_img_v2.gif") no-repeat -79px -22px;
        vertical-align: middle;
        font-size: 0;
        line-height: 200px;

        &.customlv1 {
            background-position: -81px -22px;
        }

        &.customlv2 {
            background-position: -81px -71px;
        }

        &.customlv3 {
            background-position: -81px -120px;
        }

        &.customlv4 {
            background-position: -81px -169px;
        }

        &.customlv6 {
            background-position: -81px -218px;
        }
    }

    .ico_grade {
        position: absolute;
        top: 30px;
        left: 13px;
        display: inline-block;
        overflow: hidden;
        width: 75px;
        height: 55px;
        background: url("/img/default/wing_ico_grade.gif") no-repeat 0 0;
        vertical-align: middle;
        font-size: 0;
        line-height: 200px;

        &.vvip {
            background-position: 0 0;
        }

        &.vip {
            background-position: 0 -55px;
        }

        &.family {
            background-position: 0 -110px;
        }

        &.welcome {
            background-position: 0 -165px;
        }
    }

    .wing_btn {
        border: 1px solid #505364;
        border-top: none;
        background: #63687b;

        a {
            display: block;
            width: 78px;
            height: 21px;
            border-top: 1px solid #505364;
            cursor: pointer;
            background: #63687b;

            span {
                display: block;
                margin: 8px auto;
                overflow: hidden;
                background: url("/img/default/wing_img_v2.gif") no-repeat 0 0;
                font-size: 0;
                text-indent: -9999px;
            }

            &.go_top span {
                width: 34px;
                height: 5px;
                background-position: -62px 0;
            }

            &.go_down span {
                width: 44px;
                height: 5px;
                background-position: -62px -6px;
            }
        }
    }

    .wing_mini {
        position: relative;
        width: 28px;

        .selected .arrow {
            display: block;
        }

        .arrow {
            position: absolute;
            display: none;
            width: 7px;
            height: 11px;
            background: url("/img/default/icon/bul_arr_333_03.gif") no-repeat 0 0;
        }

        h3 {
            width: 26px;
            height: 89px;
            background: #f1f6fc;
            border: 1px solid #c7cbd4;
            font-weight: normal;
            cursor: pointer;

            a {
                display: block;
                width: 10px;
                padding: 0 8px;
                color: #515c7c;
                font-size: 11px;
                text-align: center;
                line-height: 11px;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        .wing_shopinfo,
        .wing_viewprd,
        .wing_recomdprd {
            position: static;
            width: 28px;
            height: 91px;
            border: none;
        }

        .wing_viewprd {
            margin-top: -1px;
        }

        .wing_recomdprd {
            margin-top: -1px;

            .no_list {
                color: #666;
                height: 180px;
                padding: 199px 0 23px 0;
                text-align: center;
                letter-spacing: -1px;
            }
        }

        .wing_viewprd .no_list {
            color: #666;
            height: 180px;
            padding: 199px 0 23px 0;
            text-align: center;
            letter-spacing: -1px;
        }

        .wing_recomdprd .detail_inner,
        .wing_viewprd .detail_inner {
            margin-bottom: 0px;
            padding-top: 12px;
            padding-bottom: 0;
            border: none;
        }

        .wing_recomdprd .detail_inner li,
        .wing_viewprd .detail_inner li {
            margin-bottom: 14px;
            *margin-bottom: 16px;
        }

        .wing_recomdprd .detail_inner ul,
        .wing_viewprd .detail_inner ul {
            margin-top: 8px;
        }

        .wing_recomdprd .btnctr_pn,
        .wing_viewprd .btnctr_pn {
            display: block;
        }

        .wing_shopinfo {
            h3 a {
                padding: 12px 8px;
            }

            .arrow {
                top: 40px;
            }
        }

        .wing_viewprd {
            h3 a {
                padding: 17px 8px;
            }

            .arrow {
                top: 130px;
            }
        }

        .wing_recomdprd {
            h3 {
                background: #e0e6f2;

                a {
                    padding: 23px 8px;
                }
            }

            .arrow {
                top: 221px;
            }
        }
        .wing_btn {
            margin-top: -1px;

            a {
                width: 26px;

                &.go_top span {
                    width: 8px;
                    height: 4px;
                    background-position: -89px 0;
                }

                &.go_down span {
                    width: 8px;
                    height: 4px;
                    background-position: -99px -6px;
                }
            }
        }

        .btnctr_pn {
            top: 202px;
            width: 264px;

            button {
                display: block;
                width: 13px;
                height: 20px;
                padding: 0;
                background: url("/img/default/btn/prev_next13.gif") no-repeat 0 0;
                text-indent: -9999px;

                &.in_prev {
                    top: 25px;
                    left: -2px;
                }

                &.in_next {
                    top: 25px;
                    right: 0px;
                    background-position: 100% 0;
                }
            }
        }
        .winglayer_wrap {
            width: 26px;
        }
    }
}

// Footer
.footer_wrap2 {
    position: relative;
    width: 980px;
    margin: 0 auto 0px;
    color: #7c7c7c;
    font-size: 11px;
    border-top: 1px solid #d6d8da;
    border-bottom: 1px solid #d7d8da;

    .imgstop {
        position: absolute;
        top: 155px;
        right: 151px;
        *width: 65px;
    }

    h2 {
        font-size: 0;
        text-indent: -9999px;
    }

    ul.foot_menu {
        overflow: hidden;
        zoom: 1;
        height: 25px;
        padding-top: 10px;
        text-align: center;
        line-height: 15px;
        letter-spacing: 0px;

        li {
            display: inline-block;
            *display: inline;
            padding: 0px 11px 0 13px;
            background: url("/img/default/icon/bar_9px_d5d5d5.gif") no-repeat 0 2px;

            &:first-child {
                background: none;
            }
        }

        em {
            font-weight: bold;
        }

        a {
            font-size: 12px;
        }
    }

    .foot_info_wrap {
        padding: 11px 0;
        border-top: 1px solid #e7e8e8;
        border-bottom: 1px solid #e7e8e8;
        background: #f8f8f8;
        zoom: 1;
        line-height: 15px;

        &:after {
            display: block;
            clear: both;
            content: "";
        }

        address {
            display: block;
            font-size: 11px;
            line-height: 15px;
            letter-spacing: -0.5px;
            *letter-spacing: -1px;
        }

        em {
            font-weight: bold;
        }

        h3 {
            padding-bottom: 6px;
            color: #3d3d3d;
            font-size: 12px;
            letter-spacing: -1px;
        }

        ul {
            zoom: 1;

            &:after {
                display: block;
                clear: both;
                content: "";
            }
        }

        li {
            float: left;
            padding-right: 7px;
            letter-spacing: -0.5px;
        }

        .inner_wrap {
            color: #4d4d4d;
            height: 49px;
        }

        .link_arr_956c4e {
            position: static;
            display: inline-block;
            padding-right: 6px;
            background: url("/img/default/icon/bul_arr_956c4e.gif") no-repeat 100% 2px;
            color: #956c4e;
            font-family: $font-family-asis;
            font-size: 11px;
            letter-spacing: -1px;
            line-height: 12px;
            *line-height: 14px;
            text-decoration: underline;
        }

        .ftr_customer {
            float: left;
            width: 270px;
            padding: 8px 20px 0 19px;
            background: none;
            border-right: 1px solid #ddd;

            .link_arr_956c4e {
                margin: 0 0px 0 8px;
            }

            address {
                margin-bottom: 2px;
            }

            .email {
                width: 220px;
            }
        }

        .ftr_trouble {
            float: left;
            width: 226px;
            padding: 8px 0 0 20px;
            background: none;
            border-right: 1px solid #ddd;

            ul li {
                float: none;
            }
        }

        .ftr_sk_planet {
            float: left;
            width: 413px;
            padding: 8px 0 0 10px;
        }
    }
}

:root .footer_wrap2 .foot_info_wrap .ftr_sk_planet {
    padding-top: 6px;
}

.footer_wrap2 {
    .foot_info_wrap .ftr_sk_planet {
        h3 {
            padding-bottom: 7px;
        }

        .link_arr_956c4e {
            margin: 0 0px 0 8px;
        }

        .sty_none {
            width: 303px;
        }
    }

    .foot_copyright {
        clear: both;
        height: 42px;
        padding: 45px 0 0 20px;
        color: #666;

        p {
            font-size: 11px;
            letter-spacing: 0px;

            &:first-child {
                letter-spacing: -1px;
                margin-bottom: 1px;
                color: #868ba8;
                font-weight: bold;
            }
        }
    }

    div {
        &.foot_btnwrap {
            position: absolute;
            top: 155px;
            left: 20px;
            padding: 0;

            a {
                margin-right: 2px;
            }
        }

        &.foot_familysite {
            position: absolute;
            right: 20px;
            top: 155px;
            *top: 155px;
            padding-right: 0px;

            h4 {
                position: absolute;
                right: 0;
                top: 29px;
                width: 73px;
                height: 34px;
                background: url("/img/default/footer_logo.png") no-repeat 0 0;
                font-size: 11px;
                letter-spacing: -1px;

                span {
                    position: relative;
                    z-index: -1;
                    text-indent: -9999px;
                    display: block;
                }
            }

            div {
                position: relative;

                button {
                    width: 125px;
                    height: 20px;
                    padding: 0 28px 0 8px;
                    border: 1px solid #ccc;
                    background: #fff url("/img/default/btn/select_arr02.gif") no-repeat 100% -1px;
                    color: #4d4d4d;
                    text-align: left;
                    line-height: 140%;
                    font-size: 11px;
                }

                ul {
                    display: none;
                    position: absolute;
                    bottom: 19px;
                    left: 0;
                    width: 123px;
                    padding: 5px 0;
                    border: 1px solid #ccc;
                    background: #fff;
                    color: #fff;
                    font-size: 11px;
                    line-height: 18px;

                    a {
                        display: block;
                        padding: 1px 0 1px 10px;
                        color: #4d4d4d;

                        &:hover {
                            background: #f2f2f2;
                            text-decoration: none;
                            color: #4c4c4c;
                            font-weight: bold;
                        }
                    }
                }

                &.selected ul {
                    display: block;
                }
            }
        }
    }

    &.laytype2 {
        width: 100%;

        .foot_info_wrap > div {
            width: 980px;
            margin: 0 auto;
        }

        .ftrcopy_wrap {
            position: relative;
            width: 980px;
            margin: 0 auto;
        }

        .foot_btnwrap,
        .foot_familysite {
            top: 15px;
        }

        .imgstop {
            top: 152px;
            left: 50%;
            right: auto;
            margin-left: 280px;
        }
    }
}

// tmp_old_ver. banner size add (from base_4)
.Depart_Temp6 {
    ul {
        margin-top: 30px !important;
        height: 90px !important;
    }

    li {
        height: 90px !important;
    }

    ul li img {
        width: 180px;
        height: 90px !important;
    }
}

.hidden_ifrm {
    position: absolute;
    top: 0;
    left: 0;
}

// Like This
.like_this {
    float: left;
    position: relative;
    width: 49%;
    height: 21px;
    border-left: 1px solid #e5e5e5;
    text-align: center;

    > button {
        display: inline-block;
        background: none;
        width: 100%;
        color: #757575;
        font: 11px/180% $font-family-number;
        text-align: center;
        *line-height: 160%;

        > {
            .likethis_txt {
                display: inline-block;
                overflow: hidden;
                width: 12px;
                height: 11px;
                margin: 5px 1px 0 1px;
                background: url("/img/default/icon/ico_like.gif") no-repeat -26px 0;
                font-size: 0;
                text-indent: -9999px;
                vertical-align: top;
                *vertical-align: -3px;
                *text-indent: 0 !important;
                *line-height: 300%;
                *margin-top: 2px;
            }

            .num_plus {
                display: inline-block;
                width: 5px;
                height: 5px;
                margin: 8px 0 0 1px;
                background: url("/img/default/icon/ico_like.gif") no-repeat 0 -40px;
                vertical-align: top;
            }
        }
    }

    p {
        display: none;
        z-index: 100;
        position: absolute;
        top: 20px;
        left: -32px;
        width: 115px;
        height: 38px;
        padding-top: 16px;
        background: url("/img/listing/wishlist_box02.gif") no-repeat 0 5px;
        color: #f75352 !important;
        font: 11px $font-family-asis;
        line-height: 14px;
        text-indent: 0;
        text-align: center;
        letter-spacing: -1px;

        a {
            display: block;
            float: none !important;
            width: 41px !important;
            margin: 0 auto;
            padding: 2px 6px 3px 0 !important;
            background: url("/img/default/icon/bul_arr_666.gif") no-repeat 100% 5px;
            color: #777;
            text-decoration: underline !important;
        }
    }

    &.checked {
        button {
            color: #e5203b;
        }

        .likethis_txt {
            background-position: -26px -19px;
        }

        > button > .num_plus {
            background-position: -15px -40px;
        }
    }
}

* + html .like_this > button {
    line-height: 160%;

    > {
        .likethis_txt {
            margin-top: 5px;
            vertical-align: middle;
        }

        .num_plus {
            margin-top: 0;
            vertical-align: middle;
        }
    }
}

.deal_bnr {
    width: 80px;
    overflow: hidden;

    img {
        border: 1px solid #d7d7d7;
        border-bottom: none;
        vertical-align: top;
    }
}

// categroy
.navigateWrap .navi {
    width: 1200px;
    margin: 0 auto;
    background: url("/img/default/icon/bul_squ1px_d6d6d6.gif") repeat-x 0 100%;
}

.navi {
    .totalcate_wrap {
        position: relative;
        z-index: 10;
        float: left;
        width: 134px;
        height: 36px;

        .btn_totalcate {
            display: block;
            width: 117px;
            height: 32px;
            margin-left: 10px;
            background: #fff;

            span {
                display: block;
                width: 117px;
                height: 12px;
                margin: 10px 0px 12px 0px;
                background: url("/img/default/gnb_common_v2.gif") no-repeat -100px 0px;
                cursor: pointer;
                overflow: hidden;
                text-indent: -20000px;
            }
        }

        &.selected .inner_totalcate {
            visibility: visible;
        }

        .inner_totalcate {
            visibility: hidden;
            position: absolute;
            top: 0px;
            left: 0px;
            z-index: 1;
            width: 976px;
            height: auto;
            border: 2px solid #363940;
            background: #f6f7fb;
            overflow: hidden;

            h2 {
                width: 970px;
                height: 34px;
                background: #363940;
                padding: 0px 8px 0px 0px;
            }

            .btn_totalcate {
                display: block;
                width: 117px;
                height: 32px;
                margin-left: 10px;
                background: #363940;

                span {
                    background: url("/img/default/gnb_common_v2.gif") no-repeat -218px 0px;
                }
            }
        }

        .btn_close {
            position: absolute;
            top: 7px;
            right: 10px;
            overflow: hidden;
            width: 42px;
            height: 18px;
            background: url("/img/default/btn/close04.gif") no-repeat 0 0;

            span {
                position: relative;
                z-index: -1;
                white-space: nowrap;
                text-align: right;
                font-size: 11px;
                letter-spacing: -1px;
                *font-size: 0;
                *text-indent: -9999px;
            }
        }

        .category {
            float: left;
            width: 828px;
            background: #fff url("/img/default/bg_total.png") repeat-y 0 0;

            > div {
                width: 100%;
                border-bottom: 1px solid #e4e4e4;

                &:after {
                    display: block;
                    clear: both;
                    content: "";
                }

                div {
                    float: left;
                    width: 137px;
                    padding-bottom: 10px;
                    border-right: 1px solid #e5e5e5;
                }
            }

            div h3 {
                display: block;
                height: 29px;
                padding-left: 9px;
                background: #f6f7fb;
                border-bottom: 1px solid #ededef;
                color: #111;
                line-height: 34px;
            }

            ul {
                padding: 8px 0 0 10px;
            }

            li {
                overflow: hidden;
                height: 20px;

                a {
                    line-height: 1.6;
                }

                + a {
                    color: #4d4d4d;

                    &:hover {
                        color: #e71818;
                    }
                }

                &.point a {
                    color: #4e6ccc;
                }
            }
        }

        .popular_corner {
            float: left;
            width: 148px;

            h3 {
                height: 17px;
                margin: 15px 0 0 15px;
                color: #111;
                font-weight: bold;
            }

            ul {
                overflow: hidden;
                margin: 2px 7px 0 16px;
                padding-bottom: 5px;

                li {
                    display: block;
                    background: url("/img/default/icon/bul_2px_4d4d4d.gif") no-repeat 0 7px;
                    overflow: hidden;
                    max-width: 170px;
                    height: 18px;
                    padding-left: 7px;
                    overflow: hidden;

                    &:first-child {
                        border-top: none;
                    }

                    a {
                        color: #4d4d4d;
                        line-height: 18px;
                        cursor: pointer;
                    }
                }
            }

            .btn_area {
                position: absolute;
                right: 8px;
                bottom: 19px;

                span {
                    width: 104px;
                    text-align: center;
                }
            }
        }
    }

    .gnb_menu {
        position: relative;
        z-index: 5;
        width: 1066px;
        margin-left: 134px;

        ul {
            zoom: 1;
            padding: 0px;
            margin: 0;

            &:after {
                display: block;
                clear: both;
                content: "";
            }
        }

        h2 {
            font-size: 0;
            text-indent: -9999px;
            line-height: 0;
        }

        > ul > li {
            position: relative;
            float: left;
            height: 35px;
            *vertical-align: top;

            > a {
                float: left;
                position: relative;
                zoom: 1;
                z-index: 1;
                height: 35px;
                padding: 0 22px;
                margin-left: -1px;
                background: url("/img/default/icon/bar_11px_cbcbcb.gif") no-repeat 0 12px;
            }
        }

        li {
            &:hover,
            &.selected {
                z-index: 2;
            }

            a span {
                display: block;
                *display: inline-block;
                width: 53px;
                height: 13px;
                margin: 11px auto;
                background: url("/img/default/gnb_common.png") no-repeat -100px -13px;
                text-align: center;
                cursor: pointer;
            }

            &.gnbm2 a span {
                width: 83px;
                background-position: -100px -27px;
            }

            &.gnbm3 a span {
                width: 64px;
                background-position: -100px -41px;
            }

            &.gnbm4 a span {
                width: 64px;
                background-position: -100px -55px;
            }

            &.gnbm5 a span {
                width: 64px;
                background-position: -100px -69px;
            }

            &.gnbm6 a span {
                width: 104px;
                background-position: -100px -83px;
            }

            &.gnbm7 a span {
                width: 53px;
                background-position: -100px -97px;
            }

            &.gnbm8 a span {
                width: 73px;
                background-position: -100px -111px;
            }

            &.gnbm9 a span {
                width: 53px;
                background-position: -100px -125px;
            }

            &.gnbm10 a span {
                width: 31px;
                background-position: -100px -139px;
            }

            &.gnbm1 a:hover span {
                background-position: -218px -13px;
            }
        }

        > ul > li {
            &:hover > a,
            &.selected > a {
                z-index: 2;
                background: #fff;
                padding: 0 21px 1px;
                border: 1px solid #ea0001;
                border-top: none;
                border-bottom: none;
            }

            &.gnbm10 {
                > a {
                    padding: 0 19px;
                }

                &:hover > a,
                &.selected > a {
                    padding: 0 18px 1px;
                }
            }
        }
    }
}

div.navi .gnb_menu li.gnbm1.selected span {
    background-position: -218px -13px;
}

.navi .gnb_menu li.gnbm2 a:hover span,
div.navi .gnb_menu li.gnbm2.selected span {
    background-position: -218px -27px;
}

.navi .gnb_menu li.gnbm3 a:hover span,
div.navi .gnb_menu li.gnbm3.selected span {
    background-position: -218px -41px;
}

.navi .gnb_menu li.gnbm4 a:hover span,
div.navi .gnb_menu li.gnbm4.selected span {
    background-position: -218px -55px;
}

.navi .gnb_menu li.gnbm5 a:hover span,
div.navi .gnb_menu li.gnbm5.selected span {
    background-position: -218px -69px;
}

.navi .gnb_menu li.gnbm6 a:hover span,
div.navi .gnb_menu li.gnbm6.selected span {
    background-position: -218px -83px;
}

.navi .gnb_menu li.gnbm7 a:hover span,
div.navi .gnb_menu li.gnbm7.selected span {
    background-position: -218px -97px;
}

.navi .gnb_menu li.gnbm8 a:hover span,
div.navi .gnb_menu li.gnbm8.selected span {
    background-position: -218px -111px;
}

.navi .gnb_menu li.gnbm9 a:hover span,
div.navi .gnb_menu li.gnbm9.selected span {
    background-position: -218px -125px;
}

.navi .gnb_menu li.gnbm10 a:hover span,
div.navi .gnb_menu li.gnbm10.selected span {
    background-position: -218px -139px;
}

.navi .gnb_menu li {
    span span {
        overflow: hidden;
        position: relative;
        z-index: -1;
        height: 11px;
        font-size: 11px;
        letter-spacing: -2px;
        *font-size: 0;
        *text-indent: -9999px;
    }

    &.selected div {
        &.gnb_inner {
            visibility: visible !important;
        }

        &.gnb_inner_wrap2 {
            height: 398px !important;
        }
    }

    div.gnb_inner_wrap2 {
        visibility: visible;
        position: absolute;
        top: 35px;
        left: -1px;
        width: 732px;
        height: 0px;
        padding: 0px;
        transition: height 0.4s ease 0s;
        overflow: hidden;

        .gnb_inner {
            visibility: hidden;
            position: absolute;
            top: 0px;
            border: 1px solid #ea0001;
            background: #fff;
            width: 411px;
            min-height: 396px;
            max-height: 396px;

            .grouping {
                float: left;
                width: 178px;
                height: 398px;
                padding: 10px 19px 10px 8px;

                &:first-child {
                    border-right: 1px solid #e4e4e4;
                }

                h3 {
                    height: 20px;
                    padding: 7px 0 0 11px;
                    background: #eff0f5;
                    font-weight: bold;
                }

                ul {
                    padding: 2px 0 20px 0;
                    zoom: 1;
                }

                li {
                    padding-left: 11px;
                    margin-top: 4px;
                }

                a {
                    color: #4d4d4d;

                    span {
                        display: inline-block;
                        width: auto;
                        height: auto;
                        margin: 0;
                        background: none;
                    }

                    &:hover {
                        color: #4d4d4d;
                    }
                }

                .special {
                    h3 {
                        height: 13px;
                        padding-top: 0px;
                        background: none;
                        color: #4e6ccc;
                    }

                    a {
                        color: #4e6ccc;

                        &:hover {
                            color: #4e6ccc;
                        }
                    }

                    ul {
                        padding-bottom: 5px;
                    }
                }
            }

            .banner {
                position: absolute;
                height: 100%;
                top: -1px;
                right: -302px;
                width: 300px;
                border: 1px solid #ea0001;
                border-left: 1px solid #e4e4e4;
                background: #fff;
                overflow: hidden;

                .ban02 a {
                    position: absolute;
                    top: 199px;
                    z-index: 1;
                    display: block;
                    height: 198px;
                    border-left: none;

                    img {
                        width: 300px;
                        height: 198px;
                    }

                    &:first-child {
                        top: 0px;
                        z-index: 2;
                        padding-top: 0px;
                        border-bottom: 1px solid #e4e4e4;
                    }
                }

                .ban01 a {
                    position: absolute;
                    top: 0px;
                    z-index: 1;
                    display: block;
                    height: 396px;
                    padding-top: 0px;
                    border-left: none;

                    img {
                        width: 300px;
                        height: 396px;
                    }
                }
            }
        }
    }

    &.gnbm3 div.gnb_inner_wrap2 {
        left: -46px;
    }

    &.gnbm4 div.gnb_inner_wrap2 {
        left: -131px;
    }

    &.gnbm5 div.gnb_inner_wrap2 {
        left: -216px;
    }

    &.gnbm6 div.gnb_inner_wrap2 {
        left: -301px;
    }

    &.gnbm7 div.gnb_inner_wrap2 {
        left: -426px;
    }

    &.gnbm8 div.gnb_inner_wrap2 {
        left: -500px;
    }

    &.gnbm9 div.gnb_inner_wrap2 {
        left: -594px;
    }

    &.gnbm10 div.gnb_inner_wrap2 {
        left: -646px;
    }

    &.gnbm3 div.gnb_inner_wrap2.non_ban,
    &.gnbm4 div.gnb_inner_wrap2.non_ban,
    &.gnbm5 div.gnb_inner_wrap2.non_ban,
    &.gnbm6 div.gnb_inner_wrap2.non_ban {
        left: -1px;
    }

    &.gnbm7 div.gnb_inner_wrap2.non_ban {
        left: -125px;
    }

    &.gnbm8 div.gnb_inner_wrap2.non_ban {
        left: -199px;
    }

    &.gnbm9 div.gnb_inner_wrap2.non_ban {
        left: -293px;
    }

    &.gnbm10 div.gnb_inner_wrap2.non_ban {
        left: -367px;
    }

    div.gnb_inner_wrap2 {
        .btn_close {
            position: absolute;
            z-index: 2;
            top: 10px;
            right: -292px;
            width: 16px;
            height: 15px;
            background: url("/img/default/btn/close06.gif") no-repeat 0 0;
            overflow: hidden;
            text-indent: -20000px;
        }

        &.non_ban .btn_close {
            display: none;
        }
    }
}

.locatwrap {
    position: relative;
    z-index: 30;
    width: 980px;
    margin: 0 auto;
    padding-top: 10px;

    &:after {
        display: block;
        clear: both;
        content: "";
    }

    h2 {
        font-size: 0;
        text-indent: -9999px;
    }

    .go_home {
        float: left;
        padding: 2px 8px 0;
        color: #666;
    }

    button {
        overflow: hidden;
        width: 178px;
        height: 21px;
        padding: 0 22px 0 8px;
        border: 1px solid #bbb;
        background: #fff url("/img/default/btn/select_arr.gif") no-repeat 100% 0;
        color: #4d4d4d;
        font: 12px/110% $font-family-asis;
        text-align: left;
    }

    .locastep {
        position: relative;
        float: left;
        padding: 0 7px 0 12px;
        background: url("/img/default/icon/bar_arr9px_666.gif") no-repeat 0 4px;

        &.txtyp {
            // 텍스트형
            float: left;
            padding: 2px 8px 0 13px;
        }
    }

    div {
        &.loca_conts {
            display: none;
            position: absolute;
            top: 23px;
            left: 12px;
            overflow: hidden;
            width: 174px;
            padding: 5px 0;
            border: 2px solid #666;
            background: #fff;
        }

        &.locastep.selected .loca_conts {
            display: block;
        }

        &.loca_conts {
            ul {
                width: auto;
                line-height: 18px;
            }

            li {
                float: left;
                width: 179px !important;
                vertical-align: top;

                a {
                    padding-left: 10px;
                }
            }

            ul em {
                color: #e71818;
                font-weight: bold;
            }

            &.li_5over {
                width: 358px;
                background: #fff url("/img/default/icon/bar_10px_dcdcdc.gif") repeat-y 177px 0;
            }
        }
    }
}

div.loca_conts.li_50over {
    width: 358px;
    background: #fff url("/img/default/icon/bar_10px_dcdcdc.gif") repeat-y 177px 0;
}

.locatwrap div {
    &.loca_conts {
        &.li_5over li {
            float: left;
        }

        &.li_50over {
            height: 412px;
            background-position: 170px 0;

            ul {
                height: 410px;
                overflow: scroll;
                overflow-x: hidden;

                li {
                    width: 170px;
                }
            }
        }
    }

    &.first_cate div.loca_conts {
        width: 938px;
        padding: 0;
        background: url("/img/default/bg_locat.png") repeat-y 0 0;
    }

    &.loca_conts {
        > div {
            width: 100%;
            border-bottom: 1px solid #e4e4e4;

            &:after {
                display: block;
                clear: both;
                content: "";
            }

            > div {
                float: left;
                width: 156px;
                padding-bottom: 10px;
                margin-left: -1px;
                border-left: 1px solid #eaeaea;

                &:nth-child(6) {
                    width: 158px;
                }
            }
        }

        div {
            h3 {
                height: 32px;
                padding-left: 10px;
                border-bottom: 1px solid #cbcbcb;
                background: #f2f2f2;
                color: #333;
                line-height: 35px;
            }

            ul {
                padding-top: 5px;
            }

            li {
                float: none;

                &.point a {
                    color: #4e6ccc;
                }
            }
        }
    }

    &.locastep.last_cate {
        background: none !important;
    }

    &.loca_conts.loca_specialshop {
        width: 378px;
        min-height: 252px;
        _height: 252px;
        background: #fff url("/img/default/location_special_bg.gif") repeat-y 100% 0;

        li {
            float: none;

            a {
                display: block;
                line-height: 22px;

                &:hover {
                    background: #f5f5f5;
                    color: #e71818;
                    font-weight: bold;
                }
            }
        }
    }
}

div.loca_conts.loca_specialshop li.selected a {
    background: #f5f5f5;
    color: #e71818;
    font-weight: bold;
}

.locatwrap div.loca_conts.loca_specialshop {
    ul ul {
        display: none;
        overflow: auto;
        overflow-x: hidden;
        position: absolute;
        top: 7px;
        left: 185px;
        width: 188px;
        height: 250px;
        background: #f5f5f5;
    }

    li:hover ul {
        display: block;
    }
}

div.loca_conts.loca_specialshop .selected ul {
    display: block;
}

.locatwrap {
    div.loca_conts.loca_specialshop li.selected ul a {
        color: #4d4d4d;
        font-weight: normal;
    }

    button.btn_rss {
        width: 18px;
        height: 18px;
        padding: 0;
        border: none;
        background: url("/img/default/icon/ico_rss.gif") no-repeat 0 0;
        font-size: 0;
        text-indent: -9999px;
    }
}

// dayclose popup
.dayclose_popwrap {
    position: relative;
    z-index: 110;
    width: 1200px;
    margin: 0 auto;
}

.daypop {
    position: absolute;
    z-index: 40;
    top: 0;
    left: 0;
    width: 430px;

    .daypop_cnt {
        zoom: 1;
        margin: 0;
        padding: 0;
        border: 2px solid #475b82;
        border-top-width: 1px;
        background-color: #fff;

        h4 {
            height: 29px;
            margin: 0 -1px;
            border-bottom: 1px solid #333;
            background: #475b82;
            font-weight: bold;
            color: #fff;
            overflow: hidden;

            div.title,
            > span {
                display: block;
                overflow: hidden;
                height: 14px;
                margin: 7px 10px 0 10px;
                font-size: 12px;
                line-height: 14px;
            }
        }

        .cont {
            zoom: 1;
            overflow: hidden;
            padding: 10px;

            .my_benefit {
                text-align: center;
                border: 1px solid #ededed;
                background: #f4f4f4;
                padding: 20px 10px;

                strong {
                    display: block;
                    margin: 0 0 10px 0;
                    font-size: 15px;
                    color: #e90100;
                    font-weight: bold;
                    line-height: 20px;
                    letter-spacing: -1px;
                }

                span {
                    display: block;
                    margin: 0 0 18px 0;
                    font-size: 11px;
                    color: #666;
                    line-height: 14px;
                }

                em {
                    display: block;
                    font-size: 12px;
                    font-weight: bold;
                    color: #333;
                    line-height: 20px;
                }
            }
        }
    }
}

// default coupon
.def_cpn {
    position: relative;
    z-index: 1;
    width: 168px;
    height: 78px;
    padding: 16px 15px 0 23px;
    background: url("/img/default/cpn.png") no-repeat 0 0;
    text-align: center;

    > span {
        letter-spacing: 0px;
    }

    &.c_blue {
        background-position: 0 -359px;

        strong {
            color: #5365ed;
        }
    }

    &.c_mileage {
        width: 141px;
        height: 81px;
        padding: 13px 0 0 65px;
        background-position: 0 -94px;

        strong {
            visibility: hidden;
            width: 1px;
        }

        em {
            color: #f9a200;
        }
    }

    &.c_point {
        width: 141px;
        height: 81px;
        padding: 13px 0 0 65px;
        background-position: 0 -188px;

        strong {
            visibility: hidden;
            width: 1px;
        }

        em {
            color: #f96619;
        }
    }

    &.c_package {
        width: 206px;
        height: 77px;
        padding: 0;
        background-position: 0 -282px;
        text-indent: -20000px;
    }

    strong {
        display: block;
        width: 100%;
        height: 14px;
        color: #ea0000;
        margin-left: -6px;
        overflow: hidden;
        font-family: 굴림;
        line-height: 130%;
    }

    em {
        display: block;
        width: 100%;
        font-family: $font-family-number;
        font-size: 34px;
        font-weight: bold;
        color: #111;
        line-height: auto;
    }
}

:root .def_cpn em {
    line-height: 38px;
}

.def_cpn {
    em span {
        font-weight: normal;
    }

    > span {
        display: block;
        width: 100%;
        height: 14px;
        margin-top: 3px;
        *margin-top: 0;
        margin-left: -6px;
        color: #666;
        overflow: hidden;
        font-family: 굴림;
        line-height: 150%;
        letter-spacing: -1px;
    }

    .c_app,
    .c_sapp,
    .c_bapp {
        position: absolute;
        bottom: -5px;
        left: 0px;
        width: 28px;
        height: 48px;
        background: url("/img/evcpbenefit/cplaun/ico_hp.png") no-repeat 0 0;
        overflow: hidden;
        text-indent: -20000px;
    }

    .c_sapp {
        background-position: -28px 0;
    }

    .c_bapp {
        background-position: -56px 0;
    }
}

.cpn_box {
    position: relative;
    z-index: 1;
    width: 237px;
    padding-top: 15px;
    border: 1px solid #cbd3e0;
    border-bottom: 1px solid #a7aeb8;
    background: #fff;

    .def_cpn {
        margin: 0 auto;
    }

    .cp_name {
        height: 37px;
        padding: 0 10px;
        margin-top: 15px;
        border-top: 1px solid #f0f1f5;
        background: url("/img/evcpbenefit/cplaun/cp_name_bg.gif") repeat-x 0 36px;
        color: #333;
        line-height: 36px;
        overflow: hidden;
        text-align: center;
    }

    .cp_date {
        min-height: 32px;
        padding: 3px 10px;
        margin-top: -1px;
        border-top: 1px solid #f0f1f5;
        background: url("/img/evcpbenefit/cplaun/cp_name_bg.gif") repeat-x 0 36px;
        color: #757575;
        text-align: center;

        .v {
            display: inline-block;
            width: 0px;
            min-height: 31px;
            margin: 0 0 0 -1px;
            vertical-align: middle;
        }

        .tx {
            display: inline-block;
            vertical-align: middle;
        }
    }

    .btn_cpdown {
        display: block;
        width: 100%;
        height: 36px;
        background: #fff;

        span {
            display: inline-block;
            padding-left: 19px;
            background: url("/img/evcpbenefit/cplaun/ico_down.gif") no-repeat 0 50%;
            font-weight: bold;
            color: #333;
        }
    }

    button.btn_cpdown:hover {
        background: #ea0000 url("/img/evcpbenefit/cplaun/ico_cpover.png") no-repeat 100% 100%;

        span {
            background-image: url("/img/evcpbenefit/cplaun/ico_down_fff.gif");
            color: #fff;
        }
    }

    span.btn_cpdown {
        height: 36px;
        line-height: 36px;
        font-weight: bold;
        color: #a3a3a3;
        letter-spacing: -1px;
        text-align: center;
    }

    &.c_blue button.btn_cpdown:hover {
        background-color: #5365ed;
    }

    &.c_mileage button.btn_cpdown:hover {
        background-color: #f9a200;
    }

    &.c_point button.btn_cpdown:hover {
        background-color: #f96619;
    }
}

.def_cpn.disable,
.cpn_box.disable .def_cpn {
    background-position: -206px 0px;
}

.def_cpn.c_blue.disable,
.cpn_box.disable .def_cpn.c_blue {
    background-position: -206px -359px;
}

.def_cpn.c_mileage.disable,
.cpn_box.disable .def_cpn.c_mileage {
    background-position: -206px -94px;
}

.def_cpn.c_point.disable,
.cpn_box.disable .def_cpn.c_point {
    background-position: -206px -188px;
}

.def_cpn {
    &.c_package.disable {
        background-position: -206px -282px;
    }

    &.disable {
        strong,
        em,
        > span {
            color: #999;
        }
    }
}

.cpn_box.disable {
    .def_cpn {
        strong,
        em,
        > span {
            color: #999;
        }
    }

    .cp_name {
        color: #999;
    }
}

.def_cpn .flag_wrap {
    position: absolute;
    left: 0;
    top: 0;
    text-align: left;

    div {
        display: block;
        width: 44px;
        height: 20px;
        background: url("/img/evcpbenefit/cplaun/ico_card.gif") no-repeat 0 0;
        overflow: hidden;
        text-indent: -20000px;
    }

    .ico_card_01 {
        // 국민
        background-position: 0 0;
    }

    .ico_card_02 {
        // 삼성
        background-position: 0 -21px;
    }

    .ico_card_04 {
        // 하나카드(구 외환)
        width: 49px;
        background-position: 0 -42px;
    }

    .ico_card_06 {
        // 롯데
        background-position: 0 -63px;
    }

    .ico_card_07 {
        // 현대
        width: 49px;
        background-position: 0 -84px;
    }

    .ico_card_08 {
        // 신한
        width: 49px;
        background-position: 0 -105px;
    }

    .ico_card_11 {
        // 시티
        background-position: 0 -126px;
    }

    .ico_card_16 {
        // 농협
        background-position: 0 -147px;
    }

    .ico_card_17 {
        // 하나
        background-position: 0 -168px;
    }

    .ico_card_27 {
        // 비씨
        background-position: 0 -189px;
    }

    .ico_card_31 {
        // 우리
        background-position: 0 -210px;
    }

    .ico_card_35 {
        // sc
        background-position: 0 -231px;
    }

    .ico_card_36 {
        // ibk
        width: 49px;
        background-position: 0 -252px;
    }
}

.defbtn_cdown {
    display: inline-block;
    width: 154px;
    height: 50px;
    padding-left: 20px;
    background: #fff;
    font-size: 14px;
    font-weight: bold;
    line-height: 3.5;
    vertical-align: middle;

    &.ctype {
        border: 1px solid #ea0000;
        background: #fff url("/img/default/btn/def_down.png") no-repeat 20px 50%;
        color: #ea0000;
    }

    &.ctype2 {
        border: 1px solid #3a3c45;
        background: #fff url("/img/default/btn/def_down2.png") no-repeat 20px 50%;
        color: #3a3c45;
    }

    &.ctype:hover {
        background: #ea0000 url("/img/default/btn/def_down_w.png") no-repeat 20px 50%;
        color: #fff;
    }

    &.ctype2:hover {
        background: #3a3c45 url("/img/default/btn/def_down_w.png") no-repeat 20px 50%;
        color: #fff;
    }
}

span.defbtn_cdown {
    padding-left: 0px;
    border: 1px solid #bfbfbf;
    background: #ececec;
    text-align: center;
    color: #999;
    vertical-align: middle;
}

// 쿠폰 신규 추가
.def_cpn {
    &.c_size_big {
        width: 210px;
        height: 100px;
        padding: 12px 18px 0 18px;
        background: url(/img/default/cpn_l.png) no-repeat left top;

        strong {
            height: 20px;
            font-weight: normal;
            font-family: $font-family-default;
            font-size: 16px;
            line-height: 20px;
        }

        em {
            font-size: 40px;
            line-height: 44px;
        }

        > span {
            height: 20px;
            font-family: $font-family-default;
            font-size: 14px;
            line-height: 20px;
        }

        &.c_blue {
            background-position: left -224px;
        }

        &.c_mileage {
            background-position: left -336px;
        }

        &.c_point {
            background-position: left -448px;
        }

        &.disable {
            background-position: left -112px;
        }
    }

    &.c_size_small {
        width: 150px;
        height: 72px;
        padding: 7px 12px 0 12px;
        background: url("/img/default/cpn_s.png") no-repeat left top;

        strong {
            height: 16px;
            font-weight: normal;
            font-family: $font-family-default;
            font-size: 12px;
            line-height: 16px;
        }

        em {
            font-size: 28px;
            line-height: 30px;
        }

        > span {
            height: 16px;
            font-family: $font-family-default;
            font-size: 12px;
            line-height: 16px;
        }

        &.c_blue {
            background-position: left -158px;
        }

        &.c_mileage {
            background-position: left -237px;
        }

        &.c_point {
            background-position: left -316px;
        }

        &.disable {
            background-position: left -79px;
        }
    }

    &.c_down_big {
        position: relative;
        width: 211px;
        height: 100px;
        padding: 12px 96px 0 18px;
        background: url("/img/default/cpn_l.png") no-repeat -246px top;

        strong {
            height: 20px;
            font-weight: normal;
            font-family: $font-family-default;
            font-size: 16px;
            line-height: 20px;
        }

        em {
            font-size: 40px;
            line-height: 44px;
        }

        > span {
            height: 20px;
            font-family: $font-family-default;
            font-size: 14px;
            line-height: 20px;
        }

        .btn_cpn_down {
            position: absolute;
            top: 0;
            right: 0;
            width: 80px;
            height: 112px;
            margin-top: 0;
            font-size: 0;
            line-height: 0;
            color: transparent;
            background: transparent;
        }

        &.c_blue {
            background-position: -246px -224px;
        }

        &.c_mileage {
            padding-right: 90px;
            background-position: -246px -336px;
            padding-right: 88px;
        }

        &.c_point {
            padding-right: 88px;
        }

        &.c_mileage .btn_cpn_down {
            width: 72px;
        }

        &.c_point {
            background-position: -246px -448px;

            .btn_cpn_down {
                width: 72px;
            }
        }

        &.disable {
            background-position: -246px -112px;
        }

        &.c_mileage.disable {
            background-position: -571px -336px;
        }

        &.c_point.disable {
            background-position: -571px -448px;
        }

        &.c_point em,
        &.c_mileage em {
            margin-left: -15px;
        }
    }

    &.c_down_small {
        width: 149px;
        height: 72px;
        padding: 7px 70px 0 12px;
        background: url("/img/default/cpn_s.png") no-repeat -173px top;

        strong {
            height: 16px;
            font-weight: normal;
            font-family: $font-family-default;
            font-size: 12px;
            line-height: 16px;
        }

        em {
            font-size: 28px;
            line-height: 30px;
        }

        > span {
            height: 16px;
            font-family: $font-family-default;
            font-size: 12px;
            line-height: 16px;
        }

        .btn_cpn_down {
            position: absolute;
            top: 0;
            right: 0;
            width: 57px;
            height: 79px;
            margin-top: 0;
            font-size: 0;
            line-height: 0;
            color: transparent;
            background: transparent;
        }

        &.c_blue {
            background-position: -173px -158px;
        }

        &.c_mileage {
            background-position: -173px -237px;
        }

        &.c_point {
            background-position: -173px -316px;
        }

        &.c_mileage em,
        &.c_point em {
            margin-left: -10px;
            color: #000;
        }

        &.c_mileage,
        &.c_point {
            padding-right: 64px;
        }

        &.c_mileage .btn_cpn_down,
        &.c_point .btn_cpn_down {
            width: 51px;
        }

        &.disable {
            background-position: -173px -79px;
        }

        &.c_mileage.disable {
            background-position: -403px -237px;
        }

        &.c_point.disable {
            background-position: -403px -316px;
        }
    }

    &.c_size_big.c_mileage strong,
    &.c_size_small.c_mileage strong,
    &.c_down_big.c_mileage strong,
    &.c_down_small.c_mileage strong {
        visibility: visible;
        width: 100%;
        color: #d89300;
    }

    &.c_size_big.c_point strong,
    &.c_size_small.c_point strong,
    &.c_down_small.c_point strong {
        visibility: visible;
        width: 100%;
        color: #df5b1f;
    }

    &.c_down_big {
        &.c_point strong {
            visibility: visible;
            width: 100%;
            color: #df5b1f;
        }

        &.c_mileage.disable {
            strong,
            em {
                color: #999;
            }
        }

        &.c_point.disable {
            strong,
            em {
                color: #999;
            }
        }
    }

    &.c_down_small {
        &.c_mileage.disable {
            strong,
            em {
                color: #999;
            }
        }

        &.c_point.disable {
            strong,
            em {
                color: #999;
            }
        }
    }

    &.c_size_big {
        &.c_mileage em,
        &.c_point em {
            color: #000;
        }
    }

    &.c_size_small {
        &.c_mileage em,
        &.c_point em {
            color: #000;
        }
    }

    &.c_down_big {
        &.c_mileage em,
        &.c_point em {
            color: #000;
        }
    }
}

// 2018.11 쿠폰리뉴얼
.def_cp {
    position: relative;
    z-index: 1;
    width: 360px;
    font-family: $font-family-default;
    color: #000;
    letter-spacing: -1px;
    background: #fff;
    border-radius: 14px;

    .frame_g {
        display: block;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        *width: 358px;
        *height: 99.8%;
        border: 1px solid #000;
        border-radius: 14px;
        opacity: 0.1;
        filter: alpha(opacity=10);
        box-sizing: border-box;
    }

    .cp_info {
        position: relative;
        z-index: 1;
        width: 346px;
        height: 180px;
        background: url("/img/common/v2/sp_cupon.png") no-repeat 100% 0 #fff;
        border-left: 14px solid #dd2a3d;
        border-radius: 14px 14px 0 0;

        .cp_name {
            position: absolute;
            z-index: 1;
            top: 93px;
            left: 26px;
            overflow: hidden;
            width: 195px;
            max-height: 58px;
            font-size: 24px;
            color: $color-11st-red;
            line-height: 29px;
        }

        .cp_rate {
            display: inline-block;
            margin: 13px 0 0 27px;
            font-size: 58px;
            letter-spacing: -3px;
        }

        .cp_channel {
            position: absolute;
            z-index: 1;
            top: 16px;
            right: 16px;
            display: block;
            height: 36px;
            padding: 0 15px;
            border-radius: 18px;
            background: #333;
            color: #fff;

            font: {
                size: 17px;
                weight: bold;
            }

            line-height: 33px;
            overflow: hidden;
        }
    }
    .lnk_detail,
    button.btn_down,
    span.btn_down {
        display: block;
        width: 100%;
        height: 62px;
        border: 1px solid #e5e5e5;
        border: {
            top: 1px dashed #d9d9d9;
            radius: 0 0 14px 14px;
        }

        font: {
            size: 20px;
            weight: bold;
        }

        background: #fafafa;
        text-align: center;
        line-height: 60px;
        letter-spacing: -2px;
        box-sizing: border-box;
        text-decoration: none !important;
        color: #111;
    }

    .lnk_detail,
    span.btn_down {
        *width: 358px;
        *height: 60px;
    }

    .lnk_detail {
        &:after {
            display: inline-block;
            content: "";
            width: 23px;
            height: 18px;
            margin: {
                left: 8px;
                bottom: -2px;
            }

            background: url("/img/common/v2/sp_cupon.png") no-repeat 0 -368px;
        }
    }

    button.btn_down {
        &:after {
            display: inline-block;
            content: "";
            width: 26px;
            height: 24px;
            margin: {
                left: 8px;
                bottom: -4px;
            }
            background: url("/img/common/v2/sp_cupon.png") no-repeat -28px -367px;
        }
    }

    span.btn_down {
        color: #b9b9b9;
    }

    .btn_box {
        position: relative;
        z-index: 2;
        width: 100%;
        text-align: right;
        box-sizing: border-box;
    }

    .btn_guide {
        display: inline-block;
        margin: 0 20px 13px 0;

        font: {
            size: 16px;
            weight: bold;
        }

        color: #111;
        letter-spacing: -2px;

        text: {
            align: right;
            decoration: none !important;
        }

        &:after {
            display: inline-block;
            content: "";
            width: 20px;
            height: 20px;
            margin-left: 8px;
            background: url("/img/common/v2/sp_cupon.png") no-repeat -57px -368px;
            vertical-align: middle;
        }
    }

    &.style_simple .cp_info {
        border-radius: 14px;
    }

    &.style_simple .cp_name_big {
        display: block;
        width: 292px;
        height: 180px;
        font-size: 51px;
        color: #000;
        letter-spacing: -2px;
        text-align: center;
        line-height: 180px;
        *line-height: 170px;
    }

    .cp_guide {
        border-top: 1px solid #e5e5e5;
        padding: 7px 5px 17px 20px;
        font-size: 16px;
        text-align: left;
        *zoom: 1;

        li {
            position: relative;
            z-index: 1;
            padding-left: 11px;
            margin: 5px 0;
            color: #333;
            max-height: 40px;
            overflow: hidden;
            *zoom: 1;
            *list-style-type: disc;
            *list-style: inside;
            *vertical-align: middle;
            *text-indent: -10px;
            line-height: 20px;

            &:before {
                position: absolute;
                top: 10px;
                left: 0px;
                display: block;
                content: "";
                width: 6px;
                height: 1px;
                overflow: hidden;
                background: #333;
            }
        }
    }

    .pay11 {
        display: inline-block;
        &:after {
            display: inline-block;
            content: "";
            width: 10px;
            height: 12px;
            background: url("/img/common/v2/sp_cupon.png") no-repeat -27px -395px;
            margin: 0 0px 0 3px;
            vertical-align: middle;
        }
    }
}

// 공통쿠폰 middle 사이즈
.def_cp.cp_siz_mid {
    width: 240px;
    border-radius: 8px;

    &.style_height_fixed {
        height: 337px;
    }

    &.style_height_fixed .btn_box {
        position: absolute;
        bottom: 0;
    }

    .cp_info {
        width: 230px;
        height: 120px;
        border-left-width: 10px;
        background-position: 100% -30px;
        border-radius: 8px 8px 0 0;

        .cp_channel {
            top: 10px;
            right: 10px;
            height: 28px;
            border-radius: 14px;
            font-size: 14px;
            line-height: 26px;
            padding: 0 12px;
        }
    }

    &.style_simple {
        .cp_info {
            border-radius: 8px;
        }
        .cp_name_big {
            width: 203px;
            height: 120px;
            font-size: 37px;
            line-height: 120px;
        }
    }

    .cp_name {
        width: 130px;
        max-height: 42px;
        top: 65px;
        left: 20px;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: -1px;
    }

    .cp_rate {
        font-size: 38px;
        margin-left: 21px;
        letter-spacing: -2px;
    }

    .cp_guide {
        position: relative;
        z-index: 1;
        font-size: 16px;
        height: 83px;
        overflow: hidden;
        padding-left: 15px;

        li {
            padding: 2px 10px 0 15px;
            &:before {
                top: 12px;
                width: 6px;
            }
        }
    }

    .lnk_detail {
        height: 55px;
        font-size: 16px;
        line-height: 53px;

        &:after {
            width: 19px;
            height: 14px;
            background-position: -4px -370px;
        }
    }

    button {
        &.btn_down {
            height: 55px;
            font-size: 16px;
            line-height: 53px;
        }
    }

    span.btn_down {
        height: 55px;
        font-size: 16px;
        line-height: 53px;
    }

    .frame_g {
        border-radius: 8px;
        -ms-border-radius: 8px;
    }

    .lnk_detail,
    button.btn_down,
    span.btn_down {
        border-radius: 0 0 8px 8px;
        -ms-border-radius: 0 0 8px 8px;
    }

    .frame_g,
    .lnk_detail,
    span.btn_down {
        *width: 238px;
    }
}

// 공통쿠폰 small 사이즈
.def_cp.cp_siz_small {
    width: 200px;
    border-radius: 6px;

    .style_height_fixed {
        height: 293px;

        .btn_box {
            position: absolute;
            bottom: 0;
        }
    }

    .cp_info {
        width: 192px;
        height: 100px;
        border-left-width: 8px;
        background-position: 100% -39px;
        border-radius: 6px 6px 0 0;

        .cp_channel {
            top: 9px;
            right: 9px;
            height: 24px;
            padding: 0 10px;
            border-radius: 12px;
            font-size: 12px;
            line-height: 22px;
        }
    }

    &.style_simple {
        .cp_info {
            border-radius: 6px;
        }

        .cp_name_big {
            width: 172px;
            height: 100px;
            font-size: 32px;
            line-height: 100px;
        }
    }

    .cp_name {
        top: 52px;
        left: 12px;
        width: 115px;
        max-height: 34px;
        font-size: 15px;
        line-height: 17px;
    }

    .cp_rate {
        font-size: 32px;
        margin: 5px 0 0 12px;
        letter-spacing: -2px;
    }

    .cp_guide {
        position: relative;
        z-index: 1;
        height: 83px;
        padding-left: 10px;
        font-size: 14px;
        overflow: hidden;

        li {
            padding: 2px 0 0 10px;
            max-height: 37px;
            line-height: 19px;
            &:before {
                top: 12px;
                width: 6px;
            }
        }
    }

    .btn_guide {
        font-size: 14px;
    }

    .lnk_detail {
        height: 48px;
        font-size: 16px;
        line-height: 44px;

        &:after {
            width: 19px;
            height: 14px;
            background-position: -4px -370px;
        }
    }

    button.btn_down {
        height: 48px;
        font-size: 16px;
        line-height: 44px;

        &:after {
            width: 22px;
            height: 20px;
            background-position: -29px -370px;
        }
    }

    span.btn_down {
        height: 48px;
        font-size: 16px;
        line-height: 44px;
    }

    .frame_g {
        border-radius: 6px;
    }

    .lnk_detail,
    button.btn_down,
    span.btn_down {
        border-radius: 0 0 6px 6px;
    }

    .frame_g,
    .lnk_detail,
    span.btn_down {
        *width: 198px;
    }
}

// 공통쿠폰 xsmall 사이즈
.def_cp.cp_siz_xsmall {
    width: 100px;
    border-radius: 3px;

    .frame_g {
        border-radius: 3px;
        *width: 98px;
        *height: 48px;
    }

    .cp_info {
        border-radius: 3px 3px 0 0;
        width: 100px;
        *width: 94px;
        height: 50px;
        border-left-width: 6px;
        background-position: 100% -65px;
        border-radius: 3px;
        box-sizing: border-box;

        .cp_channel {
            top: -12px;
            right: -11px;
            height: 24px;
            padding: 0 9px;
            border-radius: 12px;
            font-size: 12px;
            line-height: 23px;
        }
    }

    .cp_rate {
        text-align: center;
        font-size: 22px;
        width: 80px;
        margin: 12px 0 0 0;
        letter-spacing: -1px;
    }
}

// 배송비 쿠폰
.def_cp.type_delivery {
    .cp_info {
        background-position-y: -184px;
        border-color: #4f63fa;
    }

    .cp_name {
        color: #5165f4;
    }

    &.cp_siz_mid .cp_info {
        background-position-y: -213px;
    }

    &.cp_siz_small .cp_info {
        background-position-y: -223px;
    }

    &.cp_siz_xsmall .cp_info {
        background-position-y: -248px;
    }
}

// [이하 리뉴얼 추가 CSS]
// [DEFAULT]

body.hidden {
    visibility: hidden;
}

// [LayerPopup-레이아웃]
.lay_nw_def {
    display: none;
    position: absolute;
    z-index: 1000;
    width: 398px;
    border: 1px solid #666;
    background-color: #fff;

    .sub_tit {
        color: #111;
        padding-bottom: 4px;
    }
    .sub_text {
        color: #666;
    }

    &.on {
        display: block;
    }

    &.wds {
        width: 248px;
    }

    .lay_tit {
        height: 38px;
        padding: 0 15px;
        border-bottom: 1px solid #dadada;
        font-size: 16px;
        color: #333;
        line-height: 34px;
    }

    .lay_conts {
        padding: 15px 15px 20px 15px;
        font-size: 14px;
        line-height: 1.6;
        color: #666;

        &.txt {
            padding: 9px 15px 17px;
        }

        img {
            vertical-align: top;
        }
    }

    button.btn_laycls {
        position: absolute;
        right: 3px;
        top: 10px;
        width: 32px;
        height: 24px;
        background: url("/img/common/v2/sp_common_v2.png") no-repeat 0 -29px;
    }

    .layctr_txt {
        padding-top: 5px;
        text-align: center;

        em {
            display: block;
            margin: -4px 0 2px;
            font-size: 18px;
            line-height: 28px;
            color: #333;
        }
    }

    a {
        &.btn_lay {
            display: inline-block;
            min-width: 58px;
            height: 33px;
            padding: 0 15px;
            border: 1px solid #555;
            background-color: #555;
            font-size: 14px;
            line-height: 30px;
            color: #fff;
            text-align: center;

            &.white {
                border: 1px solid #dadada;
                background-color: #fff;
                color: #666;
            }
        }
        &.sbtn_lay {
            display: inline-block;
            width: 41px;
            height: 23px;
            border: 1px solid #555;
            background-color: #555;
            font-size: 12px;
            line-height: 20px;
            color: #fff;
            text-align: center;
            vertical-align: middle;
        }

        &.btn_lay:hover,
        &.sbtn_lay:hover {
            text-decoration: none;
        }
    }

    .layctr_btn {
        margin-top: 18px;
        text-align: center;

        &.line {
            margin-top: 13px;
            padding-top: 20px;
            border-top: 1px dashed #dadada;
        }

        a,
        button {
            margin: 0 2px;
        }
    }

    &.lay_sns_v2 {
        width: 188px;

        .lay_conts {
            padding: 15px 0 10px;
        }

        button.btn_laycls {
            right: 4px;
            top: 7px;
            width: 32px;
            height: 24px;
            background-position: 0 -29px;
        }
    }
}

.lay_sns_v2 {
    .sns_type {
        padding: 0 0 15px 15px;

        li {
            float: left;
            margin-left: 30px;

            &:first-child {
                margin-left: 0;
            }

            a {
                display: block;
                width: 33px;
                height: 32px;
                background: url("/img/common/v2/sp_common_v2.png") no-repeat -57px -22px;

                &.facebook {
                    background-position: -57px -22px;
                }

                &.twitter {
                    background-position: -97px -22px;
                }

                &.kakao {
                    background-position: -137px -22px;
                }
            }
        }
    }

    .sns_url {
        padding: 10px 15px 0;
        border-top: 1px solid #dadada;

        .btn_nw_gray {
            width: 41px;
            min-width: 40px;
        }

        input[type="text"] {
            width: 105px;
            padding: 3px 4px 0;
            *padding-top: 5px;
            *padding-bottom: 2px;
            color: #666;
            font-size: 12px;
            border: 1px solid #dadada;
            border-right: 0;
            line-height: 16px;
            vertical-align: middle;
        }
    }
}

:root .lay_nw_def.lay_sns_v2 input[type="text"] {
    padding: 3px 4px 5px;
}

// [SelectBox]
.box_select {
    display: inline-block;
    height: 24px;
    padding: 4px 9px 0;
    border: 1px solid #dadada;
    background-color: #fff;
    vertical-align: middle;
    *height: 30px;
    *padding: 0 0 0;
    *border: 0;

    select {
        border: 0 none;
        background-color: #fff;
        vertical-align: top;
        *font-size: 12px;
    }
}

// [가로형테이블]
.row_tb {
    width: 100%;
    border-top: 1px solid #666;

    th {
        height: 20px;
        padding: 6px 0 0 10px;
        border-bottom: 1px solid #dadada;
        border-right: 1px solid #dadada;
        background: #f5f5f5;
        font-size: 12px;
        color: #333;
        font-weight: normal;
        text-align: left;
        vertical-align: top;
    }

    td {
        padding: 5px 0 5px 20px;
        border-bottom: 1px solid #dadada;
        font-size: 12px;
        color: #666;
    }
}

// [세로형테이블]
.col_tb {
    width: 100%;
    border-top: 1px solid #666;

    th {
        height: 30px;
        padding-left: 10px;
        border-bottom: 1px solid #dadada;
        background: #f5f5f5;
        font-size: 12px;
        color: #333;
        font-weight: normal;
        text-align: left;
        vertical-align: middle;
    }

    td {
        padding: 6px 0 4px 10px;
        border-bottom: 1px solid #dadada;
        border-left: 1px solid #dadada;
        font-size: 12px;
        color: #666;
        text-align: left;

        &:first-child {
            border-left: none;
        }
    }
}

// 약관용 테이블
.tbl_info_terms {
    width: 100%;
    margin: 8px 0 0;
    border: 1px solid #cecece;
    border-left: none;
    font-size: 12px;
    line-height: 160%;
    font-family: $font-family-asis;
    letter-spacing: -1px;

    th {
        padding: 8px 5px 5px 5px;
        margin: 0;
        vertical-align: middle;
        text-align: center;
        color: #666;
        border-left: 1px solid #cecece;
        background-color: #f7f7f7;
    }

    td {
        padding: 8px 5px 5px 5px;
        margin: 0;
        vertical-align: middle;
        text-align: center;
        color: #666;
        border-left: 1px solid #cecece;
        border-top: 1px solid #d9d9d9;
    }

    .txt_point {
        font-size: 15px;
        font-weight: bold;
    }
}

// [bg_color 박스형]
.lay_textBbox {
    width: 100%;
    padding: 15px 0 13px;
    background: #f5f5f5;
    font-size: 14px;
    text-align: center;
    color: #333;
}

// [오늘그만보기]
.lay_nw_def .day_close {
    position: relative;
    margin-top: 20px;
    border-top: 1px solid #dadada;

    label {
        display: inline-block;
        padding: 10px 0 0 20px;
        font-size: 12px;
        color: #333;
    }

    input {
        vertical-align: top;
        position: absolute;
        top: 12px;
        left: 0;
        z-index: 50;
    }
}

.lay_nw_def.direct_go_wrap .lay_conts .direct_go {
    line-height: 1.2;

    dl {
        margin-top: 20px;

        &:first-child {
            margin-top: 0;
        }
    }

    dt {
        margin-bottom: 10px;
        color: #111;
        font-size: 14px;
    }

    dd {
        font-size: 12px;
    }

    ol li {
        padding: 1px 0 5px;
        color: #536dfe;
    }

    dd .m_benefit {
        padding-top: 7px;
        color: $color-11st-red;

        .ico {
            display: inline-block;
            width: 5px;
            height: 5px;
            margin-right: 8px;
            background: url("/img/common/v2/sp_common_v2.png") no-repeat -133px -82px;
            vertical-align: 0.2em;
        }
    }

    ul li {
        float: left;
        margin: 0 -1px -1px 0;
        border: 1px solid #dadada;

        .btn_way {
            display: block;
            width: 118px;
            height: 33px;
            text-align: center;
            color: #666;
            background: #fff;
            font: normal 11px/1.2 $font-family-default;

            &:hover {
                color: $color-11st-red;
                text-decoration: none;
            }
        }
    }

    .btn_sec {
        margin-top: 15px;
        text-align: center;
    }

    .btn_nw_gray {
        padding-left: 10px;
        padding-right: 10px;
    }
}

// [파워링크]
.sponsor_link_wrap {
    width: 100%;
    background: #fff;
    margin-top: 40px;

    &.no_line {
        border: none;
    }

    .sponsor_link_renwl {
        width: 1200px;
        margin: 0 auto;
        padding: 0;
        border: none;
        position: relative;

        h3 {
            display: inline-block;
            width: auto;
            font: 22px/1.4 $font-family-default;
            background: none;
            text-indent: 0;
            color: #111;

            span {
                font-size: 12px;
                color: #666;
            }
            & + .help_ad {
                top: -2px;
            }
        }

        .top_wrap {
            position: relative;
            height: 40px;

            .nwtxt_link {
                position: absolute;
                top: 10px;
                right: 0;
            }
        }

        ol {
            display: inline-block;
            margin: 0 0 -18px;
            padding: 20px 0 0 20px;
            border-top: 1px solid #dadada;

            li {
                display: inline-block;
                width: 1100px;
                padding: 0 0 18px 20px;
                margin: 0 !important;

                > span {
                    width: 15px;
                    height: 14px;
                    margin-top: 1px;
                    background: url("/img/common/v2/sp_common_v2.png") no-repeat;
                    vertical-align: -0.2em;
                }

                .num01 {
                    background-position: -153px -184px;
                }

                .num02 {
                    background-position: -172px -184px;
                }

                .num03 {
                    background-position: -191px -184px;
                }

                .num04 {
                    background-position: -210px -184px;
                }

                .num05 {
                    background-position: -229px -184px;
                }

                .num06 {
                    background-position: -248px -184px;
                }

                .num07 {
                    background-position: -267px -184px;
                }

                .num08 {
                    background-position: -286px -184px;
                }

                strong {
                    font: 14px $font-family-default;
                    text-decoration: underline;
                    color: #00c;

                    em {
                        font-weight: bold;
                    }
                }

                > em {
                    font-size: 12px;
                    font: 14px/1.3 $font-family-default;
                    color: #323232;
                }

                span {
                    display: inline-block;
                    color: #7c5d3e;
                    font: 12px $font-family-number;
                    padding: 0 2px;
                }

                .t_bold {
                    font: bold 14px/1 $font-family-default;
                    text-decoration: none;
                    color: #333;
                }
            }
        }
    }
}

.amore_beautypoint {
    position: relative;
    width: 630px;
    margin: 10px 0 0;
    padding: 0 165px 0 0;
    background-color: #e0eaf5;
    border-top: 1px solid #d8e4f7;
    border-bottom: 1px solid #d0def9;
    font-family: $font-family-default;

    &.btn_m {
        width: 590px;
        padding-right: 205px;
    }

    &.btn_l {
        width: 552px;
        padding-right: 243px;
    }

    .notice {
        display: table-cell;
        width: 100%;
        padding-left: 10px;
        height: 59px;
        line-height: 59px;
        font-size: 14px;
        color: #3b5e8d;
        vertical-align: middle;
        text-decoration: none;

        span {
            overflow: hidden;
            display: inline-block;
            max-height: 38px;
            margin: -1px 0 0;
            *margin: -2px 0 0;
            padding: 0 0 0 20px;
            line-height: 19px;
            font-weight: bold;
            word-wrap: break-word;
            word-break: break-all;
            vertical-align: text-top;
        }
    }

    &.wide {
        width: 650px;
        padding-left: 165px;
        background: #e0eaf5 url("/img/specialshop/amore/bg_amore_beautypoint.png") 0 0 no-repeat;

        &.btn_m {
            width: 610px;
        }

        &.btn_l {
            width: 572px;
        }
    }

    .btns {
        position: absolute;
        top: 13px;
        right: 28px;
        display: inline-block;
        padding: 0 18px;
        height: 31px;
        border: 1px solid #9bb2d4;
        border-radius: 50px 50px;
        text-decoration: none;

        span {
            display: inline-block;
            height: 16px;
            margin: 6px 0 0;
            *margin: 8px 0 0;
            padding: 0 22px 0 0;
            font-size: 14px;
            color: #3b5e8d;
            font-weight: bold;
            line-height: 16px;
            text-align: center;
            background: url("/img/specialshop/amore/bg_btns.gif") 100% -15px no-repeat;
        }

        &.arr_r span {
            background-position: 103% 3px;
        }
    }

    &.wide .notice span {
        padding-left: 0;
    }
}

.header_ad_sub {
    position: absolute;
    top: 25px;
    right: 7px;
}

.header_ad_shocking {
    position: absolute;
    bottom: 5px;
    right: 0px;
    img {
        width: 175px;
        height: 90px;
    }
}

#layBodyWrap .wing_banner.wing_fixed {
    position: fixed;
    top: 0;
}

// 리뷰 체험단 - 상품상세 리뷰, 열린쇼핑 리뷰에서 사용됨
.experience_type1 {
    display: inline-block;
    width: 88px;
    height: 20px;
    font-size: 12px;
    color: #d72e20;
    border: 1px solid #d72e20;
    text-align: center;
    line-height: 19px;

    .icon {
        display: inline-block;
        width: 10px;
        height: 12px;
        margin-top: 4px;
        background: url("/img/common/v2/sp_common_v2.png") no-repeat -80px -365px;
        vertical-align: top;
    }
}

.experience_type2 {
    font-size: 11px;
    color: #d72e20;
    letter-spacing: -1px;
    .icon {
        display: inline-block;
        width: 9px;
        height: 11px;
        margin: 1px 2px 0 0;
        background: url("/img/common/v2/sp_common_v2.png") no-repeat -94px -365px;
        vertical-align: top;
    }
}

//공통 동영상
.video11box {
    position: relative;
    width: 500px;
    height: 370px;
    margin: 0 auto 20px auto;
    background: #000;
    text-align: center;

    video {
        width: 100%;
        height: 100%;
        vertical-align: top;
    }

    .btn_play {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    //MPSR-46450 리뷰동영상 아이콘 업데이트 되면 삭제예정 시작
    .ico_movreview {
        overflow: hidden;
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        margin: -33px 0 0 -33px;
        width: 66px;
        height: 66px;
        background: url("/img/common/v2/sp_common_v2.png") no-repeat 0 -430px;
        line-height: 300px;
    }
    //MPSR-46450 리뷰동영상 아이콘 업데이트 되면 삭제예정 끝

    .ico_mov {
        overflow: hidden;
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        margin: -33px 0 0 -33px;
        width: 66px;
        height: 66px;
        background: url("/img/common/v2/sp_common_v2.png") no-repeat 0 -430px;
        line-height: 300px;
    }

    .nosupport {
        position: relative;
        padding: 40px 30px 0 30px;
        color: #fff;
        font-size: 16px;
        line-height: 1.5;
        z-index: 5;

        a {
            display: inline-block;
            margin: 15px 0 0 0;
            color: #2cb1f8;
            text-decoration: underline;
        }
    }
}
